import React, { useState, useEffect } from "react";
import SideDrawer from "components/common/SideDrawer";
import Stack from "components/common/Stack";
import Box from "components/common/Box";
import DownloadButton from "components/common/DownloadButton";
import Text from "components/common/Text";
import Checkbox from "components/common/Checkbox";
import NewTextField from "components/common/TextField";
import TextField from "components/common/TextField";
import MenuItem from "components/common/MenuItem";
import DatePicker from "components/common/DatePicker";
import Grid from "components/common/Grid";
import { useNavigate } from "react-router";
import Button from "components/common/Button";
import { ButtonGroup, styled } from "@mui/material";
import { Chip } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { customersApiAction } from "stores/redux/apiSlices/customers/customersApiSlice";
import dayjs, { Dayjs } from "dayjs";
import FileUpload from "components/common/FileUpload";
import Dialog from "components/common/Dialog";
import Avatar from "components/common/Avatar";
import Featured from "asset/icons/FeatureIcon";
import { useSelector } from "react-redux";
import { validationHelper } from "helpers/validation";
import { format } from "date-fns";

let theme = createTheme({});
theme = createTheme(theme, {
  palette: {
    salmon: theme.palette.augmentColor({
      color: {
        main: "#FF5733",
      },
      name: "salmon",
    }),
  },
});

const ChipStyle = styled(Chip)(({ theme, riskprofile }) => {
  let backgroundColor;
  let color;
  if (riskprofile === "Aggressive") {
    backgroundColor = "#FFEDED";
    color = "#EB5757";
  } else if (riskprofile === "Moderate") {
    backgroundColor = "#FFF5E0";
    color = "#E58026";
  } else if (riskprofile === "Conservative") {
    backgroundColor = "#DEFFEC";
    color = "#219653";
  }

  return {
    display: "flex",
    alignItems: "center",
    borderRadius: "152px",
    backgroundColor: backgroundColor,
    color: color,
    fontSize: "12px",
    padding: "8px",
    fontWeight: 500,
    "&::before": {
      position: "relative",
      left: 5,
      bottom: 6,
      content: '"\\2022"',
      width: "6px",
      height: "6px",
      color: color,
    },
  };
});

const CapitalButton = styled(Button)(({ theme }) => ({
  padding: "2px 4px",
  borderRadius: "8px",
  border: "1px solid #142E56",
  marginTop: "12px",
}));

const ViewCustomer = ({
  isDrawerOpen,
  setIsDrawerOpen,
  customerId,
  getCustomers,
}) => {
  const navigate = useNavigate();
  const select = useSelector((state) => state);
  const [openModel, setOpenModel] = useState(false);
  const [cancelButtonText, setCancelButtonText] = useState("Reject request");
  const [getCustomerAllDetail, getCustomerAllDetailData = []] =
    customersApiAction.getCustomerDetail(customerId);
  const showCustomerData = getCustomerAllDetailData?.data?.result;
  const [downloadDocAPI] = customersApiAction.postProfileDocApi(customerId);
  const { data: subscriptionData, refetch } =
    customersApiAction.getCustomerPlan();
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);
  const originalStartDate = showCustomerData?.otherDetails[0]?.start_date;
  const originalStartDateFormat = dayjs(originalStartDate);
  const [startDate, setStartDate] = useState(dayjs(originalStartDateFormat));
  const originalEndDate = showCustomerData?.otherDetails[0]?.end_date;
  const originalEndDateFormat = dayjs(originalEndDate);
  const originaljoining = showCustomerData?.otherDetails[0]?.joining_date;
  const originalJoinDate =
    originaljoining && dayjs(originaljoining).format("DD-MM-YYYY");
  const [endDate, setEndDate] = useState(originalEndDateFormat);
  const [isEndDatePickerOpen, setEndDatePickerOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedValue, setSelectedValue] = useState(
    showCustomerData?.otherDetails[0]?.plan_detail_id
  );
  const [selectedPlanCode, setSelectedPlanCode] = useState(
    showCustomerData?.otherDetails[0]?.plan_code
  );

  const stageCheck = showCustomerData?.customerDetails[0]?.current_stage;
  const [updataData] = customersApiAction.updateCustomerApprove(customerId);
  const [formData, setFormData] = useState({
    capital: "",
    is_percent: false,
    isCustomInvestmentSelected: false,
    customAmount: "",
    isModelPortfolioSelected: false,
    modelPorfolioAmount: "",
    isHighRiskSelected: false,
    highRiskAmount: "",
    isMediumRiskSelected: false,
    mediumRiskAmount: "",
    isLowRiskSelected: false,
    lowRiskAmount: "",
    isAlgoSelected: false,
    algoAmount: "",
    plan_detail_id: selectedValue,
    panNumber: "",
    aadharNo: "",
    pan_card: null,
    aadhar_card: "",
    sign_agreement: "",
    start_date: null,
    end_date: "",
    plan_code: selectedPlanCode,
  });
  const [formError, setFormError] = useState({
    customAmount: "",
    modelPorfolioAmount: "",
    highRiskAmount: "",
    mediumRiskAmount: "",
    lowRiskAmount: "",
    algoAmount: "",
    start_date: null,
    end_date: null,
    aadhar_card: null,
    sign_agreement: null,
    pan_card: null,
    plan_detail_id: null,
    panCardNumber: "",
    aadharCardNumber: "",
  });

  const [amountError, setAmountError] = useState(null);
  const [modalAmountError, setModalAmountError] = useState(null);
  const [reason, setReason] = useState("");

  const [isReasonEmpty, setIsReasonEmpty] = useState(true);

  const onBoardStatus = showCustomerData?.customerDetails[0]?.onboard_status;

  const userDetails = useSelector((state) => state.commonReducer.userDetails);

  const customerDetail = [
    {
      name: "Customer name",
      value: showCustomerData?.customerDetails[0]?.name,
    },
    {
      name: "Email address",
      value: showCustomerData?.customerDetails[0]?.email,
    },
    { name: "Phone", value: showCustomerData?.customerDetails[0]?.mobile },
    {
      name: "Investment Type",
      value: showCustomerData?.customerDetails[0]?.investment_type ?? "",
    },

    // {
    //   name: "Onboard Status Reason",
    //   value: showCustomerData?.customerDetails[0]?.onboard_status_reason ?? "",
    // },

    {
      name: "Joining Date",
      value: originalJoinDate ?? "",
    },
    {
      name: "Customer type",
      value:
        userDetails.role_name === "SubBroker"
          ? "Sub-Broker Customer"
          : showCustomerData?.customerDetails[0]?.customer_type ?? "",
    },
    {
      name: "Customer residency",
      value: showCustomerData?.customerDetails[0]?.customer_residency ?? "",
    },
    {
      name: "Capital",
      value: showCustomerData?.customerDetails[0]?.capital ?? "",
    },
    {
      name: "Auto Trade Status",
      value: showCustomerData?.customerDetails[0]?.auto_trade ?? "",
    },
    {
      name: "Assessed Risk Profile",
      value: showCustomerData?.customerDetails[0]?.assessedriskprofile ?? "",
    },
    {
      name: "Selected Risk Profile",
      value: showCustomerData?.customerDetails[0]?.selectedriskprofile ?? "",
    }
  ];

  // const handleChange = ({ name, value }) => {
  //   let newFormData = { ...formData };
  //   newFormData[name] = value;
  //   setFormData(newFormData);
  // };

  const handleChange = ({ name, value }) => {
    const newFormData = { ...formData };
    if (
      [
        "customAmount",
        "modelPorfolioAmount",
        "highRiskAmount",
        "mediumRiskAmount",
        "lowRiskAmount",
        "algoAmount",
      ].includes(name)
    ) {
      value = value.trim();
      if (value !== "" && isNaN(value)) {
        return;
      }
    }
    setFormData({ ...newFormData, [name]: value });
  };
  

  const handleFileInputChange = async (props) => {
    const selectedFile = props.files[0];
    const inputName = props.name;
    if (selectedFile) {
      const base64Content = await readFileAsync(selectedFile);
      setFormData((prevFormData) => ({
        ...prevFormData,
        [inputName]: `data:application/pdf;base64,${base64Content}`,
      }));
    }
  };

  const readFileAsync = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(",")[1]);
      reader.readAsDataURL(file);
    });
  };

  const handleStartChange = () => {
    setDatePickerOpen(true);
  };

  const handleEndChange = () => {
    setEndDatePickerOpen(true);
  };

  const handleDateChange = (startDate, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      start_date: value,
    }));
  };

  const handleEndDateChange = (endDate, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      end_date: value,
    }));
  };

  const handleRemarkChange = (event) => {
    const newValue = event.target.value;
    setReason(newValue);
    // Update the state to reflect whether the text field is empty or not
    setIsReasonEmpty(newValue.trim() === "");
  };

  const capitalValidation = () => {
    if (!formData.is_percent) {
      let currentAmount = 0;
      if (formData.isCustomInvestmentSelected) {
        currentAmount += Number(formData.customAmount);
      }
      if (formData.isModelPortfolioSelected) {
        currentAmount += Number(formData.modelPorfolioAmount);
        let modelAmount = 0;
        modelAmount += Number(formData.highRiskAmount);
        modelAmount += Number(formData.mediumRiskAmount);
        modelAmount += Number(formData.lowRiskAmount);

        if (modelAmount > Number(formData.modelPorfolioAmount)) {
          setModalAmountError(
            "Total Model amount should be less than Model Porfolio Amount"
          );
          return 0;
        }
      } else {
        setModalAmountError(null);
      }
      if (formData.isAlgoSelected) {
        currentAmount += Number(formData.algoAmount);
      }
      if (currentAmount > Number(formData.capital)) {
        setAmountError("Total amount should be less than capital amount");
        return 0;
      } else {
        setAmountError(null);
        return 1;
      }
    } else {
      let totalPercent = 0;
      if (formData.isCustomInvestmentSelected) {
        totalPercent += Number(formData.customAmount);
      }
      if (formData.isModelPortfolioSelected) {
        // debugger;
        totalPercent += Number(formData.modelPorfolioAmount);
        let modelPercent = 0;
        modelPercent += Number(formData.highRiskAmount);
        modelPercent += Number(formData.mediumRiskAmount);
        modelPercent += Number(formData.lowRiskAmount);
        if (modelPercent > 100) {
          setModalAmountError("Total Percentage should be less than 100");
          return 0;
        } else {
          setModalAmountError(null);
        }
      }
      if (formData.isAlgoSelected) {
        totalPercent += Number(formData.algoAmount);
      }
      if (totalPercent > 100) {
        setAmountError("Total Percentage should be less than 100");
        return 0;
      } else {
        setAmountError(null);
        return 1;
      }
    }
  };

  const handleValidation = () => {
    const newFormError = { ...formError };

    const panValidation = validationHelper.required(formData.pan_card);
    newFormError.pan_card = panValidation.message;

    const aadhaarValidation = validationHelper.required(formData.aadhar_card);
    newFormError.aadhar_card = aadhaarValidation.message;

    const panNumberValidation = validationHelper.required(formData.panNumber);
    newFormError.panCardNumber = panNumberValidation.message;

    const aadharNumberValidation = validationHelper.required(formData.aadharNo);
    newFormError.aadharCardNumber = aadharNumberValidation.message;

    const signValidation = validationHelper.required(formData.sign_agreement);
    newFormError.sign_agreement = signValidation.message;
    const startDateValidation = validationHelper.required(formData.start_date);
    newFormError.start_date = startDateValidation.message;

    const expiryDateValidation = validationHelper.required(formData.end_date);
    newFormError.end_date = expiryDateValidation.message;

    const planValidation = validationHelper.required(formData.plan_detail_id);
    newFormError.plan_detail_id = planValidation.message;

    let customValidation = { isValid: 1 };
    if (formData.isCustomInvestmentSelected) {
      customValidation = validationHelper.required(formData.customAmount);
      newFormError.customAmount = customValidation.message;
      if (formData.customAmount <= 0 || isNaN(formData.customAmount)) {
        customValidation = {
          isValid: 0,
          message: "Amount must be greater than zero",
        };
        newFormError.customAmount = customValidation.message;
      }
    } else {
      newFormError.customAmount = "";
    }

    let modelPortfolioValidation = { isValid: 1 };
    if (formData.isModelPortfolioSelected) {
      const modelPortfolioAmount = formData.modelPorfolioAmount;
      if (modelPortfolioAmount <= 0 || isNaN(modelPortfolioAmount)) {
        modelPortfolioValidation = {
          isValid: 0,
          message: "Amount must be greater than zero",
        };
      }
      newFormError.modelPorfolioAmount = modelPortfolioValidation.message;
    } else {
      newFormError.modelPorfolioAmount = "";
    }

    let highRiskValidation = { isValid: 1 };
    if (formData.isHighRiskSelected) {
      highRiskValidation = validationHelper.required(formData.highRiskAmount);
      newFormError.highRiskAmount = highRiskValidation.message;
      if (formData.highRiskAmount <= 0 || isNaN(formData.highRiskAmount)) {
        highRiskValidation = {
          isValid: 0,
          message: "Amount must be greater than zero",
        };
        newFormError.highRiskAmount = highRiskValidation.message;
      }
    } else {
      newFormError.highRiskAmount = "";
    }

    let mediumRiskValidation = { isValid: 1 };
    if (formData.isMediumRiskSelected) {
      mediumRiskValidation = validationHelper.required(
        formData.mediumRiskAmount
      );
      newFormError.mediumRiskAmount = mediumRiskValidation.message;
      if (formData.mediumRiskAmount <= 0 || isNaN(formData.mediumRiskAmount)) {
        mediumRiskValidation = {
          isValid: 0,
          message: "Amount must be greater than zero",
        };
        newFormError.mediumRiskAmount = mediumRiskValidation.message;
      }
    } else {
      newFormError.mediumRiskAmount = "";
    }

    let lowRiskValidation = { isValid: 1 };
    if (formData.isLowRiskSelected) {
      lowRiskValidation = validationHelper.required(formData.lowRiskAmount);
      newFormError.lowRiskAmount = lowRiskValidation.message;
      if (formData.lowRiskAmount <= 0 || isNaN(formData.lowRiskAmount)) {
        lowRiskValidation = {
          isValid: 0,
          message: "Amount must be greater than zero",
        };
        newFormError.lowRiskAmount = lowRiskValidation.message;
      }
    } else {
      newFormError.lowRiskAmount = "";
    }

    let algoValidation = { isValid: 1 };
    if (formData.isAlgoSelected) {
      algoValidation = validationHelper.required(formData.algoAmount);
      newFormError.algoAmount = algoValidation.message;
      if (formData.algoAmount <= 0 || isNaN(formData.algoAmount)) {
        algoValidation = {
          isValid: 0,
          message: "Amount must be greater than zero",
        };
        newFormError.algoAmount = algoValidation.message;
      }
    } else {
      newFormError.algoAmount = "";
    }

    setFormError(newFormError);

    return (
      panValidation.isValid &&
      aadhaarValidation.isValid &&
      panNumberValidation.isValid &&
      aadharNumberValidation.isValid &&
      signValidation.isValid &&
      customValidation.isValid &&
      modelPortfolioValidation.isValid &&
      highRiskValidation.isValid &&
      mediumRiskValidation.isValid &&
      lowRiskValidation.isValid &&
      algoValidation.isValid &&
      startDateValidation.isValid &&
      expiryDateValidation.isValid &&
      planValidation.isValid
    );
  };

  const payloadParser = () => {
    let payload = {};
    let investments = [];
    payload.capital = formData.capital;

    let plan_detail_id = formData.plan_detail_id;
    let pan_card = formData.pan_card;
    let aadhar_card = formData.aadhar_card;
    let sign_agreement = formData.sign_agreement;
    let startDate = formData.start_date;
    let endDate = formData.end_date;

    if (formData.isCustomInvestmentSelected) {
      let investment = {
        product_id: 1,
        amount: formData.customAmount,
        product_category: [],
        is_percent: formData.is_percent ? "Y" : "N",
      };
      investments.push(investment);
    }
    if (formData.isModelPortfolioSelected) {
      let investment = {
        product_id: 2,
        amount: formData.modelPorfolioAmount,
        is_percent: formData.is_percent ? "Y" : "N",
      };
      let product_category = [];
      if (formData.isHighRiskSelected) {
        let categoryProd = {
          product_category_id: 1,
          amount: parseInt(
            formData.highRiskAmount ? formData.highRiskAmount : 0
          ),
        };
        product_category.push(categoryProd);
      }
      if (formData.isMediumRiskSelected) {
        let categoryProd = {
          product_category_id: 2,
          amount: parseInt(
            formData.mediumRiskAmount ? formData.mediumRiskAmount : 0
          ),
        };
        product_category.push(categoryProd);
      }
      if (formData.isLowRiskSelected) {
        let categoryProd = {
          product_category_id: 3,
          amount: parseInt(formData.lowRiskAmount ? formData.lowRiskAmount : 0),
        };
        product_category.push(categoryProd);
      }
      investment.product_category = product_category;
      investments.push(investment);
    }
    if (formData.isAlgoSelected) {
      let investment = {
        product_id: 3,
        amount: formData.algoAmount,
        product_category: [],
        is_percent: formData.is_percent ? "Y" : "N",
      };
      investments.push(investment);
    }
    payload.startDate = startDate;
    payload.endDate = endDate;
    payload.plan_detail_id = plan_detail_id;
    payload.aadhar_card = aadhar_card;
    payload.sign_agreement = sign_agreement;
    payload.investments = investments;
    payload.pan_card = pan_card;
    payload.sign_agreement = sign_agreement;
    return payload;
  };

  const closeDrawer = (cancelButtonText) => {
    if (cancelButtonText === "Reject request") {
      setOpenModel(true);
      setIsDrawerOpen(false);
    } else {
      setIsDrawerOpen(false);
    }
  };

  const handleDownloadDoc = async (type) => {
    try {
      const requestBody = {
        user_id: customerId,
        type: type,
      };
      let res = await downloadDocAPI(requestBody).unwrap();
      const result = res?.result;
      if (result?.file) {
        const base64 = await fetch(result.file);
        const blob = await base64.blob();
        const a = document.createElement("a");
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = result.filename || "downloaded_file.png";
        a.click();
      } else {
        console.error("No file found in the API response.");
      }
    } catch (err) {
      console.error("Error during download:", err);
    }
  };

  const handleCancel = () => {
    setOpenModel(false);
    setIsDrawerOpen(false);
  };

  const handleOkay = async () => {
    try {
      let payload = {
        user_id: customerId,
        status: "Rejected",
        plan_detail_id: "",
        plan_code: "",
        start_date: "",
        end_date: "",
        pan_card: "",
        aadhar_card: "",
        sign_agreement: "",
        investments: "",
        onboard_status_reason: reason,
      };
      const res = await updataData(payload);
      getCustomers();
      setIsDrawerOpen(false);
      setFormError("");
      setOpenModel(false);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  // const handleSave = async () => {
  //   if (!handleValidation()) {
  //     return;
  //   }
  //   if (!capitalValidation()) {
  //     return;
  //   }
  //   try {
  //     const parsedPayload = payloadParser();
  //     let payload = {
  //       user_id: customerId,
  //       status: "Accepted",
  //       plan_detail_id: parsedPayload.plan_detail_id,
  //       plan_code: selectedPlanCode,
  //       start_date: dayjs(parsedPayload.startDate).format("YYYY-MM-DD"),
  //       end_date: dayjs(parsedPayload.endDate).format("YYYY-MM-DD"),
  //       pan_card: parsedPayload.pan_card,
  //       aadhar_card: parsedPayload.aadhar_card,
  //       sign_agreement: parsedPayload.sign_agreement,
  //       investments: parsedPayload.investments,
  //     };
  //     const res = await updataData(payload);
  //     console.log("res", res);
  //     if (res?.data?.status === 1) {
  //       setIsDrawerOpen(false);
  //       setFormError("");
  //       getCustomers();
  //     }
  //     console.log("Data saved successfully view customer change :", payload);
  //   } catch (error) {
  //     console.error("Error saving data:", error);
  //   }
  // };

  const handleSelectChange = (e) => {
    const value = e.target.value;
    const selectedPlan = subscriptionData?.plans.find(
      (plan) => plan.plan_detail_id === value
    );
    if (selectedPlan) {
      setSelectedPlan(selectedPlan);
      setSelectedPlanCode(selectedPlan.code);
      setFormData((prevFormData) => ({
        ...prevFormData,
        plan_detail_id: value,
      }));
    }
  };

  const setFormDataFromShowCustomerData = (data) => {
    setSelectedValue(data?.otherDetails[0]?.plan_detail_id);
    setSelectedPlanCode(data?.otherDetails[0]?.plan_code ?? "");
    setFormData((prevData) => ({
      ...prevData,
      is_percent:
        data?.investmentDetails[0]?.is_percent === "N" ||
        data?.investmentDetails[0]?.is_percent === null ||
        data?.investmentDetails[1]?.is_percent === "N" ||
        data?.investmentDetails[0]?.is_percent === null ||
        data?.investmentDetails[2]?.is_percent === "N" ||
        data?.investmentDetails[0]?.is_percent === null
          ? 0
          : 1,
      plan_detail_id: data?.otherDetails[0]?.plan_detail_id ?? "",
      aadhar_card: data?.documentDetails[1]?.aadhar_card || "",
      sign_agreement: data?.documentDetails[2]?.sign_agreement || "",
      pan_card: data?.documentDetails[0]?.pan_card || "",
      end_date: data?.otherDetails[0]?.end_date
        ? dayjs(data?.otherDetails[0]?.end_date)["$d"]
        : "",
      start_date: data?.otherDetails[0]?.start_date
        ? dayjs(data.otherDetails[0].start_date)["$d"]
        : "",
      // (dayjs(data?.otherDetails[0]?.start_date)["$d"] ?(dayjs(data?.otherDetails[0]?.start_date)["$d"]) : '') ,
      capital: data?.customerDetails[0]?.capital ?? "",
      isCustomInvestmentSelected:
        data?.customerDetails[0]?.investment_selected_type
          .split(",")
          .includes(data?.investmentDetails[0]?.product_name),
      customAmount: data?.investmentDetails[0]?.investment_amount ?? "",
      isModelPortfolioSelected:
        data?.customerDetails[0]?.investment_selected_type
          .split(",")
          .includes(data?.investmentDetails[1]?.product_name),
      modelPorfolioAmount: data?.investmentDetails[1]?.investment_amount ?? "",
      isHighRiskSelected: data?.customerDetails[0]?.investment_selected_type
        .split(",")
        .includes(
          data?.investmentDetails[1]?.product_category[2]?.category_name
        ),
      highRiskAmount:
        data?.investmentDetails[1]?.product_category[2]?.investment_amount ??
        "",
      isMediumRiskSelected: data?.customerDetails[0]?.investment_selected_type
        .split(",")
        .includes(
          data?.investmentDetails[1]?.product_category[1]?.category_name
        ),
      mediumRiskAmount:
        data?.investmentDetails[1]?.product_category[1]?.investment_amount ??
        "",
      isLowRiskSelected: data?.customerDetails[0]?.investment_selected_type
        .split(",")
        .includes(
          data?.investmentDetails[1]?.product_category[1]?.category_name
        ),
      lowRiskAmount:
        data?.investmentDetails[1]?.product_category[0]?.investment_amount ??
        "",
      isAlgoSelected: data?.customerDetails[0]?.investment_selected_type
        .split(",")
        .includes(data?.investmentDetails[2]?.product_name),
      algoAmount: data?.investmentDetails[2]?.investment_amount ?? "",
    }));
  };

  useEffect(() => {
    if (showCustomerData) {
      setFormDataFromShowCustomerData(showCustomerData);
    }
  }, [showCustomerData]);

  useEffect(() => {
    if (isDrawerOpen) {
      getCustomerAllDetail(customerId);
    }
  }, [isDrawerOpen, customerId]);

  const handleCancelButtonClick = () => {
    closeDrawer(cancelButtonText);
  };

  const handleCompleteOnboard = (showCustomerData) => {
    navigate("/add-customer", {
      state: {
        onBoardData: showCustomerData?.customerDetails[0],
        otherDetailsData: showCustomerData,
      },
    });
  };

  return (
    <Stack style={{ marginTop: "10px" }}>
      {isDrawerOpen && (
        <SideDrawer
          anchor="right"
          open={isDrawerOpen}
          closeDrawer={closeDrawer}
          title="View customer"
          subtitle="Here you can view all details of the customer."
          handleSubmit={() => handleCompleteOnboard(showCustomerData)}
          primaryAction={() => handleCancelButtonClick(cancelButtonText)}
          cancelButtonText={
            onBoardStatus === "REJECTED" ? "Request Rejected" : "Reject request"
          }
          submitButtonText="Complete Onboarding"
          primaryButtonOpacity={onBoardStatus == "REJECTED" ? "0.3" : "1"}
          primarPointerEvents={onBoardStatus === "REJECTED" ? "none" : ""}
        >
          {customerDetail.map((item) => (
            <Box sx={{ m: 2 }}>
              <Grid container spacing={1.5}>
                <Grid item xs={4}>
                  <Box>
                    <Text
                      variant="small"
                      component="h4"
                      fontSize="16px"
                      fontWeight="400"
                      marginBottom="3px"
                      sx={{ color: "#667085", textWrap: "nowrap" }}
                    >
                      {item.name}
                    </Text>
                  </Box>
                </Grid>

                <Grid item xs={8}>
                  <Box>
                    {item.name === "Risk Profile" ? (
                      <Stack direction="row" textAlign="center">
                        {":  "}
                        {showCustomerData?.customerDetails[0]?.riskprofile ? (
                          <ChipStyle
                            style={{ marginLeft: "10px" }}
                            label={
                              showCustomerData?.customerDetails[0]?.riskprofile
                            }
                            size="small"
                            riskprofile={
                              showCustomerData?.customerDetails[0].riskprofile
                            }
                          />
                        ) : (
                          ""
                        )}
                      </Stack>
                    ) : (
                      <Stack direction="row">
                        {": "}
                        <Text
                          variant="small"
                          fontSize="16px"
                          fontWeight="500"
                          marginBottom="3px"
                          color="#101828"
                          paddingLeft="12px"
                        >
                          {item.value}
                        </Text>
                      </Stack>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ))}

          <Box sx={{ m: 2 }}>
            <Text
              variant="small"
              component="h4"
              fontSize="14px"
              fontWeight="500"
              marginBottom="3px"
            >
              PAN Card
            </Text>
            {showCustomerData?.documentDetails &&
            showCustomerData.documentDetails.length > 0 ? (
              <DownloadButton
                disabled
                isDwonloadIconVisible={false}
                variant="outlined"
                onClick={() => handleDownloadDoc("PAN_CARD")}
              >
                {showCustomerData?.documentDetails[0]?.pan_card || "Pan Card"}
              </DownloadButton>
            ) : (
              <>
                <FileUpload
                  disabled={true}
                  handleChange={({ name, value }) => {
                    handleFileInputChange({ name: "pan_card", files: value });
                  }}
                  acceptedFiles={["application/pdf"]}
                  sx={{ width: "100%" }}
                />
                {formError.pan_card && (
                  <Text variant="small" color="red" py={1}>
                    {formError.pan_card}
                  </Text>
                )}
              </>
            )}
          </Box>
          <Box sx={{ m: 2 }}>
            <Text
              variant="small"
              component="h4"
              fontSize="14px"
              fontWeight="500"
              marginBottom="3px"
            >
              Aadhar Card
            </Text>
            {showCustomerData?.documentDetails &&
            showCustomerData.documentDetails.length > 0 ? (
              <DownloadButton
                disabled
                isDwonloadIconVisible={false}
                variant="outlined"
                onClick={() => handleDownloadDoc("AADHAR_CARD")}
              >
                {showCustomerData?.documentDetails[1]?.aadhar_card ||
                  "Aadhar Card"}
              </DownloadButton>
            ) : (
              <>
                <FileUpload
                  disabled={true}
                  handleChange={({ name, value }) => {
                    handleFileInputChange({
                      name: "aadhar_card",
                      files: value,
                    });
                  }}
                  acceptedFiles={["application/pdf"]}
                  sx={{ width: "100%" }}
                />
                {formError.aadhar_card && (
                  <Text variant="small" color="red" py={1}>
                    {formError.aadhar_card}
                  </Text>
                )}
              </>
            )}
          </Box>

          <Box sx={{ width: "100%" }}>
            <Text
              variant="small"
              component="h4"
              fontSize="14px"
              fontWeight="500"
              marginBottom="3px"
            >
              PAN Number
            </Text>
            <TextField
              disabled
              placeholder="Enter PAN number"
              value={
                showCustomerData?.customerDetails[0]?.pan_card ||
                "Sign Agreement"
              }
              onChange={(e) => handleChange("panNumber", e.target.value)}
              sx={{ borderRadius: "8px" }}
            />
            {formError.panCardNumber && (
              <Text variant="small" color="red" py={1}>
                {formError.panCardNumber}
              </Text>
            )}
          </Box>

          <Box sx={{ width: "100%" }}>
            <Text
              variant="small"
              component="h4"
              fontSize="14px"
              fontWeight="500"
              marginBottom="3px"
            >
              Aadhar Number
            </Text>
            <TextField
              disabled
              placeholder="Enter Aadhar number"
              value={
                showCustomerData?.customerDetails[0]?.aadhar_card ||
                "Sign Agreement"
              }
              onChange={(e) => handleChange("aadharNo", e.target.value)}
              sx={{ borderRadius: "8px" }}
            />
            {formError.aadharCardNumber && (
              <Text variant="small" color="red" py={1}>
                {formError.aadharCardNumber}
              </Text>
            )}
          </Box>

          {showCustomerData?.documentDetails &&
          showCustomerData.documentDetails.length > 0 &&
          showCustomerData.documentDetails[2]?.sign_agreement ? (
            <Box sx={{ m: 2 }}>
              <Text
                variant="small"
                component="h4"
                fontSize="14px"
                fontWeight="500"
                marginBottom="3px"
              >
                Sign Agreement
              </Text>
              <DownloadButton
                disabled
                isDwonloadIconVisible={false}
                variant="outlined"
                onClick={() => handleDownloadDoc("SIGN_AGREEMENT")}
              >
                {showCustomerData?.documentDetails[2]?.sign_agreement ||
                  "Sign Agreement"}
              </DownloadButton>
            </Box>
          ) : (
            <></>
          )}

          {/* <Box sx={{ m: 2 }} disabled>
            <Text
              variant="small"
              component="h4"
              fontSize="14px"
              fontWeight="500"
              marginBottom="3px"
            >
              Sign Agreement
            </Text>
            {showCustomerData?.documentDetails &&
            showCustomerData.documentDetails.length > 0 ? (
              <DownloadButton
                disabled
                isDwonloadIconVisible={false}
                variant="outlined"
                onClick={() => handleDownloadDoc("SIGN_AGREEMENT")}
              >
                {showCustomerData?.documentDetails[2]?.sign_agreement ||
                  "Sign Agreement"}
              </DownloadButton>
            ) : (
              <>
                <FileUpload
                  disabled
                  handleChange={({ name, value }) => {
                    handleFileInputChange({
                      name: "sign_agreement",
                      files: value,
                    });
                  }}
                  acceptedFiles={["application/pdf"]}
                  // name="sign_agreement"
                  sx={{ width: "100%" }}
                />
                {formError.sign_agreement && (
                  <Text variant="small" color="red" py={1}>
                    {formError.sign_agreement}
                  </Text>
                )}{" "}
              </>
            )}
          </Box> */}
          <Box sx={{ m: 2 }}>
            <Stack
              direction="row"
              alignItems="space-between"
              justifyContent="space-between"
            >
              <Text
                variant="small"
                component="h4"
                fontSize="14px"
                fontWeight="500"
              >
                Investment type
              </Text>
              <ButtonGroup style={{ marginTop: "-8px" }}>
                <CapitalButton
                  variant={!formData.is_percent ? "contained" : "outlined"}
                  // onClick={() => {
                  //   handleChange({ name: "is_percent", value: 0 });
                  // }}
                >
                  <Text>₹</Text>
                </CapitalButton>
                <CapitalButton
                  variant={formData.is_percent ? "contained" : "outlined"}
                  // onClick={() => {
                  //   handleChange({ name: "is_percent", value: 1 });
                  // }}
                >
                  <Text>%</Text>
                </CapitalButton>
              </ButtonGroup>
            </Stack>
            <Box
              sx={{
                maxWidth: "480px",
                margin: "0 auto",
              }}
            >
              <Stack
                direction="column"
                alignItems="space-between"
                justifyContent="space-between"
              >
                <Checkbox
                  checked={formData.isCustomInvestmentSelected}
                  label={
                    <Text
                      variant="small"
                      fontWeight="600"
                      style={{
                        marginLeft: "10px",
                        marginBottom: "10px",
                        cursor: "context-menu",
                      }}
                    >
                      Custom{" "}
                    </Text>
                  }
                  value={formData.isCustomInvestmentSelected}
                  size="small"
                  style={{ marginLeft: "15px", marginBottom: "10px" }}
                  // onChange={() => {
                  //   handleChange({
                  //     name: "isCustomInvestmentSelected",
                  //     value: !formData.isCustomInvestmentSelected,
                  //   });
                  // }}
                />
                <TextField
                  disabled
                  placeholder="Enter amount in ₹ "
                  value={formData.customAmount}
                  name="customAmount"
                  onChange={(e) => {
                    handleChange({
                      name: e.target.name,
                      value: e.target.value,
                    });
                  }}
                  // disabled={!formData.isCustomInvestmentSelected}
                />
                {formError.customAmount && (
                  <Text variant="small" color="red" py={1}>
                    {formError.customAmount}
                  </Text>
                )}
              </Stack>
              <Stack
                direction="column"
                alignItems="space-between"
                justifyContent="space-between"
              >
                <Checkbox
                  size="small"
                  checked={formData.isModelPortfolioSelected}
                  value={formData.isModelPortfolioSelected}
                  // onChange={() => {
                  //   handleChange({
                  //     name: "isModelPortfolioSelected",
                  //     value: !formData.isModelPortfolioSelected,
                  //   });
                  // }}
                  label={
                    <Text
                      variant="small"
                      fontWeight="600"
                      style={{ marginLeft: "10px", marginBottom: "10px" }}
                    >
                      Model Portfolio
                    </Text>
                  }
                  style={{ marginLeft: "15px", marginBottom: "10px" }}
                />
                <TextField
                  disabled
                  placeholder="Enter amount in ₹ "
                  value={formData.modelPorfolioAmount}
                  name="modelPorfolioAmount"
                  // onChange={(e) => {
                  //   handleChange({
                  //     name: e.target.name,
                  //     value: e.target.value,
                  //   });
                  // }}
                  // disabled={!formData.isModelPortfolioSelected}
                />
                {formError.modelPorfolioAmount && (
                  <Text variant="small" color="red" py={1}>
                    {formError.modelPorfolioAmount}
                  </Text>
                )}
                <>
                  <Stack
                    direction="column"
                    alignItems="space-between"
                    justifyContent="space-between"
                  >
                    <Checkbox
                      checked={formData.isHighRiskSelected}
                      size="small"
                      style={{
                        marginLeft: "15px",
                        marginBottom: "10px",
                        cursor: "context-menu",
                      }}
                      label={
                        <Text
                          variant="small"
                          component="h5"
                          fontWeight="600"
                          style={{ marginLeft: "10px", marginBottom: "10px" }}
                        >
                          High Risk Product Stock
                        </Text>
                      }
                      value={formData.isHighRiskSelected}
                      // onChange={() => {
                      //   handleChange({
                      //     name: "isHighRiskSelected",
                      //     value: !formData.isHighRiskSelected,
                      //   });
                      // }}
                    />

                    <TextField
                      disabled
                      sx={{ width: "100%" }}
                      placeholder="Enter amount to invest"
                      value={formData.highRiskAmount}
                      name="highRiskAmount"
                      onChange={(e) => {
                        handleChange({
                          name: e.target.name,
                          value: e.target.value,
                        });
                      }}
                      // disabled={!formData.isHighRiskSelected}
                    />
                    {formError.highRiskAmount && (
                      <Text variant="small" color="red" py={1}>
                        {formError.highRiskAmount}
                      </Text>
                    )}
                  </Stack>
                </>
                <Stack
                  direction="column"
                  alignItems="space-between"
                  justifyContent="space-between"
                  cursor="none"
                >
                  <Checkbox
                    checked={formData.isMediumRiskSelected}
                    size="small"
                    style={{
                      marginLeft: "15px",
                      marginBottom: "10px",
                      cursor: "context-menu",
                    }}
                    label={
                      <Text
                        disabled
                        variant="small"
                        component="h5"
                        fontWeight="600"
                        style={{ marginLeft: "10px", marginBottom: "10px" }}
                      >
                        Medium Risk Product Stock
                      </Text>
                    }
                    value={formData.isMediumRiskSelected}
                    // onChange={() => {
                    //   handleChange({
                    //     name: "isMediumRiskSelected",
                    //     value: !formData.isMediumRiskSelected,
                    //   });
                    // }}
                  />

                  <TextField
                    disabled
                    sx={{ width: "100%" }}
                    placeholder="Enter amount to invest"
                    value={formData.mediumRiskAmount}
                    name="mediumRiskAmount"
                    onChange={(e) => {
                      handleChange({
                        name: e.target.name,
                        value: e.target.value,
                      });
                    }}
                    // disabled={!formData.isMediumRiskSelected}
                  />
                  {formError.mediumRiskAmount && (
                    <Text variant="small" color="red" py={1}>
                      {formError.mediumRiskAmount}
                    </Text>
                  )}
                </Stack>

                <Stack
                  textAlign="center"
                  direction="column"
                  alignItems="space-between"
                  justifyContent="space-between"
                >
                  <Checkbox
                    checked={formData.isLowRiskSelected}
                    size="small"
                    style={{ marginLeft: "15px", marginBottom: "10px" }}
                    label={
                      <Text
                        variant="small"
                        component="h5"
                        fontWeight="600"
                        style={{ marginLeft: "10px", marginBottom: "10px" }}
                      >
                        Low Risk Product Stock
                      </Text>
                    }
                    value={formData.isLowRiskSelected}
                    // onChange={() => {
                    //   handleChange({
                    //     name: "isLowRiskSelected",
                    //     value: !formData.isLowRiskSelected,
                    //   });
                    // }}
                  />

                  <TextField
                    disabled
                    sx={{ width: "100%" }}
                    placeholder="Enter amount to invest"
                    value={formData.lowRiskAmount}
                    name="lowRiskAmount"
                    onChange={(e) => {
                      handleChange({
                        name: e.target.name,
                        value: e.target.value,
                      });
                    }}
                    // disabled={!formData.isLowRiskSelected}
                  />
                  {formError.lowRiskAmount && (
                    <Text variant="small" color="red" py={1}>
                      {formError.lowRiskAmount}
                    </Text>
                  )}
                </Stack>

                {modalAmountError && (
                  <Stack alignItems="flex-end" py={1}>
                    <Text variant="small" color="red">
                      {modalAmountError}
                    </Text>
                  </Stack>
                )}
                <Stack
                  direction="column"
                  textAlign="center"
                  alignItems="space-between"
                  justifyContent="space-between"
                >
                  <Checkbox
                    checked={formData.isAlgoSelected}
                    size="small"
                    style={{ marginLeft: "15px", marginBottom: "10px" }}
                    label={
                      <Text
                        variant="small"
                        component="h5"
                        fontWeight="600"
                        style={{ marginLeft: "15px", marginBottom: "10px" }}
                      >
                        Algo (F&O)
                      </Text>
                    }
                    value={formData.isAlgoSelected}
                    // onChange={() => {
                    //   handleChange({
                    //     name: "isAlgoSelected",
                    //     value: !formData.isAlgoSelected,
                    //   });
                    // }}
                  />
                </Stack>
                <TextField
                  disabled
                  placeholder="Enter amount in ₹ "
                  value={formData.algoAmount}
                  name="algoAmount"
                  onChange={(e) => {
                    handleChange({
                      name: e.target.name,
                      value: e.target.value,
                    });
                  }}
                  // disabled={!formData.isAlgoSelected}
                />
              </Stack>
              {formError.algoAmount && (
                <Text variant="small" color="red" py={1}>
                  {formError.algoAmount}
                </Text>
              )}
              {amountError && (
                <Stack alignItems="flex-end" py={1}>
                  <Text variant="small" color="red">
                    {amountError}
                  </Text>
                </Stack>
              )}
            </Box>
          </Box>

          <Box sx={{ width: "425px", m: 2 }} disabled>
            <Text
              variant="small"
              component="h4"
              fontSize="14px"
              fontWeight="500"
              marginBottom="3px"
            >
              Subscription Plan
            </Text>
            <NewTextField
              disabled
              select
              style={{ width: "106%" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formData.plan_detail_id ?? selectedValue}
              onChange={handleSelectChange}
            >
              {subscriptionData?.plans?.map((plan) => (
                <MenuItem
                  key={plan.plan_detail_id}
                  value={plan.plan_detail_id}
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {plan.name}
                </MenuItem>
              ))}
            </NewTextField>
            {formError.plan_detail_id && (
              <Text variant="small" color="red" py={1}>
                {formError.plan_detail_id}
              </Text>
            )}

            <Box sx={{ mt: 1, mr: 1 }}>
              <Text
                variant="small"
                component="h4"
                fontSize="14px"
                fontWeight="500"
                marginBottom="3px"
              >
                Plan code
              </Text>
              <TextField
                style={{ width: "108%" }}
                value={selectedPlanCode}
                disabled
              />
            </Box>

            {/* <Text
              variant="small"
              component="h4"
              fontSize="14px"
              fontWeight="500"
              marginBottom="3px"
            >
              Subscription Plan
            </Text>
            <NewTextField
              select
              style={{ width: "106%" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formData.plan_name ?? selectedValue}
              onChange={handleSelectChange}
            >
              {subscriptionData?.plans?.map((plan) => (
                <MenuItem
                  key={plan.plan_detail_id}
                  value={plan.plan_detail_id}
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {plan.name}
                </MenuItem>
              ))}
            </NewTextField> */}

            <Box sx={{ mt: 1 }}>
              <Text
                variant="small"
                component="h4"
                fontSize="14px"
                fontWeight="500"
              >
                Plan Start Date
              </Text>
              {!isDatePickerOpen && (
                <TextField
                  disabled
                  sx={{ width: "106%", marginTop: "3px" }}
                  onClick={handleStartChange}
                  value={
                    originalStartDate === null
                      ? "dd/mm/yyyy"
                      : dayjs(formData.start_date).format("MM/DD/YYYY")
                  }
                />
              )}
              {isDatePickerOpen && (
                <>
                  <DatePicker
                    disabled
                    sx={{ width: "106%" }}
                    // open={isDatePickerOpen}
                    value={formData.start_date ?? ""}
                    onChange={(date) => handleDateChange(startDate, date)}
                    style={{ position: "absolute", top: "100%", left: 0 }}
                  />
                </>
              )}
            </Box>
            {formError.start_date && (
              <Text variant="small" color="red" py={1}>
                {formError.start_date}
              </Text>
            )}
            <Box sx={{ mt: 1 }}>
              <Text
                variant="small"
                component="h4"
                fontSize="14px"
                fontWeight="500"
              >
                Expire Date
              </Text>
              {!isEndDatePickerOpen && (
                <TextField
                  disabled
                  sx={{ width: "106%", marginTop: "3px" }}
                  value={
                    originalEndDate === null
                      ? "dd/mm/yyyy"
                      : dayjs(formData.end_date).format("MM/DD/YYYY")
                  }
                  onClick={handleEndChange}
                />
              )}
              {isEndDatePickerOpen && (
                <>
                  <DatePicker
                    disabled
                    sx={{ width: "106%" }}
                    onChange={(date) => handleEndDateChange(endDate, date)}
                    value={dayjs(formData.end_date).format("MM/DD/YYYY") ?? ""}
                    style={{ position: "absolute", top: "100%", left: 0 }}
                  />
                </>
              )}
            </Box>
            {formError.end_date && (
              <Text variant="small" color="red" py={1}>
                {formError.end_date}
              </Text>
            )}
          </Box>
        </SideDrawer>
      )}
      <Dialog
        onClose={() => setOpenModel(false)}
        open={openModel}
        disableCloseIcon
        maxWidth="sm"
        contentComponent={() => (
          <Stack
            direction="column"
            spacing={0.5}
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <Avatar
              style={{ width: "50px", height: "50px" }}
              src={Featured}
            ></Avatar>
            <Text fontSize="20px" fontWeight="600" color="#101828" p={1}>
              Are you sure you want to reject this request?
            </Text>

            <Box sx={{ width: "400px", m: 5 }}>
              <Text
                variant="small"
                component="h4"
                fontSize="15px"
                fontWeight="500"
                marginBottom="3px"
              >
                Reason to Reject
              </Text>
              <NewTextField
                rows={4}
                multiline
                error={formError.reason}
                helperText={formError.reason}
                inputProps={{ style: { fontSize: "14px" } }}
                placeholder="Type reason here"
                name="reason"
                onChange={handleRemarkChange}
                style={{ width: "108%" }}
              />
            </Box>
            <Stack direction="row" style={{ padding: "0px", width: "100%" }}>
              <Button
                style={{ width: "100%" }}
                variant="outlined"
                sx={{
                  fontWeight: 600,
                  borderRadius: "8px",
                  width: "100%",
                  p: 2,
                  m: 2,
                }}
                onClick={handleCancel}
              >
                No
              </Button>
              <Button
                sx={{
                  fontWeight: 600,
                  borderRadius: "8px",
                  p: 2,
                  width: "100%",
                  m: 2,
                }}
                onClick={handleOkay}
                disabled={isReasonEmpty}
              >
                Yes , Confirm
              </Button>
            </Stack>
          </Stack>
        )}
      />
    </Stack>
  );
};

export default ViewCustomer;
