import Stack from "components/common/Stack";
import Box from "components/common/Box";
import Button from "components/common/Button";
import Text from "components/common/Text";

import Skeleton from "@mui/material/Skeleton";

const TradesCountStacks = (props) => {
  const {
    pendingTradeCallStackList,
    pendingCallStackList,
    stepToSendCall,
    pendingTradeCheck,
    stackListFetching,
  } = props;

  if (stackListFetching) {
    return (
      <Box height="220px" mb="-20px">
        <Skeleton
          variant="text"
          height="220px"
          sx={{ mt: "-52px" }}
          animation="wave"
        />
      </Box>
    );
  }

  return (
    <>
      {pendingTradeCallStackList && pendingTradeCallStackList.length ? (
        <Stack
          sx={{
            height: "50px",
            backgroundColor: "#FFF5E0",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingRight: "50px",
            paddingLeft: "16px",
            marginBottom: "20px",
            alignItems: "center",
            mb: 2,
            border: "1px solid red",
            borderRadius: "8px",
          }}
        >
          <Text>{pendingTradeCallStackList.length} Trade Overview</Text>
          <Box>
            <Button
              onClick={() => pendingTradeCheck()}
              variant="contained"
              color="primary"
              sx={{ width: "122px" }}
              // sx={{ backgroundColor: "#219653" }}
            >
              Trade Status
            </Button>
          </Box>
        </Stack>
      ) : null}

      {pendingCallStackList && pendingCallStackList.length ? (
        <Stack
          sx={{
            height: "50px",
            backgroundColor: "#E6FFE3",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingRight: "50px",
            paddingLeft: "16px",
            marginBottom: "20px",
            alignItems: "center",
            mb: 2,
            border: "1px solid #219653",
            borderRadius: "8px",
          }}
        >
          <Text>
            there are {pendingCallStackList.length} Trade call pending
          </Text>
          <Box>
            <Button
              onClick={() => stepToSendCall()}
              variant="contained"
              // color="primary"

              sx={{ backgroundColor: "#219653", width: "122px" }}
            >
              check calls
            </Button>
          </Box>
        </Stack>
      ) : null}
    </>
  );
};

export default TradesCountStacks;
