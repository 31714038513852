// This is Customer Change Risk
import React from "react";
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import Button from "components/common/Button";
import Card from "components/common/Card";
import Grid from "components/common/Grid";
import RiskProfileGraph from "components/common/RiskProfileGraph";
import { styled } from "@mui/material";
import { CheckWordType } from "helpers/utility";

const RiskProfileText = styled(Text)(() => ({
  color: "#CF1414",
  fontSize: "28px",
  fontWeight: 500,
  marginBottom: "21px",
  zoom:"0.7"
}));

const RiskProfileDesc = styled(Text)(() => ({
  color: "#676C76",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "25px"
}));

function CustomerRiskTracker(props) {
  const {
    changeRiskClick,
    currentRiskProfile,
    riskProfile,
    handleRiskProfileSubmit,
    handleStepChange
  } = props;
  let description = "";

  switch (riskProfile?.selected_risk_profile_id) {
    case 1:
      description =
        "You are a High risk-taker, so your portfolio will be volatile and expected to deliver returns with some degree of risk and volatility without compromising on stability.";
      break;
    case 2:
      description =
        "You are a moderate risk-taker, so your portfolio will be less volatile and expected to deliver returns with some degree of risk and volatility without compromising on stability.";
      break;
    case 3:
      description =
        "You are a conservative investor, so your portfolio will be relatively less volatile and expected to deliver returns that are stable with low volatility and yet protect your capital investment.";
      break;
    default:
      description = "Description not available for this risk profile.";
  }

  return (
    <>
      <Card sx={{ p: 4, borderRadius: "16px", mb: "36px", mt: 9 , zoom:"0.7"}}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Stack justifyContent="center" alignItems="center">
              <RiskProfileGraph
                riskProfileId={(currentRiskProfile?.risk_profile_id ^ 2) * 2}
                chartWidth={420}
                needleWidth={10}
              />
            </Stack>
          </Grid>

          <Grid item xs={6}>
            <Stack justifyContent="center" sx={{ height: "100%" }}>
              <Text
                variant="button"
                color="#242424"
                sx={{ fontSize: "18px", fontWeight: 500 }}
              >
                YOUR SCORE IS {riskProfile?.risk_score}
                {riskProfile.selected_risk_profile_name !==
                  riskProfile.risk_profile_name && (
                  <span style={{ textTransform: "capitalize" }}>
                    {" "}
                    (Assessed risk profile : {
                      riskProfile.risk_profile_name
                    }{" "}
                    Investor)
                  </span>
                )}
              </Text>

              {riskProfile.selected_risk_profile_name !==
              riskProfile.risk_profile_name ? (
                <CheckWordType
                  word={riskProfile.selected_risk_profile_name || ""}
                  renderComponent={(checkWordType) => (
                    <RiskProfileText>
                      Selected Risk Profile:{" "}
                      {riskProfile.selected_risk_profile_name} Investor
                    </RiskProfileText>
                  )}
                />
              ) : (
                <RiskProfileText>
                  You are{" "}
                  {riskProfile?.selected_risk_profile_name
                    ? ["a", "e", "i", "o", "u"].includes(
                        riskProfile.selected_risk_profile_name[0]?.toLowerCase()
                      )
                      ? "an"
                      : "a"
                    : ""}{" "}
                  {riskProfile?.selected_risk_profile_name} Investor
                </RiskProfileText>
              )}

              <RiskProfileDesc>{description}</RiskProfileDesc>
            </Stack>
          </Grid>
        </Grid>
      </Card>

      <Stack direction="row" justifyContent="flex-end" spacing={2}
      sx={{ zoom:"0.7"}}>
        <Button
          variant="outlined"
          sx={{
            padding: "13px 28px",
            borderRadius: "8px",
          }}
          onClick={() => {
            handleStepChange(1);
          }}
        >
          Previous
        </Button>
        <Button
          sx={{
            padding: "13px 28px",
            borderRadius: "8px",
          }}
          onClick={changeRiskClick}
        >
          Select Risk profile
        </Button>
        <Button
          sx={{
            padding: "13px 28px",
            borderRadius: "8px",
          }}
          onClick={() => {
            handleRiskProfileSubmit();
          }}
        >
          Proceed to next step
        </Button>
      </Stack>
    </>
  );
}

export default CustomerRiskTracker;
