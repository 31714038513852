import Box from "components/common/Box";
import React, { useState, useEffect } from "react";
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import CustomGrid from "components/datagrid/CustomGrid";
import { useSelector } from "react-redux";
import { uuid } from "helpers/utility";
import { useLocation } from "react-router-dom";
import { customersApiAction } from "stores/redux/apiSlices/customers/customersApiSlice";
import { CustomerPortfolioApiAction } from "stores/redux/apiSlices/CustomerPortfolio/portfolioApiSlice";

const CustomerHeader = () => {
  return (
    <>
      <Box sx={{ p: 2 }}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center">
          <Text
            sx={{ color: "primary", mr: 1, fontWeight: 500 }}
            color="primary"
            variant="button"
          >
            Allocation{" "}
          </Text>
        </Stack>
      </Box>
    </>
  );
};

const AllocationTable = ({memberID}) => {
  const location = useLocation();
  const select = useSelector((state) => state);
  const user_id = select?.toast?.breadCrumbData?.user_id;
  const cardDataDetail = location.state && location.state.cardData;
  const productId = cardDataDetail?.id;
  const [getAllocationPerformance, getAllocationTableDetail = []] =
  CustomerPortfolioApiAction.getAllocationAPI();
  const allocationTableData = getAllocationTableDetail?.data?.data ?? [];
  const totalCount = getAllocationTableDetail?.data?.totalRecords
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
    member_id: memberID
  });
  // const allocationTableData=getPerformanceDetailData?.allocation ?? [] ;
  const modifiedAllocationTableData = allocationTableData.map((row) => ({
    ...row,
    id: uuid(),
  }));


  const AllocationHeader = [
    {
      field: "symbol",
      headerName: "Stock Name",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#242424"
              fontWeight="500"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.symbol}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "last_price",
      headerName: "Current Value",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
              marginLeft="5px"
            >
              {params?.row?.last_price ? `₹ ${params?.row?.last_price}` : ""}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "allocation",
      headerName: "Allocation % at current",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
              marginLeft="5px"
            >
              {params?.row?.allocation ? `${params?.row?.allocation} %` : ""}
            </Text>
          </Stack>
        </>
      ),
    },
  ];

  useEffect(() => {
    getAllocationPerformance({
      user_id: user_id,
      product_id: productId,
      member_id:memberID,
      page : paginationModel?.page+1,
      pageSize : paginationModel.pageSize
    });
  }, [user_id, productId, memberID ,paginationModel.page,paginationModel.pageSize]);

  const handlePaginationChange = (newPaginationModel) => { 
    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      page: newPaginationModel?.page,
      pageSize: newPaginationModel.pageSize,
    }));
  };

  return (
    <div>
      <Stack style={{ padding: "0px" }}>
        <CustomGrid
          style={{ padding: "0px" }}
          list={modifiedAllocationTableData}
          getRowId={(row) => row.id}
          columnHeaderHeight={46}
          header={CustomerHeader}
          rowHeight={72}
          columns={AllocationHeader}
          rowCount={totalCount}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={handlePaginationChange}
        />
      </Stack>
    </div>
  );
};

export default AllocationTable;
