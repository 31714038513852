import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import InvesmentDetails from "components/addCustomer/InvesmentDetails";
import { addCustomerApiAction } from "stores/redux/apiSlices/addCustomerApiSlice";
import { validationHelper } from "helpers/validation";
import { useLocation } from "react-router-dom";
function InvestmentDetailsContainer({ handleStepChange, currentUserId }) {
  //States
  const [formData, setFormData] = useState({
    capital: "0",
    // collateral: "0",
    isPercent: false,
    isCustomInvestmentSelected: false,
    customAmount: "",
    isModelPortfolioSelected: false,
    modelPorfolioAmount: "",
    isHighRiskSelected: false,
    highRiskAmount: "",
    isMediumRiskSelected: false,
    mediumRiskAmount: "",
    isLowRiskSelected: false,
    lowRiskAmount: "",
    isAlgoSelected: false,
    algoAmount: "",
  });

  const [formError, setFormError] = useState({
    capital: "",
    customAmount: "",
    modelPorfolioAmount: "",
    highRiskAmount: "",
    mediumRiskAmount: "",
    lowRiskAmount: "",
    algoAmount: "",
  });
  const [amountError, setAmountError] = useState(null);
  const [modalAmountError, setModalAmountError] = useState(null);
  const [apiResponse, setApiResponse] = useState(null); // State to store API response
  const location = useLocation();
  const onBoardData = location?.state && location?.state?.onBoardData;

  // const customerRiskDetails = useSelector(
  //   (state) => state.addCustomerSlice.customerDetails
  // );


  // Apis
  const { data: productDetails } = addCustomerApiAction.getProductDetails();
  const [submitInvestmentDetails] =
    addCustomerApiAction.updateInvestmentDetails();
  // const [getCustomerDetails, customerSummary] =
  //   addCustomerApiAction.getCustomerSummary();

  // //useEffect
  // useEffect(() => {
  //   if (onBoardData) {
  //     getCustomerDetails({ userId: onBoardData?.user_id }, false);
  //   } else if (currentUserId) {
  //     getCustomerDetails({ userId: currentUserId }, false);
  //   }
  // }, [onBoardData, currentUserId]);

  const [getCustomerDetails, customerSummary] = addCustomerApiAction.getCustomerSummary();

// useEffect
useEffect(() => {
  if (onBoardData) {
    getCustomerDetails({ userId: onBoardData?.user_id }, false)
    .then(response => {
      setApiResponse(response); // Set the API response to state variable
    });
      
  } else if (currentUserId) {
    getCustomerDetails({ userId: currentUserId }, false)
    .then(response => {
      setApiResponse(response); // Set the API response to state variable
    });
  }
}, [onBoardData, currentUserId]);

  useEffect(() => {
    if (customerSummary?.data) {
      setInvestmentDetails(customerSummary?.data);
    }
  }, [customerSummary.data]);

  const setInvestmentDetails = ({ investmentDetails, customerDetails }) => {
    let newFormData = { ...formData };
    if (customerDetails?.capital > 0) {
      newFormData.capital = customerDetails?.capital;
    }
    if (investmentDetails?.custom?.investmentAmount > 0) {
      newFormData.isCustomInvestmentSelected = true;
      newFormData.customAmount = investmentDetails?.custom?.investmentAmount;
    }
    if (investmentDetails?.modelType?.investmentAmount > 0) {
      newFormData.isModelPortfolioSelected = true;
      newFormData.modelPorfolioAmount =
        investmentDetails?.modelType?.investmentAmount;

      if (investmentDetails?.modelType?.product[0]?.investmentAmount > 0) {
        newFormData.isLowRiskSelected = true;
        newFormData.lowRiskAmount =
          investmentDetails?.modelType?.product[0]?.investmentAmount;
      }

      if (investmentDetails?.modelType?.product[1]?.investmentAmount > 0) {
        newFormData.isMediumRiskSelected = true;
        newFormData.mediumRiskAmount =
          investmentDetails?.modelType?.product[1]?.investmentAmount;
      }

      if (investmentDetails?.modelType?.product[2]?.investmentAmount > 0) {
        newFormData.isHighRiskSelected = true;
        newFormData.highRiskAmount =
          investmentDetails?.modelType?.product[2]?.investmentAmount;
      }
    }

    if (investmentDetails?.algoType?.investmentAmount > 0) {
      newFormData.isAlgoSelected = true;
      newFormData.algoAmount = investmentDetails?.algoType?.investmentAmount;
    }

    setFormData(newFormData);
  };

  const handleChange = ({ name, value }) => {
    // Check if the value is numeric
    if (!isNaN(value)) {
      // If Percentage button is clicked
      if (name === "isPercent") {
        let newFormData = { ...formData };
        // Check if conversion already applied
        if (value !== formData.isPercent) {
          if (value === true) {
            // Convert rupees to percentage for model, algo, and custom amounts
            let totalInvestment = parseFloat(formData.capital);
            if (
              newFormData.isCustomInvestmentSelected &&
              newFormData.customAmount !== ""
            ) {
              newFormData.customAmount =
                (parseFloat(newFormData.customAmount) / totalInvestment) * 100;
            }
            if (newFormData.isModelPortfolioSelected) {
              let totalPortfolioAmount = parseFloat(
                newFormData.modelPorfolioAmount
              );
              let totalRiskAmount =
                parseFloat(newFormData.highRiskAmount || 0) +
                parseFloat(newFormData.mediumRiskAmount || 0) +
                parseFloat(newFormData.lowRiskAmount || 0);
              let adjustmentRatio = totalPortfolioAmount / totalRiskAmount;
              newFormData.highRiskAmount =
                ((parseFloat(newFormData.highRiskAmount || 0) *
                  adjustmentRatio) /
                  totalInvestment) *
                100;
              newFormData.mediumRiskAmount =
                ((parseFloat(newFormData.mediumRiskAmount || 0) *
                  adjustmentRatio) /
                  totalInvestment) *
                100;
              newFormData.lowRiskAmount =
                ((parseFloat(newFormData.lowRiskAmount || 0) *
                  adjustmentRatio) /
                  totalInvestment) *
                100;
              newFormData.modelPorfolioAmount =
                (totalPortfolioAmount / totalInvestment) * 100;
            }
            if (newFormData.isAlgoSelected && newFormData.algoAmount !== "") {
              newFormData.algoAmount =
                (parseFloat(newFormData.algoAmount) / totalInvestment) * 100;
            }
            newFormData.isPercent = true; // Highlight the percentage button
          } else {
            // Convert percentage to rupees for model, algo, and custom amounts
            let totalInvestment = parseFloat(formData.capital);
            if (
              newFormData.isCustomInvestmentSelected &&
              newFormData.customAmount !== ""
            ) {
              newFormData.customAmount =
                (parseFloat(newFormData.customAmount) * totalInvestment) / 100;
            }
            if (newFormData.isModelPortfolioSelected) {
              let totalPortfolioAmount = parseFloat(
                newFormData.modelPorfolioAmount
              );
              let totalRiskAmount =
                parseFloat(newFormData.highRiskAmount || 0) +
                parseFloat(newFormData.mediumRiskAmount || 0) +
                parseFloat(newFormData.lowRiskAmount || 0);
              let adjustmentRatio = totalPortfolioAmount / totalRiskAmount;
              newFormData.highRiskAmount =
                (parseFloat(newFormData.highRiskAmount || 0) *
                  totalInvestment) /
                adjustmentRatio /
                100;
              newFormData.mediumRiskAmount =
                (parseFloat(newFormData.mediumRiskAmount || 0) *
                  totalInvestment) /
                adjustmentRatio /
                100;
              newFormData.lowRiskAmount =
                (parseFloat(newFormData.lowRiskAmount || 0) * totalInvestment) /
                adjustmentRatio /
                100;
              newFormData.modelPorfolioAmount =
                (totalPortfolioAmount * totalInvestment) / 100;
            }
            if (newFormData.isAlgoSelected && newFormData.algoAmount !== "") {
              newFormData.algoAmount =
                (parseFloat(newFormData.algoAmount) * totalInvestment) / 100;
            }
            newFormData.isPercent = false; // Unhighlight the percentage button
          }
        }
        setFormData(newFormData);
      } else {
        // For other fields, just update the value
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    }
  };

  const handleValidation = () => {
    const newFormError = { ...formError };

    const capitalValidation = validationHelper.required(formData.capital);
    newFormError.capital = capitalValidation.message;

    let customValidation = { isValid: 1 };
    if (formData.isCustomInvestmentSelected) {
      customValidation = validationHelper.required(formData.customAmount);
      newFormError.customAmount = customValidation.message;
    } else {
      newFormError.customAmount = "";
    }

    let modelPortfolioValidation = { isValid: 1 };
    if (formData.isModelPortfolioSelected) {
      modelPortfolioValidation = validationHelper.required(
        formData.modelPorfolioAmount
      );
      newFormError.modelPorfolioAmount = modelPortfolioValidation.message;
    } else {
      newFormError.modelPorfolioAmount = "";
    }

    let highRiskValidation = { isValid: 1 };
    if (formData.isHighRiskSelected) {
      highRiskValidation = validationHelper.required(formData.highRiskAmount);
      newFormError.highRiskAmount = highRiskValidation.message;
    } else {
      newFormError.highRiskAmount = "";
    }

    let mediumRiskValidation = { isValid: 1 };
    if (formData.isMediumRiskSelected) {
      mediumRiskValidation = validationHelper.required(
        formData.mediumRiskAmount
      );
      newFormError.mediumRiskAmount = mediumRiskValidation.message;
    } else {
      newFormError.mediumRiskAmount = "";
    }

    let lowRiskValidation = { isValid: 1 };
    if (formData.isLowRiskSelected) {
      lowRiskValidation = validationHelper.required(formData.lowRiskAmount);
      newFormError.lowRiskAmount = lowRiskValidation.message;
    } else {
      newFormError.lowRiskAmount = "";
    }

    let algoValidation = { isValid: 1 };
    if (formData.isAlgoSelected) {
      algoValidation = validationHelper.required(formData.algoAmount);
      newFormError.algoAmount = algoValidation.message;
    } else {
      newFormError.algoAmount = "";
    }
    setFormError(newFormError);

    return (
      capitalValidation.isValid &&
      customValidation.isValid &&
      modelPortfolioValidation.isValid &&
      highRiskValidation.isValid &&
      mediumRiskValidation.isValid &&
      lowRiskValidation.isValid &&
      algoValidation.isValid
    );
  };

  const payloadParser = () => {
    let payload = {};
    payload.capital = formData.capital;
    // payload.collateral = formData.collateral;
    payload.isPercent = formData.isPercent;
    let products = [];

    if (formData.isCustomInvestmentSelected) {
      let product = {
        product_id: 1,
        amount: formData.customAmount,
        category: [],
      };
      products.push(product);
    }
    if (formData.isModelPortfolioSelected) {
      let product = {
        product_id: 2,
        amount: formData.modelPorfolioAmount,
      };
      let category = [];
      if (formData.isHighRiskSelected) {
        let categoryProd = {
          product_category_id: 1,
          amount: formData.highRiskAmount,
        };
        category.push(categoryProd);
      }
      if (formData.isMediumRiskSelected) {
        let categoryProd = {
          product_category_id: 2,
          amount: formData.mediumRiskAmount,
        };
        category.push(categoryProd);
      }
      if (formData.isLowRiskSelected) {
        let categoryProd = {
          product_category_id: 3,
          amount: formData.lowRiskAmount,
        };
        category.push(categoryProd);
      }
      product.category = category;
      products.push(product);
    }
    if (formData.isAlgoSelected) {
      let product = {
        product_id: 3,
        amount: formData.algoAmount,
        category: [],
      };
      products.push(product);
    }
    payload.products = products;
    return payload;
  };

  const handleSubmit = async () => {
    // Perform form validation
    if (!handleValidation()) {
      return;
    }

    // Check if capital is greater than zero
    if (parseFloat(formData.capital) <= 0) {
      setFormError((prevState) => ({
        ...prevState,
        capital: "Capital amount must be greater than zero.",
      }));
      return;
    }

    // Check if model portfolio amount is selected
    if (formData.isModelPortfolioSelected) {
      const modelPortfolioAmount = parseFloat(
        formData.modelPorfolioAmount || 0
      );
      const highRiskAmount = parseFloat(formData.highRiskAmount || 0);
      const mediumRiskAmount = parseFloat(formData.mediumRiskAmount || 0);
      const lowRiskAmount = parseFloat(formData.lowRiskAmount || 0);

      if (
        modelPortfolioAmount !==
        highRiskAmount + mediumRiskAmount + lowRiskAmount
      ) {
        setFormError((prevState) => ({
          ...prevState,
          modelPorfolioAmount:
            "Model portfolio amount must be equal to the entered amount.",
        }));
        return;
      }
    }

    // Calculate total investment
    let totalInvestment = 0;

    if (formData.isCustomInvestmentSelected) {
      totalInvestment += parseFloat(formData.customAmount || 0);
    }
    if (formData.isModelPortfolioSelected) {
      totalInvestment += parseFloat(formData.modelPorfolioAmount || 0);
    }
    if (formData.isAlgoSelected) {
      totalInvestment += parseFloat(formData.algoAmount || 0);
    }

    const capital = parseFloat(formData.capital || 0);

    // Check if total investment matches capital
    if (totalInvestment !== capital && !formData.isPercent) {
      setAmountError("Total investment must be equal to capital amount.");
      return;
    }

    try {
      const parsedPayload = payloadParser();
      let payload = {
        investmentDetails: parsedPayload,
        userId: onBoardData
          ? onBoardData?.user_id
          : currentUserId,
      };
      const res = await submitInvestmentDetails(payload);
      if (res) {
        handleStepChange(4);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <InvesmentDetails
        customerRiskDetails={onBoardData}
        handleStepChange={handleStepChange}
        handleChange={handleChange}
        formData={formData}
        formError={formError}
        handleSubmit={handleSubmit}
        amountError={amountError}
        modalAmountError={modalAmountError}
        apiResponse={apiResponse} // Pass the API response as a prop
      />
    </>
  );
}

export default InvestmentDetailsContainer;
