import React, { useState, useEffect, useRef } from "react";
import Button from "components/common/Button";
import Card from "components/common/Card";
import Text from "components/common/Text";
import Box from "components/common/Box";
import Divider from "components/common/Divider";
import Grid from "components/common/Grid";
import TextField from "components/common/TextField";
import Dialog from "components/common/Dialog";
import Stack from "components/common/Stack";
import DownloadButton from "components/common/DownloadButton";
import OnOffButton from "components/addCustomer/OnOffButton";
import dayjs from "dayjs";
import { Chip } from "@mui/material";
import { styled } from "@mui/system";
import { useSelector } from "react-redux";
import Avatar from "components/common/Avatar";
import CustomerRiskProfileModal from "./CustomerRiskProfileModal";
import { CustomerProfileApiAction } from "stores/redux/apiSlices/CustomerPortfolio/customerProfileApiSlice";
import BrokerDetails from "customers/BrokerDetails";
import Checkbox from "components/common/Checkbox";
import { clickAndDownloadFile } from "helpers/fileFormat";
import Link from "components/common/Link";
import FileUpload from "components/common/FileUpload";
import { validationHelper } from "helpers/validation";
import { fileToBase64 } from "helpers/fileFormat";

const ProfileBox = styled(Box)(() => ({
  width: "236px",
  height: "236px",
  borderRadius: "9px",
  backgroundColor: "#E5EEFF",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const CancelBtn = styled(Button)(() => ({
  border: "1px solid #142E56",
  borderRadius: "4px",
  color: "#142E56",
  textTransform: "capitalize",
  width: "116px",
  padding: "8px 16px",
  marginRight: 4,
}));

const SaveBtn = styled(Button)(() => ({
  border: "1px solid #142E56",
  borderRadius: "4px",
  color: "#FFF",
  textTransform: "capitalize",
  width: "116px",
  padding: "8px 16px",
  marginLeft: 4,
}));

const ChipStyle = styled(Chip)(({ active = 0 }) => ({
  backgroundColor: active ? "#026902" : "#B22323",
  color: "#FFFFFF",
  width: "103px",
  height: "28px",
  padding: "0px 10px",
  marginLeft: "20px",
}));

const LabelText = styled(Text)(() => ({
  fontSize: "16px",
  fontWeight: 400,
  color: "#667085",
  width: "250px",
  textTransform: "uppercase",
}));

const CustomerProfileCard = () => {
  const [profile, setProfile] = useState(null);
  const [riskprofile, setRiskProfile] = useState(false);
  const [riskProfileID, setRiskProfileID] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editedValues, setEditedValues] = useState({});
  const [isChecked, setIsChecked] = useState(null);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [documentID, setDocumentID] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [previousIsChecked, setPreviousIsChecked] = useState(false);
  const [formData, setFormData] = useState({
    aadharCard: null,
  });
  const [formError, setFormError] = useState({
    aadharCard: null,
  });

  const fileInputRef = useRef(null);

  const userDetail = useSelector((state) => state.commonReducer.userDetails); // from redux

  const { data: profileData, refetch: refetchProfileData } =
    CustomerProfileApiAction.getCustomerProfileApi({
      user_id: userDetail.user_id,
    });

  const [handleUpdateProfileApi] = CustomerProfileApiAction.updateProfileApi({
    user_id: userDetail.user_id,
  });
  const [refetchProfileDoc, { data: profileDocApi = {} }] =
    CustomerProfileApiAction.getProfileDocApi();

  const [fetchSignAgreementDoc] =
    CustomerProfileApiAction.getLazySignAgreementApi();

  const [submitOtherDetails] = CustomerProfileApiAction.updateOtherDetails();

  const base64String = profileDocApi.file;

  const xyz = () => {
    if (profileData && profileData.is_auto_trade) {
      if (profileData.is_auto_trade === "N") {
        setIsChecked(false);
      } else if (profileData.is_auto_trade === "Y") {
        setIsChecked(true);
      }
    }
  };

  useEffect(() => {
    refetchProfileData();
    xyz();
  }, []);

  useEffect(() => {
    if (documentID) {
      refetchProfileDoc({ documentID });
    }
  }, [documentID]);

  useEffect(() => {
    refetchProfileData();
  }, []);

  useEffect(() => {
    if (profileData) {
      const isAutoTradeEnabled = profileData.is_auto_trade === "Y";
      setIsChecked(isAutoTradeEnabled);
      setPreviousIsChecked(isAutoTradeEnabled);
    }
  }, [profileData]);

  // useEffect(() => {
  //   handleValidation(); // Run validation when the component mounts or updates
  // }, []);

  if (!profileData) {
    return <div>Loading...</div>;
  }
  const handleValidation = () => {
    let isValid = true;
    let errors = {};

    // Only validate the aadharCard field if isAutoTradeDocumentUpload is true

    if (!profileData.isAutoTradeDocumentUpload && !formData.aadharCard) {
      if (!formData.aadharCard) {
        isValid = false;
        errors.aadharCard = "This field is required";
      }
    }

    setFormError(errors);
    return isValid;
  };

  // Define the function to trigger file download
  const downloadFile = (file, filename) => {
    const blob = new Blob([file]);
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(link.href);
  };

  // Function to get document ID and trigger download
  const getDocumentID = async (documentName) => {
    const foundDoc = profileData.documents.find(
      (doc) => doc.document_name === documentName
    );

    const payload = {
      base64File: base64String,
      fileName: foundDoc.document_name,
      documentID: foundDoc.user_document_id,
    };

    try {
      let res = await refetchProfileDoc(payload, false);
      if (res.data.file) {
        const { file, filename } = res.data;
        await clickAndDownloadFile({ base64File: file, fileName: filename });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const openriskprofilemodal = () => {
    setRiskProfile(true);
  };

  const riskcarddata = [
    {
      id: 1,
      name: "Aggressive risk profile ",
      chart: "/images/aggressive.svg",
    },
    {
      id: 2,
      name: "Moderate risk profile ",
      chart: "/images/moderate.svg",
    },
    {
      id: 3,
      name: "Conservative risk profile ",
      chart: "/images/conservative.svg",
    },
  ];

  const handleChange = () => {
    if (!isChecked) {
      setModalOpen(true);
      setIsChecked((prev) => !prev);
      setPreviousIsChecked(isChecked);
    } else {
      setIsChecked((prev) => !prev);
    }
  };
  const handleChange1 = ({ name, value }) => {
    let newFormData = { ...formData, [name]: value };
    setFormData(newFormData);
  };

  const handleRiskProfileClick = (profile) => {
    setSelectedProfile(profile);
  };

  const userDetails = [
    {
      label: "Email",
      key: "email",
      value: `${profileData.email}`,
      isEditable: true,
    },
    {
      label: "Phone",
      key: "phone",
      value: `${profileData.mobile}`,
      isEditable: true,
    },
    {
      label: "Customer residency",
      key: "joiningDate",
      value: `${profileData.residency}`,
      isEditable: false,
    },
    {
      label: "Customer Type",
      key: "userType",
      value:
        userDetail.mappedTo == "SUBBROKER"
          ? "Sub-Broker Customer"
          : `${profileData.customer_type}`,
      isEditable: false,
    },    
    {
      label: "Auto trade",
      key: "autoTrade",
      value: false,
      isEditable: false,
    },
    {
      label: "Customer category",
      key: "customerCategory",
      value: `${profileData.category}`,
      isEditable: false,
    },
    {
      label: "Family name",
      key: "familyName",
      value: `${profileData.family_name || "NA"}`,
      isEditable: false,
    },
    {
      label: "Plan name",
      key: "plan",
      value: `${profileData.plan_name}`,
      isEditable: false,
    },
    {
      label: "Expiry Date",
      key: "expiryDate",
      value: {
        date: dayjs(profileData.end_date).format("DD-MM-YYYY"),
        isExpired: profileData.is_plan_expired || false,
      },
      isEditable: false,
    },
    {
      label: "Assessed Risk Profile",
      key: "risk",
      value: `${profileData.suggested_risk_profile}`,
      isEditable: false,
      rendercell: () => (
        <Box style={{ display: "flex", alignItems: "center" }}>
          {profileData.suggested_risk_profile === "Aggressive" ? (
            <Avatar
              style={{
                width: "52px",
                height: "30px",
                borderRadius: "0px",
              }}
              src={riskcarddata[0]?.chart}
            ></Avatar>
          ) : profileData.suggested_risk_profile === "Moderate" ? (
            <Avatar
              style={{
                width: "52px",
                height: "30px",
                borderRadius: "0px",
              }}
              src={riskcarddata[1]?.chart}
            ></Avatar>
          ) : profileData.suggested_risk_profile === "Conservative" ? (
            <Avatar
              style={{
                width: "52px",
                height: "30px",
                borderRadius: "0px",
              }}
              src={riskcarddata[2]?.chart}
            ></Avatar>
          ) : (
            ""
          )}
          <Text sx={{ fontSize: "14px", fontWeight: 600, ml: "13px" }}>
            {profileData.suggested_risk_profile}
          </Text>
        </Box>
      ),
    },
    {
      label: "Selected Risk Profile",
      key: "risk",
      value: "Selected Risk Profile",
      isEditable: false,
      rendercell: () => (
        <Box style={{ display: "flex", alignItems: "center" }}>
          {profileData.selected_risk_profile === "Aggressive" ? (
            <Avatar
              style={{
                width: "52px",
                height: "30px",
                borderRadius: "0px",
              }}
              src={riskcarddata[0]?.chart}
            ></Avatar>
          ) : profileData.selected_risk_profile === "Moderate" ? (
            <Avatar
              style={{
                width: "52px",
                height: "30px",
                borderRadius: "0px",
              }}
              src={riskcarddata[1]?.chart}
            ></Avatar>
          ) : profileData.selected_risk_profile === "Conservative" ? (
            <Avatar
              style={{
                width: "52px",
                height: "30px",
                borderRadius: "0px",
              }}
              src={riskcarddata[2]?.chart}
            ></Avatar>
          ) : (
            ""
          )}
          <Text sx={{ fontSize: "14px", fontWeight: 600, ml: "13px" }}>
            {profileData.selected_risk_profile}
          </Text>

          {editMode && (
            <Text
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                ml: "13px",
                color: "#1A54B9",
                cursor: "pointer",
              }}
              onClick={openriskprofilemodal}
            >
              Select another risk profile
            </Text>
          )}

          {riskprofile ? (
            <CustomerRiskProfileModal
              isOpen={riskprofile}
              selectedRiskProfile={selectedProfile}
              handleClose={() => {}}
              handleRiskProfileClick={handleRiskProfileClick}
              handleSubmit={handleSubmit}
            />
          ) : null}
        </Box>
      ),
    },
    {
      label: "Broker name",
      key: "broker_name",
      value: `${profileData.broker_name || "NA"}`,
      isEditable: false,
    },
  ];

  const handleEditClick = () => {
    setEditMode(true);
    const initialEditedValues = {};
    userDetails.forEach((detail) => {
      initialEditedValues[detail.key] = detail.value;
    });
    setEditedValues(initialEditedValues);
  };

  const handleCancelClick = () => {
    setEditMode(false);

    // Reset the file upload data and any associated errors
    setFormData({
      aadharCard: null, // Clear the uploaded file
    });
    setFormError({
      aadharCard: null, // Clear any errors related to the file upload
    });

    // Reset any other states as necessary
    setIsCheckboxChecked(false);
    setModalOpen(false);
    setEditedValues({}); // Clear any edited values
  };

  const handleCancel = () => {
    setIsChecked((prevChecked) => !prevChecked);
    setIsCheckboxChecked(false);
    setModalOpen(false);
    close();

    // Clear form data
    setFormData({});

    // Clear file input
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleSaveClick = async (event) => {
    event.preventDefault();

    const formattedJoiningDate = dayjs(profileData.joining_date).format(
      "YYYY-MM-DD"
    );
    const formattedEndDate = dayjs(profileData.end_date).format("YYYY-MM-DD");
    const formattedStartDate = dayjs(profileData.start_date).format(
      "YYYY-MM-DD"
    );

    const payload = {
      user_id: userDetail.user_id,
      email: editedValues.email,
      mobile: editedValues.phone,
      user_name: profileData.user_name,
      joining_date: formattedJoiningDate,
      user_investment_id: profileData.user_investment_id,
      residency: profileData.residency,
      customer_type: profileData.customer_type,
      category: profileData.category,
      is_auto_trade: isChecked ? "Y" : "N",
      family_detail_id: profileData.family_detail_id,
      family_id: profileData.family_id,
      family_name: profileData.family_name,
      is_admin: profileData.is_admin,
      user_subscription_id: profileData.user_subscription_id,
      plan_id: profileData.plan_id,
      plan_detail_id: profileData.plan_detail_id,
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      risk_profile_id: riskProfileID || profileData.selected_risk_profile_id,
    };

    try {
      const response = await handleUpdateProfileApi(payload);

      if (response.data.statusCode === 200) {
        await refetchProfileData();
        setEditMode(false);
      } else {
        console.error("Error updating profile. Status code:", response.status);
        // Revert the toggle switch state on failure
        setIsChecked(previousIsChecked);
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      // Revert the toggle switch state on error
      setIsChecked(previousIsChecked);
    }
  };

  const handleInputChange = (key, value) => {
    setEditedValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };
  const handleButtonClick = async (event) => {
    event.preventDefault();

    if (!handleValidation()) {
      return;
    }

    setIsCheckboxChecked(false);
    setModalOpen(false);

    let aadharString = "";

    if (formData.aadharCard) {
      const getBase64String = async (file) => {
        if (Array.isArray(file)) {
          file = file[0];
        }
        const base64 = await fileToBase64(file);
        let fileType = file.type;

        if (fileType === "image/jpeg") {
          fileType = "application/jpeg";
        }

        if (fileType === "image/png") {
          fileType = "application/png";
        }

        return { base64, fileType };
      };

      const { base64, fileType } = await getBase64String(formData.aadharCard);
      aadharString = `data:${fileType};base64,${base64}`;
    }

    const payload1 = {
      auto_trade_agreement_base64: aadharString || "",
    };

    try {
      const res1 = await submitOtherDetails(payload1);
      console.log("res1", res1);
    } catch (err) {
      console.log(err);
    }
  };

  // Function to handle submitting the selected risk profile
  const handleSubmit = () => {
    let profileValue;
    if (selectedProfile === "aggressive") {
      profileValue = 1;
    } else if (selectedProfile === "moderate") {
      profileValue = 2;
    } else if (selectedProfile === "conservative") {
      profileValue = 3;
    }
    // Now you can use the profileValue for further processing or payload
    setRiskProfileID(profileValue);
    setRiskProfile(false);
  };

  const AvatarName = profileData.user_name
    .split(" ")
    .map((word) => word.charAt(0))
    .join("");

  const getDocumentDisplayName = (documentName) => {
    const foundDocument = profileData.documents.find(
      (doc) => doc.document_name === documentName
    );
    if (foundDocument) {
      return foundDocument.display_name;
    } else {
      // Return a default name or an empty string if no matching document found
      return "";
    }
  };

  const handleCheckboxChange = (event) => {
    setIsCheckboxChecked((prevChecked) => !prevChecked);
  };

  const downloadAgreementTemplate = async () => {
    // let userId = userDetail.user_id;
    let res = await fetchSignAgreementDoc();

    if (res.data.file) {
      const { file, filename } = res.data;
      await clickAndDownloadFile({ base64File: file, fileName: filename });
    }
  };

  return (
    <>
      <Box>
        <Card sx={{ p: "24px" }}>
          <Stack direction="row" justifyContent="space-between" p="16px">
            <Stack>
              <Text
                sx={{ color: "#101828", fontSize: "20px", fontWeight: 500 }}
              >
                {profileData.user_name}
              </Text>
            </Stack>

            <Box>
              {editMode ? (
                <>
                  <CancelBtn variant="outlined" onClick={handleCancelClick}>
                    {" "}
                    Cancel
                  </CancelBtn>

                  <SaveBtn variant="contained" onClick={handleSaveClick}>
                    Save
                  </SaveBtn>
                </>
              ) : (
                <Button
                  variant="outlined"
                  onClick={handleEditClick}
                  sx={{
                    border: "1px solid #828282",
                    borderRadius: "4px",
                    color: "#101828",
                    textTransform: "capitalize",
                  }}
                >
                  Edit Details
                </Button>
              )}
            </Box>
          </Stack>

          <Divider />

          <Stack direction="row" p="32px 0px 32px 40px">
            <ProfileBox>
              <Text
                sx={{ fontSize: "64px", fontWeight: 600, color: "#104960" }}
              >
                {AvatarName}
                {/*  */}
              </Text>
            </ProfileBox>

            <Box sx={{ marginLeft: 8 }}>
              {userDetails.map((detail, index) => {
                if (detail.key === "expiryDate") {
                  console.log("is Expaied", detail);
                }
                if (detail.key === "autoTrade") {
                  return (
                    <Stack
                      direction="row"
                      alignItems="center"
                      key={index}
                      sx={{
                        mb: 2,
                      }}
                    >
                      <LabelText>{detail.label}</LabelText>
                      <Text
                        sx={{
                          color: "#667085",
                          fontSize: "16px",
                          fontWeight: 400,
                          mr: "20px",
                        }}
                      >
                        :
                      </Text>

                      {editMode ? (
                        <OnOffButton
                          checked={isChecked}
                          onChange={handleChange}
                          opacity={1}
                          cursor="pointer"
                          onText="Enabled"
                          offText="Disabled"
                        />
                      ) : (
                        <OnOffButton
                          checked={isChecked}
                          opacity={1}
                          cursor="unset"
                          onText="Enabled"
                          offText="Disabled"
                        />
                      )}
                      <Link
                        onClick={downloadAgreementTemplate}
                        href="#"
                        underline="always"
                        sx={{
                          color: "#0C53B4",
                          fontSize: "14px",
                          fontWeight: "500px",
                          ml: 2,
                        }}
                      >
                        {"Download Agreement"}
                      </Link>
                    </Stack>
                  );
                }

                if (detail.key === "expiryDate") {
                  return (
                    <Stack
                      key={index}
                      direction="row"
                      alignItems="center"
                      mb={2}
                    >
                      <LabelText>{detail.label}</LabelText>
                      <Text
                        sx={{
                          color: "#667085",
                          fontSize: "16px",
                          fontWeight: 400,
                          mr: "20px",
                        }}
                      >
                        :
                      </Text>

                      <Stack direction="row" alignItems="center">
                        <Text sx={{ width: "120px" }}>{detail.value.date}</Text>
                        {detail.value.isExpired ? (
                          <ChipStyle label="Expired" />
                        ) : (
                          <ChipStyle active label="Active" />
                        )}
                      </Stack>
                    </Stack>
                  );
                }

                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <Text
                      sx={{
                        fontSize: "16px",
                        fontWeight: 400,
                        color: "#667085",
                        width: "250px",
                        textTransform: "uppercase",
                      }}
                    >
                      {detail.label}
                    </Text>
                    <Text
                      sx={{
                        mr: "20px",
                        color: "#667085",
                        fontSize: "16px",
                        fontWeight: 400,
                      }}
                    >
                      :
                    </Text>
                    {detail.rendercell ? (
                      detail.rendercell() // Call the renderCell function
                    ) : editMode && detail.isEditable ? (
                      <TextField
                        placeholder={detail.label}
                        size="small"
                        value={editedValues[detail.key]}
                        onChange={(e) => {
                          // Trim input to 10 characters if it exceeds
                          const trimmedValue = e.target.value.slice(0, 10);
                          handleInputChange(detail.key, trimmedValue);
                        }}
                        // onInput={(e) => {
                        //   e.target.value = e.target.value.replace(
                        //     /[^0-9]/g,
                        //     ""
                        //   );
                        // }}
                        sx={{
                          display: "flex",
                          width: "300px",
                          p: "4px 16px",
                          alignItems: "center",
                          borderRadius: "1px solid #CBCBCB",
                        }}
                      />
                    ) : (
                      <Text
                        sx={{
                          fontSize: "16px",
                          color: "#101828",
                          fontWeight: 500,
                          flexShrink: 0,
                        }}
                      >
                        {detail.value}
                      </Text>
                    )}
                  </Box>
                );
              })}
            </Box>
            <Dialog
              open={modalOpen}
              onClose={() => setModalOpen(false)}
              disableCloseIcon
              maxWidth="sm"
              max
              sx={{ borderRadius: "10px" }}
              enableBackdropClick={false}
              title="Auto Trade "
              contentComponent={() => (
                <>
                  <Box sx={{ display: "flex" }}>
                    <Checkbox
                      sx={{ mt: "2px" }}
                      checked={isCheckboxChecked}
                      onChange={handleCheckboxChange}
                    />
                    <Text
                      sx={{
                        color: "#242424",
                        fontSize: "16px",
                        fontWeight: 500,
                        mb: 2,
                        mt: 2,
                      }}
                    >
                      Are you sure you want to change the auto trade setting?
                    </Text>
                  </Box>

                  {!profileData.isAutoTradeDocumentUpload ? (
                    <>
                      <FileUpload
                        name="aadharCard"
                        handleChange={handleChange1}
                        acceptedFileTypes={[
                          "application/pdf",
                          // "image/jpeg",
                          // "image/jpg",
                          // "image/png",
                        ]}
                      />
                      {formError.aadharCard && (
                        <Text variant="small" color="red" py={1}>
                          {formError.aadharCard}
                        </Text>
                      )}
                    </>
                  ) : null}
                </>
              )}
              actionComponent={(close) => (
                <>
                  <Box mb={2}>
                    <Button
                      // onClick={() => {
                      //   setIsChecked((prevChecked) => !prevChecked);
                      //   setIsCheckboxChecked(false);
                      //   close();
                      // }}
                      onClick={handleCancel}
                      disableEndIcon={false}
                      variant="outlined"
                      size="large"
                      m={0.5}
                      sx={{
                        borderRadius: "8px",
                        border: "1px solid #142E56",
                        fontSize: "16px",
                        fontWeight: 600,
                        width: "46%",
                        ml: 2,
                        mr: 2,

                        textTransform: "capitalize",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleButtonClick}
                      disableEndIcon={false}
                      variant="outlined"
                      size="large"
                      m={0.5}
                      sx={{
                        borderRadius: "8px",
                        border: "1px solid #142E56",
                        // backgroundColor: "#142E56",
                        // color: "#FFFFFF",
                        backgroundColor: isCheckboxChecked
                          ? "#142E56"
                          : "#828282",
                        color: isCheckboxChecked ? "#FFFFFF" : "#666666",
                        fontSize: "16px",
                        fontWeight: 600,
                        width: "46%",
                        "&:hover": { backgroundColor: "#142E56" },
                        textTransform: "capitalize",
                      }}
                      disabled={!isCheckboxChecked}
                    >
                      Confirm
                    </Button>
                  </Box>
                </>
              )}
            />
          </Stack>

          <Divider />
          <Text
            sx={{
              fontSize: "16px",
              fontWeight: 500,
              mb: "24px",
              mt: "20px",
              color: "#242424",
            }}
          >
            Documents Submitted
          </Text>
          <Stack sx={{ mb: "20px" }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Text
                  sx={{
                    color: "#676C76",
                    fontSize: "14px",
                    fontWeight: 400,
                    mb: "6px",
                  }}
                >
                  PAN CARD
                </Text>
                <DownloadButton
                  onClick={() => getDocumentID("PAN_CARD")}
                  children={getDocumentDisplayName("PAN_CARD")}
                />
              </Grid>

              <Grid item xs={4}>
                <Text
                  sx={{
                    color: "#676C76",
                    fontSize: "14px",
                    fontWeight: 400,
                    mb: "6px",
                  }}
                >
                  Aadhar Card
                </Text>
                <DownloadButton
                  onClick={() => getDocumentID("AADHAR_CARD")}
                  children={getDocumentDisplayName("AADHAR_CARD")}
                />
              </Grid>

              {userDetail.mappedTo !== "SUBBROKER" && (
              <Grid item xs={4}>
                <Text
                  sx={{
                    color: "#676C76",
                    fontSize: "14px",
                    fontWeight: 400,
                    mb: "6px",
                  }}
                >
                  Sign Agreement
                </Text>
                <DownloadButton
                  onClick={() => getDocumentID("SIGN_AGREEMENT")}
                  children={getDocumentDisplayName("SIGN_AGREEMENT")}
                />
              </Grid>
              )}

              {profileData?.documents.some(
                (doc) => doc.document_name === "AUTO_TRADE_AGREEMENT"
              ) && (
                <Grid item xs={4}>
                  <Text
                    sx={{
                      color: "#676C76",
                      fontSize: "14px",
                      fontWeight: 400,
                      mb: "6px",
                    }}
                  >
                    Auto Trade Agreement
                  </Text>
                  <DownloadButton
                    onClick={() => getDocumentID("AUTO_TRADE_AGREEMENT")}
                    children={getDocumentDisplayName("AUTO_TRADE_AGREEMENT")}
                  />
                </Grid>
              )}
            </Grid>
          </Stack>

          <Divider sx={{ mt: "40px" }} />
          <Text
            sx={{
              fontSize: "16px",
              fontWeight: 500,
              mb: "7px",
              mt: "10px",
              color: "#242424",
            }}
          >
            Broker Details
          </Text>
          <BrokerDetails />
        </Card>
      </Box>
    </>
  );
};

export default CustomerProfileCard;
