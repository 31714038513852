import React, { useState, useEffect } from "react";
import Box from "components/common/Box";
import TextField from "components/common/TextField";
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import IconButton from "components/common/IconButton";
import CustomGrid from "components/datagrid/CustomGrid";
import EditIcon from "asset/icons/EditIcon";
import Button from "components/common/Button";
import SideDrawer from "components/common/SideDrawer";
import { dividendApiAction } from "stores/redux/dividend/dividendApiSlice";
import DatePicker from "components/common/DatePicker";
import { profileApiAction } from "stores/redux/riaProfile/riaProfileSlice";
import AddIcon from "@mui/icons-material/Add";
import Autocomplete from "@mui/material/Autocomplete";
import { validationHelper } from "helpers/validation";
import dayjs from "dayjs";
import styled from "@emotion/styled";
import { toastActions } from "stores/redux/slices/toastSlice";
import { UseDispatch, useDispatch } from "react-redux";
import { param } from "jquery";
import SearchIcon from "@mui/icons-material/Search";
import useDebounce from "hooks/Debounce";

const HeaderBox = styled(Box)(({}) => ({
  display: "flex",
  padding: "24px",
  justifyContent: "space-between",
}));
const DividendText = styled(Text)(({}) => ({
  color: "primary",
  marginRight: 1,
  fontWeight: 500,
  fontSize: "18px",
}));
const AddDividendBtn = styled(Button)(({}) => ({
  padding: "10px 16px",
  width: "155px",
  height: "40px",
  borderRadius: "8px",
}));
const StockNameText = styled(Text)(({}) => ({
  color: "#242424",
  fontSize: "14px",
  fontWeight: 500,
  marginBottom: "6px",
}));
const ExDividendText = styled(Text)(({}) => ({
  color: "#242424",
  fontSize: "14px",
  fontWeight: 500,
  marginBottom: "6px",
}));
const DividendpercText = styled(Text)(({}) => ({
  color: "#242424",
  fontSize: "14px",
  fontWeight: 500,
  marginBottom: "6px",
}));

function DividendContainer() {
  const [isDrawerAddDividend, setDrawerAddDividend] = useState(false);
  const [stockName, setStockName] = useState("");
  const [exDividendDate, setExDividendDate] = useState(null);
  const [dividendPercentage, setDividendPercentage] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedDividendId, setSelectedDividendId] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();
  const { data: profileData, refetch } = profileApiAction.getProfileApi();

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });

  const [addFormError, setAddFormError] = useState({
    stockName: "",
    dividendDate: "",
    dividendPerc: "",
  });
  const [getDividendApi, { data: dividendData = [] }] =
    dividendApiAction.getDividendApi();

  const dividendDataDetail = dividendData.data;

  const debouncedSearchValue = useDebounce(searchValue, 500);

  React.useEffect(() => {
    getDividendApi({
      page: paginationModel.page * paginationModel.pageSize,
      pageSize: paginationModel.pageSize,
      searchValue: debouncedSearchValue,
    });
  }, [
    paginationModel.page,
    paginationModel.pageSize,
    debouncedSearchValue,
    getDividendApi,
  ]);

  const handlePaginationChange = (newPaginationModel) => {
    setPaginationModel({
      ...paginationModel,
      page: newPaginationModel.page,
      pageSize: newPaginationModel.pageSize,
    });
  };

  // here we are calling the Company data API
  const [getAllStockName, { data: companyData }] =
    dividendApiAction.getCompanyApi();

  const [formDataSearch, setFormDataSearch] = useState("");

  const debouncedSearchValueStock = useDebounce(formDataSearch, 500);

  useEffect(() => {
    let payload = {
      search: debouncedSearchValueStock,
      // exchange: "NSE",
    };
    getAllStockName(payload);
  }, [debouncedSearchValueStock]);

  const data = companyData;

  //Apis call
  const [handleAddDividendApi] = dividendApiAction.addDividendApi();
  const [handleUPDATEDividendApi] = dividendApiAction.updateDividendApi();

  const handleEdit = (rowData) => {
    const dividendId = rowData.dividend_id ?? null;
    setSelectedDividendId(dividendId);

    // Make sure to use 'rowData.symbol' instead of 'rowData.symbol ?? ""'
    setStockName({ label: rowData.symbol, symbol: rowData.symbol });

    setExDividendDate(rowData.date ? dayjs(rowData.date) : null);
    // basl_expiry: dayjs(editedValues.baslExpiry).format("YYYY-MM-DD"),

    setDividendPercentage(rowData.percentage ?? "");
    setIsEditing(true);
    setDrawerAddDividend(true);
  };

  const handleAddValidation = () => {
    let newAddError = { ...addFormError };
    const stockValidation = validationHelper.required(stockName);
    newAddError.stockName = stockValidation.message;
    const dateValidation = validationHelper.required(exDividendDate);
    newAddError.dividendDate = dateValidation.message;
    const dividendValidation = validationHelper.required(dividendPercentage);
    newAddError.dividendPerc = dividendValidation.message;

    setAddFormError(newAddError);

    return (
      stockValidation.isValid &&
      dateValidation.isValid &&
      dividendValidation.isValid
    );
  };

  const handleSave = async () => {
    if (!handleAddValidation()) {
      return;
    }

    try {
      if (isEditing && selectedDividendId) {
        const payload = {
          dividend_id: selectedDividendId,
          stock_symbol: stockName.symbol,
          date: dayjs(exDividendDate).format("YYYY-MM-DD"),
          percentage: parseInt(dividendPercentage),
        };
        const res = await handleUPDATEDividendApi(payload);

        if (res) {
          const toastMessage =
            res.data.status === 1
              ? "Dividend Updated Successfully"
              : res.message;

          dispatch(
            toastActions.setToastData({
              message: toastMessage,
              variant: res.data.status === 1 ? "success" : "error",
            })
          );
        }
        if (res.data.status === 1) {
          getDividendApi({
            page: paginationModel.page * paginationModel.pageSize,
            pageSize: paginationModel.pageSize,
          });
        }
      } else {
        const payload = {
          stock_symbol: stockName.symbol,
          date: dayjs(exDividendDate).format("YYYY-MM-DD"),
          percentage: parseInt(dividendPercentage),
        };
        const res = await handleAddDividendApi(payload);

        if (res) {
          const toastMessage =
            res.data.status === 1 ? "Dividend Added Successfully" : res.message;

          dispatch(
            toastActions.setToastData({
              message: toastMessage,
              variant: res.data.status === 1 ? "success" : "error",
            })
          );
        }
        if (res.data.status === 1) {
          getDividendApi({
            page: paginationModel.page * paginationModel.pageSize,
            pageSize: paginationModel.pageSize,
          });
          setStockName("");
          setExDividendDate(null);
          setDividendPercentage("");
        }
      }
    } catch (error) {}
    // Reset states after API call
    setDrawerAddDividend(false);
    setIsEditing(false);
    setSelectedDividendId(null);
  };
  const AddDividendDrawer = () => {
    setDrawerAddDividend(true);
    setIsEditing(false);
    setSelectedDividendId(null);

    setStockName("");
    setExDividendDate(null);
    setDividendPercentage("");
  };

  // const familyDataWithIds = Object.values(dividendData).map((item, index) => ({
  //   ...item,
  //   id: index + 1,
  // }));

  const familyDataWithIds = dividendDataDetail
    ? Object.values(dividendDataDetail).map((item, index) => ({
        ...item,
        id: index + 1,
      }))
    : [];

  const handledividendperc = (e) => {
    const inputValue = e.target.value;
    const numberRegex = /^[0-9\b]+$/;
    const inputValueAsNumber = parseFloat(inputValue);

    if (
      inputValue === "" ||
      (numberRegex.test(inputValue) && inputValue === "") ||
      (inputValueAsNumber >= 0 && inputValueAsNumber <= 100)
    ) {
      setDividendPercentage(inputValue);
      setAddFormError({ dividendPerc: "" });
    } else {
      setAddFormError({
        dividendPerc: "Please enter numbers between 0 to 100",
      });
    }
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  // Filter options based on search input
  const filteredCompanyData = familyDataWithIds.filter((item) => {
    // Assuming you want to search by family name
    return item?.symbol?.toLowerCase().includes(searchValue.toLowerCase());
  });

  const dividendColumns = [
    {
      field: "symbol",
      headerName: "Stock name",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" alignItems="center">
          <Text sx={{ fontSize: "14px", fontWeight: 400, color: "#242424" }}>
            {params.value}
          </Text>
        </Stack>
      ),
    },
    {
      field: "date",
      headerName: "Ex dividend date",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" alignItems="center">
          {/* <Text sx={{fontSize:"14px",fontWeight:400,color:"#676C76"}}>{params.value}</Text> */}
          <Text sx={{ fontSize: "14px", fontWeight: 400, color: "#676C76" }}>
            {dayjs(params.value).format("DD-MM-YYYY")}
          </Text>
        </Stack>
      ),
      // dayjs(profileData?.joining_date).format("DD-MM-YYYY"),
    },
    {
      field: "percentage",
      headerName: "Dividend %",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" alignItems="center">
          <Text sx={{ fontSize: "14px", fontWeight: 400, color: "#676C76" }}>
            {params.value}%
          </Text>
        </Stack>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" alignItems="center">
          <IconButton onClick={() => handleEdit(params.row)}>
            <EditIcon sx={{ height: "20px", width: "20px" }} />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const TradeHeader = () => {
    return (
      <>
        <HeaderBox>
          <Box>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{ mb: "10px" }}
            >
              <DividendText color="primary" variant="button">
                Dividend
              </DividendText>

              <Box
                sx={{
                  borderRadius: "16px",
                  backgroundColor: "#E5EEFF",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100px",
                  height: "25px",
                  ml: 1,
                }}
              >
                <Text sx={{ fontSize: "14px" }}>
                  {dividendData?.dividendRecords} stocks
                </Text>
              </Box>
            </Stack>
            <Stack direction="row">
              <Text
                variant="small"
                sx={{ color: "#667085", fontWeight: 400, fontSize: "14px" }}
              >
                Here you can add dividend values with the date for the stock.
              </Text>
            </Stack>
          </Box>

          <AddDividendBtn startIcon={<AddIcon />} onClick={AddDividendDrawer}>
            Add dividend
          </AddDividendBtn>
        </HeaderBox>

        <Box style={{ width: "500px", marginBottom: "16px " }}>
          <Stack direction="row">
            <TextField
              sx={{ maxWidth: "500px", ml: 2 }}
              placeholder="Search stock name"
              value={searchValue}
              onChange={handleSearchChange}
            />
            <SearchIcon
              style={{
                position: "relative",
                left: "-42px",
                marginTop: "12px",
                color: "#676C76",
              }}
            />
          </Stack>
        </Box>
      </>
    );
  };

  return (
    <>
      <SideDrawer
        open={isDrawerAddDividend}
        closeDrawer={() => setDrawerAddDividend(false)}
        title={isEditing ? "Edit dividend" : "Add dividend"}
        subtitle="Here you can add dividend for any stock."
        cancelButtonText="Cancel"
        submitButtonText="Save"
        handleSubmit={handleSave}
      >
        <Box>
          <Box>
            <StockNameText>Stock name</StockNameText>
            <Autocomplete
              sx={{ width: "100%", mb: "20px" }}
              placeholder="Enter stock name"
              value={stockName}
              onChange={(e, value) => {
                setStockName(value);
              }}
              disablePortal
              id="combo-box-demo"
              options={companyData}
              getOptionLabel={(option) => option.symbol || ""} // This ensures that the `symbol` is shown
              renderOption={(props, option) => (
                <li {...props} key={option.symbol}>
                  {option.symbol}{" "}
                  {/* Customize how the options are displayed */}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  onChange={(event) => {
                    setFormDataSearch(event.target.value);
                  }}
                  value={formDataSearch}
                  {...params}
                  placeholder="Enter a Stock Name"
                />
              )}
            />{" "}
            {addFormError.stockName ? (
              <span style={{ color: "red" }}>{addFormError.stockName}</span>
            ) : null}
          </Box>
          <Box>
            <ExDividendText>Ex dividend date</ExDividendText>
            <DatePicker
              sx={{ width: "100%", mb: "20px" }}
              // defaultValue={exDividendDate}
              onChange={(date) => {
                setExDividendDate(date);
              }}
              value={exDividendDate}
            />
            {addFormError.dividendDate ? (
              <span style={{ color: "red" }}>{addFormError.dividendDate}</span>
            ) : null}
          </Box>
          <Box>
            <DividendpercText>Dividend %</DividendpercText>
            <TextField
              sx={{ width: "100%", mb: "20px" }}
              placeholder="Enter Dividend %"
              value={dividendPercentage}
              onChange={handledividendperc}
              // onChange={(e) => setDividendPercentage(e.target.value)}
            />
            {addFormError.dividendPerc ? (
              <span style={{ color: "red" }}>{addFormError.dividendPerc}</span>
            ) : null}
          </Box>
        </Box>
      </SideDrawer>
      <Box sx={{ marginTop: "25px" }}>
        <CustomGrid
          list={filteredCompanyData}
          columnHeaderHeight={46}
          rowHeight={72}
          columns={dividendColumns}
          header={TradeHeader}
          pagination
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={handlePaginationChange}
          rowCount={dividendData?.dividendRecords}
        />
      </Box>
    </>
  );
}
export default DividendContainer;
