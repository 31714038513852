import React, { useState, useEffect, useRef } from "react";
import Box from "components/common/Box";
import Grid from "components/common/Grid";
import Text from "components/common/Text";
import Stack from "components/common/Stack";
import Image from "components/common/Image";
import SideDrawer from "components/common/SideDrawer";
import Button from "components/common/Button";
import Card from "components/common/Card";
import CustomerDashboardHeader from "components/dashboard/CustomerDashboardHeader";
import CustomGrid from "components/datagrid/CustomGrid";
import CustomerTradeCard from "components/Customer/CustomerTradeCard";
import TradeCallStats from "components/dashboard/TradeCallStats";
import ModelPortfolioAllocation from "components/Customer/ModelPortfolioAllocation";
import Chip from "@mui/material/Chip";
import { Alert, Modal, styled } from "@mui/material";
import NewDialog from "components/common/Dialog";
import { useSelector } from "react-redux";
import { CustomerDashboardApiAction } from "stores/redux/customerDashBoard/customerDashBoardApiSlice";
import CustomerDashBoardAddFilter from "components/Customer/CustomerDashBoardAddFilter";
import { toastActions } from "stores/redux/slices/toastSlice";
import { useDispatch } from "react-redux";
import { uuid } from "helpers/utility";
import _get from "lodash/get";
import { style } from "@mui/system";
import { appConstants } from "helpers/constants/appConstants";
import { validationHelper } from "helpers/validation";
import Tooltip from "components/common/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import TextField from "components/common/TextField";
import { tradesApiAction } from "stores/redux/apiSlices/trades/tradesApiSlice";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "components/common/IconButton";

const USER_ROLES = appConstants.userRoles;

// Styled Components
const ChipStyle = styled(Chip)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  background: "#EAFFF3",
  borderRadius: "152px",
  color: "#219653",
  fontSize: "12px",
  fontWeight: 500,
  "&::before": {
    position: "relative",
    left: 6,
    bottom: 6,
    content: '"\\2022"',
    width: "6px",
    height: "6px",
    color: "#219653",
  },
}));

const BuyChip = styled(Chip)(() => ({
  backgroundColor: "#A1F0C2",
  color: "#219653",
  borderRadius: "152px",
  width: "61px",
  height: "24px",
}));

const SellChip = styled(Chip)(() => ({
  backgroundColor: "#FFEDED",
  color: "#EB5757",
  borderRadius: "152px",
  width: "61px",
  height: "24px",
}));

const ExecuteBox = styled(Button)(({ is_broker_missing }) => ({
  width: "10px",
  height: "26px",
  mr: "0px",
  backgroundColor: "#A1F0C2 !important",
  border: "1.5px solid #219653",
  borderRadius: "4.5px",
  cursor: "pointer",
  opacity: is_broker_missing ? 0.4 : 1,
}));

const DeleteTrade = styled(Button)(({ is_broker_missing }) => ({
  width: "10px",
  height: "26px",
  mr: "0px",
  backgroundColor: "#FCB9B9 !important",
  border: "1.5px solid #EB5757",
  borderRadius: "4.5px",
  cursor: "pointer",
  opacity: is_broker_missing ? 0.4 : 1,
}));

const AddFilterBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  border: "1px solid #D0D5DD",
  height: "20px",
  borderRadius: "8px",
  padding: "10px 16px",
}));
// 2 Graphs
const graphCardData = [
  {
    id: 1,
    name: "Algo (F&O) progress",
    chart: "/images/Graph2.svg",
    display: "column",
    xs: 6,
  },
  // {
  //   id: 2,
  //   name: "Custom Progress till date",
  //   chart: "/images/Graph2.svg",
  //   display: "flex",
  //   xs: 6,
  // },
];

const graphCardData2 = [
  {
    id: 1,
    name: "Custom Progress till date",
    chart: "/images/Graph2.svg",
    display: "column",
    xs: 6,
  },
];

// View Trade Drawer
const ViewTrade = ({
  companyName,
  actionTaken,
  quantity,
  buyPrice,
  invested,
  sellPrice,
}) => {
  return (
    <Card sx={{ p: "12px 16px" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Text
          sx={{
            color: "#242424",
            fontSize: "16px",
            fontWeight: 500,
            mr: "16px",
          }}
        >
          {companyName}
        </Text>
        <ChipStyle label={actionTaken} size="small" />
      </Box>

      <Box
        sx={{
          display: "flex",
          mt: 2,
          mb: "8px",
        }}
      >
        <Text sx={{ fontSize: "14px", mr: "auto" }}>
          Invesment amount : ₹ {invested}
        </Text>
        <Text sx={{ fontSize: "14px", mr: "auto" }}>
          Quantity : {sellPrice ? `₹ ${quantity}` : "NA"}
        </Text>
      </Box>

      <Box
        sx={{
          display: "flex",
          mb: "8px",
        }}
      >
        <Text sx={{ fontSize: "14px", mr: "auto" }}>
          Buy price : ₹ {buyPrice}
        </Text>
        <Text sx={{ fontSize: "14px", mr: "auto" }}>
          Sell price : {sellPrice ? `₹ ${sellPrice}` : "NA"}
        </Text>
      </Box>
    </Card>
  );
};

let intervalList;
// Below 2 array's are Rohit handling
const tradecallsstats = [
  {
    noofcalls: 20,
    actiontaken: 5,
  },
];

function CustomerDashboardContainer() {
  const [isDrawerEye, setIsDrawerEye] = useState(false);
  const [isDrawerAddFamily, setDrawerAddFamily] = useState(false);
  const [apiData, setApiData] = useState(null);
  const [headerData, setHeaderData] = useState();
  const [productID, setProductID] = useState(null);
  const [tradeID, setTradeID] = useState(null);
  const [transactionID, setTransactionID] = useState(null);
  const [viewData, setViewData] = useState(null);
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [editableQuantity, setEditableQuantity] = useState("");
  const [editablePrice, setEditablePrice] = useState("");
  const [editableStopLoss, setEditableStopLoss] = useState("");
  const [orderType, setOrderType] = useState("");
  const [productType, setProductType] = useState("");
  const [refetchStacksDataCounter, setRefetchStacksDataCounter] = useState(0);
  const [graphCustomData, setGraphCustomData] = useState(null); // Store custom graph data
  const [graphAlgoData, setGraphAlgoData] = useState(null);
  const [stateValue, setStateValue] = useState({
    formattedStartDate: null,
    formattedEndDate: null,
    dropDownData: {
      investmentType: [],
      familyMember: [],
    },
  });

  const [stacksLoading, setStacksLoading] = useState(false);

  const [tradesPaginationModel, setTradesPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });

  const [selectedFilters, setSelectedFilters] = useState({
    orderType: [],
    actionType: [],
    actionStatus: [],
    investmentType: [],
    familyMember: [],
  });

  const dispatch = useDispatch();
  const handlePaginationModelChange = (model) => {
    setTradesPaginationModel(model);
  };

  const intervalRef = useRef(null);

  const userRoleCode = useSelector((state) => state.commonReducer.userRoleCode);
  const userDetails = useSelector((state) => state.commonReducer.userDetails);
  const roleConfig = USER_ROLES[userRoleCode];
  const pendingCustomerApprv =
    userDetails.onboard_status == "PENDING" && roleConfig.code == "CUSTOMER";
  const [
    handleAddFilterApi,
    { data: tradeList = {}, isLoading: tradeListLoading },
  ] = CustomerDashboardApiAction.addFilterDashBoardApi({
    page: tradesPaginationModel.page + 1,
    take: tradesPaginationModel.pageSize,
  });
  const [updatePendingTrade] = tradesApiAction.updatePendingApi();
  const [updatePendingTradeUpdate] = tradesApiAction.updatePendingApiUpdate();

  const modifiedTableData = _get(tradeList, "result", []).map((row) => ({
    ...row,
    id: uuid(),
  }));
  const rowCount = _get(tradeList, "total_record", 0);

  const [executeCustomerTrade] =
    CustomerDashboardApiAction.executeCustomerTradeApi({
      product_id: productID,
      trade_id: tradeID,
      transaction_id: transactionID,
    });
  const [deleteCustomerTrade] =
    CustomerDashboardApiAction.deleteCustomerTradeApi({
      product_id: productID,
      transaction_id: transactionID,
    });
  //--------------------Graph Data-------------------------//
  const payload = {
    user_id: userDetails.user_id, // this username is coming from redux
  };
  const { data: graphData } =
    CustomerDashboardApiAction.getModelPortfolioGraph(payload);
  const { data: graphDataSummary } =
    CustomerDashboardApiAction.getModelPortfolioGraphSummary(payload);

  const handlestateValues = (data) => {
    setStateValue(data);
  };
  const handleApplyFilters = () => {
    setDrawerAddFamily(false);
  };
  const handleCellClick = (row) => {
    setViewData(row);
  };
  // Eye Drawer
  const EyeDrawer = () => {
    setIsDrawerEye(true);
  };
  // Add FIlter Drawer
  const AddFamilyDrawer = () => {
    setDrawerAddFamily(true);
  };
  const handleOpenModal = (rowData) => {
    setSelectedRow(rowData);
    setEditableQuantity(rowData.quantity); // Set the quantity to be editable
    setEditablePrice(rowData.price);
    setEditableStopLoss(rowData.stop_loss);
    setOrderType(rowData.order_type);
    setProductType(rowData.product_type);
    setOpen(true);
  };

  const handleOpenModalUpdate = (rowData) => {
    setSelectedRow(rowData);
    setEditableQuantity(rowData.quantity); // Set the quantity to be editable
    setEditablePrice(rowData.price);
    setEditableStopLoss(rowData.stop_loss);
    setOrderType(rowData.order_type);
    setProductType(rowData.product_type);
    setOpenUpdate(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  const handleCloseModalUpdate = () => {
    setOpenUpdate(false);
    setSelectedRow(null);
  };

  // const handleSave = async () => {
  //   if (selectedRow) {
  //     // Create the payload in the required format
  //     const payload = {
  //       tradeIds: [
  //         {
  //           user_id: selectedRow.user_id, // Assuming selectedRow has user_id
  //           trade_id: selectedRow.trade_id, // Assuming selectedRow has trade_id
  //           transaction_id: selectedRow.transaction_id, // Assuming selectedRow has transaction_id
  //           quantity: editableQuantity, // Updated quantity from the state
  //         },
  //       ],
  //     };

  //     let res = await updatePendingTrade({ payload });

  //     try {
  //       if (res.data.status == 1) {
  //         handleCloseModal();
  //         fetchDataGridApi();
  //       }
  //     } catch (error) {
  //       console.error("Error updating trade:", error);
  //     }
  //   }
  // };

  const handleFiltersChange = (type, value) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [type]: value,
    }));
  };

  const executeTrade = async (product_id, trade_id, transaction_id) => {
    try {
      const response = await executeCustomerTrade({
        product_id,
        trade_id,
        transaction_id,
      });
      fetchDataGridApi();
      setRefetchStacksDataCounter((prv) => prv + 1);
      // Dispatch success toast message
      dispatch(
        toastActions.setToastData({
          message: response?.data?.message,
          severity: "success",
        })
      );
    } catch (error) {
      console.error("Error executing trade:", error);

      // Dispatch error toast message
      dispatch(
        toastActions.setToastData({
          message: "Failed to execute trade. Please try again later.",
          severity: "error",
        })
      );
    }
  };

  const deleteTrade = async (product_id, transaction_id) => {
    setProductID(product_id);
    setTransactionID(transaction_id);
    try {
      const response = await deleteCustomerTrade({
        product_id,
        transaction_id,
      });
      setRefetchStacksDataCounter((prv) => prv + 1);
      fetchDataGridApi();

      // Dispatch success toast message
      dispatch(
        toastActions.setToastData({
          // message: response?.data?.message,
          message: "Trade Deleted Succesfully ",
          severity: "success",
        })
      );
    } catch (error) {
      console.error("Error deleting trade:", error);

      // Dispatch error toast message
      dispatch(
        toastActions.setToastData({
          message: "Failed to delete trade. Please try again later.",
          severity: "error",
        })
      );
    }
  };

  useEffect(() => {
    // Call the function immediately, and then every 5 seconds
    fetchDataGridApi();

    intervalRef.current = setInterval(fetchDataGridApi, 5000); // 5000ms = 5 seconds

    // Cleanup the interval when the component unmounts
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []); // Empty dependency array ensures this runs once on mount

  useEffect(() => {
    if (!pendingCustomerApprv) {
      fetchDataGridApi();
    }
  }, [selectedFilters, tradesPaginationModel]);

  useEffect(() => {
    // Note: Recall Trade listing API if any of them are inprogress.
    const listData = _get(tradeList, "result", []);
    if (listData && listData.length && listData.some((t) => t.status == 1)) {
      if (intervalList) {
        clearInterval(intervalList);
      }
      intervalList = setInterval(() => {
        setRefetchStacksDataCounter((prv) => prv + 1);
      }, 10000);
    } else {
      clearInterval(intervalList);
    }
  }, [tradeList]);

  useEffect(() => {
    return () => {
      if (intervalList) {
        clearInterval(intervalList);
      }
    };
  }, []);

  const fetchDataGridApi = async () => {
    const {
      orderType,
      actionType,
      actionStatus,
      investmentType,
      familyMember,
      formattedStartDate,
      formattedEndDate,
    } = selectedFilters;

    const payload = {
      member_ids:
        familyMember && familyMember.length
          ? familyMember
          : [userDetails?.user_id].filter(Boolean),
      product_id:
        investmentType && investmentType.length ? investmentType : [1, 2, 3],
      date_range_from: formattedStartDate?.length ? formattedStartDate : "",
      date_range_to: formattedEndDate?.length ? formattedEndDate : "",
      action: orderType,
      order_type: actionType,
      status: actionStatus,
      page: tradesPaginationModel.page + 1,
      take: tradesPaginationModel.pageSize,
      noGlobalLoading: true, // Ensure no global loader
    };

    try {
      await handleAddFilterApi(payload);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const cbWithFilteredData = async (filterParameter) => {
    const {
      investmentType,
      familyMember,
      formattedStartDate,
      formattedEndDate,
    } = filterParameter;
    setSelectedFilters({
      ...selectedFilters,
      investmentType,
      familyMember,
      formattedStartDate,
      formattedEndDate,
    });
  };

  // Table columns

  const dashboardCard = () => {
    let cardData = headerData?.card_data || [];
    return cardData.map((card) => {
      let finalData = {
        display_heading: _get(card, "display_heading", "Card"),
        sequence: _get(card, "sequence", ""),
        data_1: {
          sub_heading: _get(card, "data_1.sub_heading", null),
          value: _get(card, "data_1.value", 0),
          value_type: _get(card, "data_1.value_type", ""),
          suffix_val: _get(card, "data_1.suffix_val", null),
          suffix_val_type: _get(card, "data_1.suffix_val_type", ""),
        },
        data_2: {
          sub_heading: _get(card, "data_2.sub_heading", null),
          value: _get(card, "data_2.value", null),
          value_type: _get(card, "data_2.value_type", ""),
          suffix_val: _get(card, "data_2.suffix_val", null),
          suffix_val_type: _get(card, "data_2.suffix_val_type", ""),
        },
      };

      return finalData;
    });
  };

  const customerColumns = [
    {
      field: "symbol",
      headerName: "Stock name",
      flex: 1,
      renderCell: (params) => (
        <div style={{ color: "#242424", fontSize: "14px", fontWeight: 500 }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      renderCell: (params) => (
        <div style={{ color: "#676C76", fontSize: "14px", fontWeight: 400 }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 1,
      renderCell: (params) => (
        <div style={{ color: "#676C76", fontSize: "14px", fontWeight: 400 }}>
          {params.value}
          <Box component="span" sx={{ ml: "2px", fontSize: "12px" }}>
            {params?.row?.product_id == 3 ? " (LOT)" : ""}
          </Box>
        </div>
      ),
    },
    {
      field: "order_type",
      headerName: "Order type",
      flex: 1,
      renderCell: (params) => (
        <div style={{ color: "#676C76", fontSize: "14px", fontWeight: 400 }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "action",
      headerName: "Action type",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" alignItems="center">
          {params.value === "BUY" && <BuyChip label="Buy" />}
          {params.value === "SELL" && <SellChip label="Sell" />}
        </Stack>
      ),
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
      renderCell: (params) => (
        <div style={{ color: "#676C76", fontSize: "14px", fontWeight: 400 }}>
          {params.value ? `₹ ${params.value}` : "-"}
        </div>
      ),
    },
    {
      field: "stop_loss",
      headerName: "Stop loss",
      flex: 1,
      renderCell: (params) => (
        <div style={{ color: "#676C76", fontSize: "14px", fontWeight: 400 }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "target",
      headerName: "Target (Sell)",
      flex: 1,
      renderCell: (params) => (
        <div style={{ color: "#676C76", fontSize: "14px", fontWeight: 400 }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "expired_trade_date",
      headerName: "Time",
      flex: 1,
      renderCell: (params) => (
        <div
          style={{
            color: "#676C76",
            fontSize: "14px",
            fontWeight: 400,
            cursor: "pointer",
          }}
          title={params.value}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "trade_is_expired",
      headerName: "Trade Status ",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" alignItems="center">
          {params.value === 1 && (
            <SellChip sx={{ width: "79px" }} label="Expired" />
          )}
          {params.value === 0 && (
            <BuyChip sx={{ width: "79px" }} label="Active" />
          )}
        </Stack>
      ),
    },

    {
      field: "message",
      headerName: "Message",
      flex: 1,
      renderCell: (params) => (
        <div
          style={{
            color: "#676C76",
            fontSize: "14px",
            fontWeight: 400,
            cursor: "pointer",
          }}
          title={params.value}
        >
          {!params.value ? "-" : params.value}
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        const { product_id, trade_id, transaction_id, tradeStatus } =
          params.row;

        let statusChip = {};
        switch (params.value) {
          case 1:
            statusChip.chipColor = "#FF5722";
            statusChip.chipBg = "#ffc3b0";
            break;
          case 2:
            statusChip.chipColor = "#196d3d";
            statusChip.chipBg = "#a1f0c2";
            break;
          case 3:
            statusChip.chipColor = "#eb5757";
            statusChip.chipBg = "#fcb9b9";
            break;
          case 4:
            statusChip.chipColor = "#d2d2d2";
            statusChip.chipBg = "gray";
            break;
          case 5:
            statusChip.chipColor = "#d2d2d2";
            statusChip.chipBg = "gray";
            break;
          case 7:
            statusChip.chipColor = "#d2d2d2";
            statusChip.chipBg = "gray";
            break;
          default:
            statusChip = null;
            break;
        }

        if (params.value === 0) {
          // Render ExecuteBox and DeleteTrade
          return (
            <Stack direction="row" alignItems="center">
              <IconButton
                onClick={() =>
                  executeTrade(product_id, trade_id, transaction_id)
                }
                disabled={userDetails.is_broker_missing}
                is_broker_missing={userDetails.is_broker_missing}
              >
                <CheckIcon />
              </IconButton>

              <IconButton
                onClick={() => deleteTrade(product_id, transaction_id)}
                disabled={userDetails.is_broker_missing}
                is_broker_missing={userDetails.is_broker_missing}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
          );
        } else if (statusChip) {
          return (
            <span
              style={{
                color: statusChip.chipColor,
                background: statusChip.chipBg,
                fontSize: "11px",
                padding: "5px 10px",
                borderRadius: "50px",
              }}
            >
              {tradeStatus}
            </span>
          );
        } else {
          return <></>;
        }
      },
    },

    // Show EditIcon only when ExecuteBox exists
    {
      field: "Edit",
      headerName: "Edit",
      flex: 1,
      renderCell: (params) => {
        const { order_type, tradeStatus } = params.row;
        if (tradeStatus === "PENDING") {
          return (
            <IconButton>
              <EditIcon
                variant="contained"
                onClick={() => handleOpenModal(params.row)}
              >
                Modify
              </EditIcon>
            </IconButton>
          );
        } else if (tradeStatus === "INPROGRESS") {
          return (
            <IconButton>
              <EditIcon
                variant="contained"
                onClick={() => handleOpenModalUpdate(params.row)}
                disabled={
                  !(
                    (order_type === "LIMIT" && tradeStatus === "PENDING") ||
                    tradeStatus === "INPROGRESS"
                  )
                }
              >
                Modify
              </EditIcon>
            </IconButton>
          );
        }
      },
    },
  ];

  const data = {
    name: "Algo (F&O) progress",
  };
  const data2 = {
    name: "Custom Progress till date",
  };

  const [customGraphField, setCustomGraphField] = useState({
    amountInvested: null,
    profitMade: null,
  });

  const [algoGraphField, setAlgoGraphField] = useState({
    amountInvested: null,
    profitMade: null,
  });

  // Function to update graph fields based on data
  const updateGraphFields = (data, setField) => {
    if (data && data.length > 0) {
      return {
        amountInvested: data[4]?.data_1?.value || null,
        profitMade: data[13]?.data_1?.value || null,
      };
    }
    return null;
  };

  useEffect(() => {
    // Update both custom and algo graph fields
    const updatedCustomField = updateGraphFields(
      graphCustomData,
      setCustomGraphField
    );
    if (updatedCustomField) {
      setCustomGraphField(updatedCustomField);
    }
    const updatedAlgoField = updateGraphFields(
      graphAlgoData,
      setAlgoGraphField
    );
    if (updatedAlgoField) {
      setAlgoGraphField(updatedAlgoField);
    }
  }, [graphCustomData, graphAlgoData]);

  // CustomGrid Header
  const TradeHeader = () => {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            padding: "24px",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{}}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{ mb: "10px" }}
            >
              <Text
                sx={{ color: "primary", mr: 1, fontWeight: 500 }}
                color="primary"
                variant="button"
              >
                Active Trades{" "}
              </Text>
              <Chip
                color="secondary"
                label={`${rowCount} trade calls`}
                size="small"
              ></Chip>
            </Stack>
            <Stack direction="row">
              {/* <Box
                component="img"
                sx={{ width: "20px", height: "20px", mr: "6px" }}
                alt="Exclamation"
                src="/images/redclockIcon.svg"
              ></Box> */}
              {/* <Text
                variant="small"
                sx={{ color: "#EB5757", fontWeight: 600, fontSize: "14px" }}
              >
                4:30 min left to take the trade calls
              </Text> */}
            </Stack>
          </Box>
          <AddFilterBox onClick={AddFamilyDrawer}>
            <Box
              component="img"
              sx={{ width: "20px", height: "20px", mr: "6px" }}
              alt="Add Filter Icon"
              src="/images/addFilterIcon.svg"
            ></Box>
            <Text sx={{ color: "#242424", fontSize: "14px", fontWeight: 600 }}>
              Add Filters
            </Text>
          </AddFilterBox>
        </Box>
      </>
    );
  };

  const handleSave = async () => {
    if (selectedRow) {
      // Create the payload in the required format
      const payload = {
        tradeIds: [
          {
            customer_id: selectedRow.user_id,
            trade_id: selectedRow.trade_id,
            transaction_id: selectedRow.transaction_id,
            quantity: editableQuantity, // Updated quantity from the state
            product_type: productType,
            order_type: orderType,
            price: editablePrice,
            stop_loss: editableStopLoss,
          },
        ],
      };

      try {
        let res = await updatePendingTrade({ payload });

        if (res.data && res.data.status == 1) {
          handleCloseModal();

          // if (res.data.result) {
          //   setTableData(res.data.result);
          // }
          fetchDataGridApi();
        }
      } catch (error) {
        console.error("Error updating trade:", error);
      }
    }
  };

  const handleSaveUpdate = async () => {
    if (selectedRow) {
      // Create the payload in the required format
      const payload = {
        tradeIds: [
          {
            customer_id: selectedRow.user_id, // Assuming selectedRow has user_id
            trade_id: selectedRow.trade_id, // Assuming selectedRow has trade_id
            transaction_id: selectedRow.transaction_id, // Assuming selectedRow has transaction_id
            quantity: editableQuantity, // Updated quantity from the state
            product_type: productType,
            order_type: orderType,
            price: editablePrice,
            stop_loss: editableStopLoss,
          },
        ],
      };

      let res = await updatePendingTradeUpdate({ payload });

      try {
        if (res.data && res.data.status === 1) {
          // Close the modal
          handleCloseModalUpdate();
          fetchDataGridApi();

          // Update the table data if needed
          // This assumes that `tradeTableData.result` is updated after the API call
          // if (res.data.result) {
          //   setTableData(res.data.result); // This updates the CustomDataGrid's list prop
          // }
        }
      } catch (error) {
        console.error("Error updating trade:", error);
      }
    }
  };

  return (
    <>
      <CustomerDashboardHeader
        apiData={setApiData}
        userName={userDetails}
        onDataUpdate={handlestateValues}
        apiresponse={setHeaderData}
        cbWithFilteredData={cbWithFilteredData}
        refetchStacksDataCounter={refetchStacksDataCounter}
        pendingCustomerApprv={pendingCustomerApprv}
        setStacksLoading={setStacksLoading}
        setGraphCustomData={setGraphCustomData} // Setter for custom graph data
        setGraphAlgoData={setGraphAlgoData}
      />

      {/* Stacks */}
      <CustomerTradeCard
        data={dashboardCard()}
        cardsPerRow={headerData?.cards_per_row}
        stacksLoading={stacksLoading}
      />

      {/* Table: Trade listing */}
      <Box sx={{ mb: "24px" }}>
        <CustomGrid
          autoHeight
          list={modifiedTableData}
          getRowId={(row) => row.id}
          loading={tradeListLoading}
          columnHeaderHeight={46}
          rowHeight={46}
          columns={customerColumns}
          rowCount={rowCount}
          header={TradeHeader}
          onCellClick={handleCellClick}
          pagination
          paginationMode="server"
          paginationModel={tradesPaginationModel}
          onPaginationModelChange={handlePaginationModelChange}
        />
      </Box>

      <NewDialog open={open} onClose={handleCloseModal}>
        <Box
        // sx={{
        //   p: 4,
        //   backgroundColor: "white",
        //   width: "400px",
        //   margin: "auto",
        //   marginTop: "100px",
        // }}
        >
          <h2> Edit Trades</h2>
          {selectedRow && (
            <div>
              {/* Display static fields */}
              <p style={{ marginTop: "12px", marginBottom: "20px" }}></p>
              <TextField
                select
                label="Order Type"
                value={orderType}
                onChange={(e) => setOrderType(e.target.value)} // Update orderType on change
                fullWidth
                sx={{ marginBottom: 2 }}
                SelectProps={{
                  native: true, // Using native select for simplicity
                }}
              >
                <option value="MARKET">MARKET</option>
                <option value="LIMIT">LIMIT</option>
              </TextField>

              <TextField
                label="Price"
                variant="outlined"
                value={editablePrice}
                onChange={(e) => {
                  const value = e.target.value;
                  // Allow numbers with optional decimal point
                  if (/^\d*\.?\d*$/.test(value)) {
                    setEditablePrice(value);
                  }
                }}
                fullWidth
                sx={{ marginBottom: 2 }}
                disabled={orderType !== "LIMIT"}
              />

              <TextField
                label="Stop Loss"
                variant="outlined"
                value={editableStopLoss}
                onChange={(e) => {
                  const value = e.target.value;
                  // Only allow numbers
                  if (/^\d*\.?\d*$/.test(value)) {
                    setEditableStopLoss(value);
                  }
                }}
                fullWidth
                sx={{ marginBottom: 2 }}
              />

              <TextField
                select
                label="Product Type"
                value={productType}
                onChange={(e) => setProductType(e.target.value)} // Update orderType on change
                fullWidth
                sx={{ marginBottom: 2 }}
                SelectProps={{
                  native: true, // Using native select for simplicity
                }}
              >
                <option value="CNC">CNC</option>
                <option value="MIS">MIS/Margin</option> {/* Updated value */}
                <option value="NRML">NORMAL</option>
              </TextField>

              {/* Editable quantity */}
              <TextField
                label="Quantity"
                variant="outlined"
                value={editableQuantity}
                onChange={(e) => {
                  const value = e.target.value;
                  // Only allow numbers
                  if (/^\d*$/.test(value)) {
                    setEditableQuantity(value);
                  }
                }}
                fullWidth
                sx={{ marginBottom: 2 }}
              />

              {/* Add more fields as necessary */}
            </div>
          )}
          <Button variant="contained" onClick={handleCloseModal} sx={{ mr: 2 }}>
            Close
          </Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Box>
      </NewDialog>
      <NewDialog open={openUpdate} onClose={handleCloseModalUpdate}>
        <Box
        // sx={{
        //   p: 4,
        //   backgroundColor: "white",
        //   width: "400px",
        //   margin: "auto",
        //   marginTop: "100px",
        // }}
        >
          <h2>Edit Trades</h2>
          {selectedRow && (
            <div>
              {/* Display static fields */}
              <p style={{ marginTop: "12px", marginBottom: "20px" }}></p>

              <TextField
                select
                label="Order Type"
                value={orderType}
                onChange={(e) => setOrderType(e.target.value)} // Update orderType on change
                fullWidth
                sx={{ marginBottom: 2 }}
                SelectProps={{
                  native: true, // Using native select for simplicity
                }}
              >
                <option value="MARKET">MARKET</option>
                <option value="LIMIT">LIMIT</option>
              </TextField>

              <TextField
                label="Price"
                variant="outlined"
                value={editablePrice}
                onChange={(e) => {
                  const value = e.target.value;
                  // Allow numbers with optional decimal point
                  if (/^\d*\.?\d*$/.test(value)) {
                    setEditablePrice(value);
                  }
                }}
                fullWidth
                sx={{ marginBottom: 2 }}
                disabled={orderType !== "LIMIT"}
              />

              <TextField
                label="Stop Loss"
                variant="outlined"
                value={editableStopLoss}
                onChange={(e) => {
                  const value = e.target.value;
                  // Only allow numbers
                  if (/^\d*\.?\d*$/.test(value)) {
                    setEditableStopLoss(value);
                  }
                }}
                fullWidth
                sx={{ marginBottom: 2 }}
              />

              <TextField
                select
                label="Product Type"
                value={productType}
                onChange={(e) => setProductType(e.target.value)} // Update orderType on change
                fullWidth
                sx={{ marginBottom: 2 }}
                SelectProps={{
                  native: true, // Using native select for simplicity
                }}
              >
                <option value="CNC">CNC</option>
                <option value="MIS">MIS/Margin</option> {/* Updated value */}
                <option value="NRML">NORMAL</option>
              </TextField>

              {/* Editable quantity */}
              <TextField
                label="Quantity"
                variant="outlined"
                value={editableQuantity}
                onChange={(e) => {
                  const value = e.target.value;
                  // Only allow numbers
                  if (/^\d*$/.test(value)) {
                    setEditableQuantity(value);
                  }
                }}
                fullWidth
                sx={{ marginBottom: 2 }}
              />

              {/* Add more fields as necessary */}
            </div>
          )}
          <Button
            variant="contained"
            onClick={handleCloseModalUpdate}
            sx={{ mr: 2 }}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveUpdate}
          >
            Save Update
          </Button>
        </Box>
      </NewDialog>

      <Grid container spacing={2} sx={{ mb: 3 }}>
        {/* First Grid for TradeCallStats */}
        <Grid item xs={6} sx={{ p: 1 }}>
          {" "}
          {/* Add padding for some space inside the card */}
          <TradeCallStats data={data} investmentChart={graphAlgoData} />
        </Grid>

        {/* Second Grid for TradeCallStats */}
        <Grid item xs={6} sx={{ p: 1 }}>
          {" "}
          {/* Add padding for some space inside the card */}
          <TradeCallStats data={data2} investmentChart={graphCustomData} />
        </Grid>
      </Grid>
      <ModelPortfolioAllocation
        apiResponse={graphData}
        summaryData={graphDataSummary}
      />

      <SideDrawer
        open={isDrawerAddFamily}
        closeDrawer={() => setDrawerAddFamily(false)}
        title="Add Filters"
        subtitle="See the data in an organized manner by applying filters"
        cancelButtonText="Clear all"
        submitButtonText="Apply"
        handleSubmit={handleApplyFilters}
      >
        <CustomerDashBoardAddFilter onFiltersChange={handleFiltersChange} />
      </SideDrawer>

      <SideDrawer
        open={isDrawerEye}
        closeDrawer={() => setIsDrawerEye(false)}
        title="View trade"
        subtitle="Here you can view the specific trade."
        showSecondaryButton={false}
        submitButtonText="Okay"
      >
        <ViewTrade
          companyName="Infosys Limited"
          actionTaken="Action Taken"
          quantity={10}
          buyPrice={500}
          invested={10000}
          sellPrice={null}
          date="Jan 24, 2023"
          buyTime="02:42 PM"
          sellTime="03:42 PM"
        />
      </SideDrawer>
    </>
  );
}

export default CustomerDashboardContainer;
