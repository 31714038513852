import Box from "components/common/Box";
import React, { useEffect, useState, useCallback, useRef } from "react";
import Button from "components/common/Button";
import ImportIcon from "asset/icons/ImportIcon";
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import NewDialog from "components/common/Dialog";
import SendEmail from "../components/customerSidebar/SendEmail";
import AddFilter from "../components/customerSidebar/AddFilter";
import FileUpload from "components/common/DragNdDrop";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "components/common/TextField";
import CustomGrid from "components/datagrid/CustomGrid";
import ViewCustomer from "../components/customerSidebar/ViewCustomer";
import { useNavigate } from "react-router";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/system";
import Paper from "components/common/Paper";
import { customersApiAction } from "stores/redux/apiSlices/customers/customersApiSlice";
import CheckIcon from "@mui/icons-material/Check";
import infoIcon from "asset/icons/infoIcon.svg";
import PlayForWorkIcon from "@mui/icons-material/PlayForWork";
import Right_CLick from "asset/icons/Right_Click.svg";
import Popover from "@mui/material/Popover";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import Avatar from "components/common/Avatar";
import { useDispatch } from "react-redux";
import { toastActions } from "stores/redux/slices/toastSlice";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import useDebounce from "hooks/Debounce";
import {
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import Tooltip from 'components/common/Tooltip';

const StyledDataGrid = styled(CustomGrid)(({ theme }) => ({
  "& .super-app-theme--approve": {
    backgroundColor: "lightgreen",
  },
  "& .super-app-theme--not-approve": {
    backgroundColor: "lightcoral",
  },
}));

const ChipStyle = styled(Chip)(({ theme, riskprofile }) => {
  let backgroundColor;
  let color;
  if (riskprofile === "Aggressive") {
    backgroundColor = "#FFEDED";
    color = "#EB5757";
  } else if (riskprofile === "Moderate") {
    backgroundColor = "#FFF5E0";
    color = "#E58026";
  } else if (riskprofile === "Conservative") {
    backgroundColor = "#DEFFEC";
    color = "#219653";
  }

  return {
    display: "flex",
    alignItems: "center",
    borderRadius: "152px",
    backgroundColor: backgroundColor,
    color: color,
    fontSize: "12px",
    padding: "8px",
    fontWeight: 500,
    "&::before": {
      position: "relative",
      left: 5,
      bottom: 6,
      content: '"\\2022"',
      width: "6px",
      height: "6px",
      color: color,
    },
  };
});

const ActiveChipStyle = styled(Chip)(({ theme, auto_trade }) => {
  let backgroundColor;
  let color;
  if (auto_trade === "ACTIVE") {
    backgroundColor = "#DEFFEC";
    color = "#219653";
  } else if (auto_trade === "INACTIVE") {
    backgroundColor = "#FFEDED";
    color = "#EB5757";
  }

  return {
    display: "flex",
    alignItems: "center",
    borderRadius: "152px",
    backgroundColor: backgroundColor,
    color: color,
    fontSize: "12px",
    padding: "8px",
    fontWeight: 500,
    "&::before": {
      position: "relative",
      left: 5,
      bottom: 6,
      content: '"\\2022"',
      width: "6px",
      height: "6px",
      color: color,
    },
  };
});

const tableHeaderData = [
  {
    field: "investment_type",
    headerName: "Type",
    flex: 1,
    renderCell: (params) => (
      <>
        <Stack direction="column">
          <Text
            color="#6F6F6F"
            fontWeight="500"
            fontSize="16px"
            alignItems="textCenter"
            style={{ cursor: "pointer" }}
          >
            {params?.row?.investment_type}
          </Text>
        </Stack>
      </>
    ),
  },
  {
    field: "name",
    headerName: "Customer Name",
    flex: 1,
    renderCell: (params) => (
      <Stack direction="row" textAlign="center" sx={{flexFlow: "column"}}>
        {["ACCEPTED","REJECTED"].includes(params?.row?.onboard_status) ? (
          <Text
            color="#242424"
            fontSize="14px"
            fontWeight="500"
            style={{ cursor: "pointer" }}
          >
            {" "}
            {params?.row?.name}
          </Text>
        ) : (
          <Text
            color="#242424"
            fontSize="14px"
            fontWeight="500"
            style={{ cursor: "pointer", marginBottom: "2px", position:"relative" }}
          >
            {params?.row?.name}
            <FiberManualRecordIcon
                style={{
                  width: "10px",
                  height: "10px",
                  color: "#D21818",
                  marginBottom: "8px",
                }}
              />
            {/* {"*"} */}
          </Text>
        )}

        {["REJECTED"].includes(params?.row?.onboard_status) ?
          (<>
            <Tooltip
              title={params?.row?.onboard_status_reason}
              placement="top"
              sx={{
                width: "10px",
                height: "10px",
                top: "-1px",
                right: "-8px"
              }}
              >
                <Text 
                  sx={{
                    fontSize: "10px",
                    color: "red",
                    textAlign: "left",
                    marginTop: "-3px",
                    cursor: "pointer"
                  }}>Rejected
                </Text>
            </Tooltip>
          </>)
        :null}
      </Stack>
    ),
  },
  {
    field: "contactDetail",
    headerName: "Contact Detail",
    flex: 1,
    renderCell: (params) => (
      <>
        <Stack direction="column">
          <Text
            color="#676C76"
            fontWeight="500"
            fontSize="14px"
            alignItems="textCenter"
            style={{ cursor: "pointer" }}
          >
            {params?.row?.email}
          </Text>
          <Text
            color="#676C76"
            fontWeight="500"
            fontSize="14px"
            alignItems="textCenter"
            marginTop="2px"
            style={{ cursor: "pointer" }}
          >
            {params?.row?.mobile}
          </Text>
        </Stack>
      </>
    ),
  },
  {
    field: "capital",
    headerName: "Capital",
    flex: 1,
    renderCell: (params) => (
      <>
        <Stack direction="column">
          <Text
            color="#676C76"
            fontWeight="400"
            fontSize="14px"
            alignItems="center"
            style={{ cursor: "pointer" }}
          >
            ₹ {params?.row?.capital}
          </Text>
        </Stack>
      </>
    ),
  },
  {
    field: "leverage",
    headerName: "Leverage",
    flex: 1,
    renderCell: (params) => (
      <>
        <Stack direction="column">
          <Text
            color="#676C76"
            fontWeight="400"
            fontSize="14px"
            alignItems="textCenter"
            style={{ cursor: "pointer" }}
          >
            {params?.row?.leverage}
          </Text>
        </Stack>
      </>
    ),
  },
  {
    field: "current_value",
    headerName: "Current value",
    flex: 1,
    renderCell: (params) => (
      <>
        <Stack direction="column">
          <Text
            color="#676C76"
            fontWeight="400"
            fontSize="14px"
            alignItems="textCenter"
            style={{ cursor: "pointer" }}
          >
            {params?.row?.current_value}
          </Text>
        </Stack>
      </>
    ),
  },
  {
    field: "riskFilter_selected",
    headerName: "Selected Risk Profile",
    flex: 1,
    renderCell: (params) => (
      <Stack direction="row" textAlign="center">
        {params?.row?.riskFilter_selected ? (
          <ChipStyle
            style={{ cursor: "pointer" }}
            label={params?.row?.riskFilter_selected}
            size="small"
            riskprofile={params?.row?.riskFilter_selected}
          />
        ) : (
          ""
        )}
      </Stack>
    ),
  },
  {
    field: "risk_profile",
    headerName: "Assessed Risk Profile",
    flex: 1,
    renderCell: (params) => (
      <Stack direction="row" textAlign="center">
        {params?.row?.risk_profile ? (
          <ChipStyle
            style={{ cursor: "pointer" }}
            label={params?.row?.risk_profile}
            size="small"
            riskprofile={params?.row?.risk_profile}
          />
        ) : (
          ""
        )}
      </Stack>
    ),
  },
  {
    field: "auto_trade",
    headerName: "Auto Trade Status",
    flex: 1,
    renderCell: (params) => (
      <Stack direction="row" textAlign="center">
        <ActiveChipStyle
          style={{ cursor: "pointer" }}
          label={params?.row.auto_trade}
          size="small"
          auto_trade={params?.row?.auto_trade}
        />
      </Stack>
    ),
  },
];

const CustomerContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // ------------APi call
  const [getCustomerListApi, { isLoading }] =
    customersApiAction.getCustomerListApi();
  const [getCustomerSearchingData] =
    customersApiAction.getCustomerSearchingData();
  const [postImportFileData] = customersApiAction.postImportFile();
  const [postAttachImportFileData] = customersApiAction.postAttachImportFile();

  // ----------- //
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [congratsModalOpen, setCongratsModalOpen] = useState(false);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [customerId, setCustomerId] = useState(0);
  const [viewData, setViewData] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [customersData, setCustomersData] = useState([]);
  const [totalCount, setTotalCount] = useState("");
  const [importListData, setImportListData] = useState([]);
  const [attachedFile, setAttachedFile] = useState(null);
  const [postData, setPostData] = useState({
    search: "",
    capital_from: "",
    capital_to: "",
    investment_type: "",
    plan_status: "",
    risk_profile: "",
    riskFilter_selected: "",
    customer_category: "",
  });

  // -------------------Import file State
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [importListDataID, setImportListDataID] = useState([]);
  const [anchorEls, setAnchorEls] = useState({});
  const importTableHeader = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" textAlign="center">
          <Text
            color="#242424"
            fontSize="14px"
            fontWeight="500"
            style={{ cursor: "pointer" }}
          >
            {" "}
            {params?.row?.name}
          </Text>
        </Stack>
      ),
      editable: true,
    },
    {
      field: "email",
      headerName: "Email Address",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.email}
            </Text>
          </Stack>
        </>
      ),
      editable: true,
    },
    {
      field: "mobile",
      headerName: "Mobile",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
              marginTop="2px"
            >
              {params?.row?.mobile}
            </Text>
          </Stack>
        </>
      ),
      editable: true,
    },
    {
      field: "investmenttype",
      headerName: "Investment Type",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#6F6F6F"
              fontWeight="500"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.investmenttype}
            </Text>
          </Stack>
        </>
      ),
      editable: true,
    },
    {
      field: "plancode",
      headerName: "Plan Code",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#6F6F6F"
              fontWeight="500"
              fontSize="12px"
              alignItems="textCenter"
            >
              {params?.row?.plancode}
            </Text>
          </Stack>
        </>
      ),
      editable: true,
    },
    {
      field: "capital",
      headerName: "Capital",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#6F6F6F"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.capital}
            </Text>
          </Stack>
        </>
      ),
      editable: true,
    },
    {
      field: "riskprofile",
      headerName: "Risk Profile",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" textAlign="center">
          <ChipStyle
            label={params?.row?.riskprofile}
            size="small"
            riskprofile={params?.row?.riskprofile}
          />
        </Stack>
      ),
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      renderCell: (params) => {
        const isInEditMode =
          rowModesModel[params.id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<CheckIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleImportSave(params.id)}
              color="inherit"
            />,
          ];
        }

        return [
          <>
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(params.id)}
              color="inherit"
            />
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(params.id)}
              color="inherit"
            />
            {params?.row?.isvalid ? (
              ""
            ) : (
              <Stack direction="row" marginLeft="2px">
                <Avatar
                  style={{ width: "20px", height: "20px" }}
                  src={infoIcon}
                  aria-describedby={params?.id}
                  variant="contained"
                  onClick={(event) => handleClick(event, params?.id)}
                />
                <Popover
                  id={params.id}
                  open={isPopoverOpen(params?.id)}
                  anchorEl={anchorEls[params?.id]}
                  onClose={() => handleClose(params?.id)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <Text sx={{ p: 2 }}>{params?.row?.message}</Text>
                </Popover>
              </Stack>
            )}
          </>,
        ];
      },
    },
  ];

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });

  const searchingToServer = async () => {
    try {
      const result = await getCustomerSearchingData(postData);
      setCustomersData(result?.data?.result?.customers);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const handleChange = (props) => {
    if (props.files.length > 0) {
      setAttachedFile(props.files[0]);
    } else {
      setAttachedFile(null);
    }
  };

  const handleFileUnselect = () => {
    setAttachedFile(null);
  };

  const handleCongrats = () => {
    getCustomers();
    setSaveModalOpen(false);
    setCongratsModalOpen(false);
    setImportModalOpen(false);
  };

  const handleCancel = () => {
    setSaveModalOpen(false);
    setCongratsModalOpen(false);
    setImportModalOpen(false);
  };

  const handleAttachCancel = () => {
    getCustomers();
    setSaveModalOpen(false);
    setCongratsModalOpen(false);
    setImportModalOpen(false);
  };

  // const getCustomers = async () => {
  //   const payload = {
  //     ...postData,
  //     skip: paginationModel.page * paginationModel.pageSize, //pageno,
  //     take: paginationModel.pageSize, //pageSize,
  //   };
  //   let result = await getCustomerListApi(payload).unwrap();
  //   setCustomersData(result?.result?.customers);
  //   setTotalCount(result?.result?.customer_count);
  // };

  const getCustomers = async () => {
    const {
      investment_type,
      plan_status,
      risk_profile,
      riskFilter_selected,
      customer_category,
    } = postData;
    try {
      const payload = {
        ...postData,
        investment_type: Array.isArray(investment_type)
          ? investment_type.join()
          : postData?.investment_type ?? "",
        plan_status: Array.isArray(plan_status)
          ? plan_status.join()
          : postData?.plan_status ?? "",
        risk_profile: Array.isArray(risk_profile)
          ? risk_profile.join()
          : postData?.risk_profile ?? "",
        riskFilter_selected: Array.isArray(riskFilter_selected)
          ? riskFilter_selected.join()
          : postData?.riskFilter_selected ?? "",
        customer_category: Array.isArray(customer_category)
          ? customer_category.map((category) => `'${category}'`).join(",")
          : postData?.customer_category ?? "",
        skip: paginationModel.page * paginationModel.pageSize,
        take: paginationModel.pageSize,
        noGlobalLoading: true,
      };

      let result = await getCustomerListApi(payload).unwrap();

      if (result && result?.result) {
        setCustomersData(result?.result?.customers || []);
        setTotalCount(result?.result?.customer_count || 0);
      } else {
        setCustomersData([]);
        setTotalCount(0);
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getClearFilter = async () => {
    try {
      const payload = {
        ...postData,
        search: "",
        capital_from: "",
        capital_to: "",
        investment_type: "",
        plan_status: "",
        risk_profile: "",
        riskFilter_selected: "",
        customer_category: "",
        skip: paginationModel.page * paginationModel.pageSize,
        take: paginationModel.pageSize,
      };

      let result = await getCustomerListApi(payload).unwrap();
      if (result && result?.result) {
        setCustomersData(result?.result?.customers || []);
        setTotalCount(result?.result?.customer_count || 0);
      } else {
        setCustomersData([]);
        setTotalCount(0);
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const debouncedValue = useDebounce(postData.search, 500);

  const handleFilter = () => {
    getCustomers();
  };

  useEffect(() => {
    // if (!initialized.current) {
    //   initialized.current = true;
    getCustomers();
    // }
  }, [paginationModel.page, paginationModel.pageSize]);

  // --------------------Import file function handler
  const handleClick = (event, id) => {
    setAnchorEls((prevState) => ({
      ...prevState,
      [id]: event.currentTarget,
    }));
  };

  const handleClose = (id) => {
    setAnchorEls((prevState) => ({
      ...prevState,
      [id]: null,
    }));
  };

  const isPopoverOpen = (id) => Boolean(anchorEls[id]);

  const open = Boolean(anchorEl);

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleImportSave = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setImportListDataID(importListDataID.filter((row) => row.id !== id));
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setImportListDataID(
      importListDataID.map((row) => (row.id === newRow.id ? updatedRow : row))
    );
    return updatedRow;
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  useEffect(() => {
    const newArrayWithUUIDs = importListData.map((item, id) => ({
      ...item,
      id,
    }));
    setImportListDataID(newArrayWithUUIDs);
  }, [importListData]);

  const saveDataToServer = async () => {
    try {
      const result = await postAttachImportFileData(importListDataID);
      if (result?.data?.status === 1) {
        const validCountStore = result?.data?.result?.validcount;
        if (validCountStore > 0) {
          setImportListData([]);
          setCongratsModalOpen(true);
          setSaveModalOpen(false);
        } else {
          setSaveModalOpen(false);
        }
      } else {
        const arrayData = result?.data?.result?.customer;
        const filteredArrayData = arrayData.filter((item) => !item.isvalid);
        const newArrayWithUUIDs = filteredArrayData.map((item, id) => ({
          ...item,
          id,
        }));
        setImportListDataID(newArrayWithUUIDs);
      }
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const handleSaveClick = () => {
    saveDataToServer(importListDataID);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!attachedFile) {
      console.error("No file selected");
      return;
    }
    const formData = new FormData();
    formData.append("file", attachedFile);
    try {
      const response = await postImportFileData(formData).unwrap();
      setImportListData(response?.result?.customer);
      setSaveModalOpen(true);
      setCongratsModalOpen(false);
      setImportModalOpen(false);
    } catch (error) {
      console.error("Error importing file", error);
    }
  };

  const handleInputChange = (e) => {
    let newPostData = { ...postData };
    newPostData.search = e.target.value;
    setPostData(newPostData);
    // debouncedSearch();
  };

  useEffect(() => {
    getCustomers();
  }, [debouncedValue]);

  return (
    <div>
      <Paper sx={{ borderRadius: "16px", mb: 4, mt: 2, height: "auto" }}>
        <Box style={{ padding: "15px" }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={1.5}
            sx={{ width: "100%" }}
            alignItems="center"
            style={{ marginTop: "10px" }}
          >
            <Box>
              <Stack direction="row">
                <Text fontSize="20px" fontWeight="600">
                  Customers
                </Text>

                <Box
                  sx={{
                    borderRadius: "16px",
                    backgroundColor: "#E5EEFF",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "130px",
                    height: "30px",
                    ml: "8px",
                  }}
                >
                  <Text sx={{ fontSize: "14px" }}>{totalCount} Customers</Text>
                </Box>
              </Stack>
              <Text fontSize="15px" fontWeight="400" color="#667085">
                Here you can view and add customer manually or by importing.
              </Text>
            </Box>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={1.5}
              style={{ marginTop: "10px" }}
            >
              <Button
                variant="outlined"
                startIcon={<ImportIcon />}
                onClick={() => {
                  setImportModalOpen(true);
                }}
                sx={{
                  fontWeight: 600,
                  borderRadius: "8px",
                  p: 1,
                  width: "180px",
                }}
              >
                Import Customers
              </Button>

              {/* Import Modal */}
              <NewDialog
                onClose={() => setImportModalOpen(false)}
                open={importModalOpen}
                title={
                  <Text fontSize="16px" fontWeight="600" marginBottom="-15px">
                    Upload and attach file
                  </Text>
                }
                disableCloseIcon
                maxWidth="sm"
                contentComponent={() => (
                  <div>
                    <FileUpload
                      handleFileUnselect={handleFileUnselect}
                      handleChange={({ mailAttachment, value }) => {
                        handleChange({ files: value });
                      }}
                      acceptedFileTypes={[
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        "text/csv",
                      ]}
                    />
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-start"
                      marginTop="10px"
                    >
                      <PlayForWorkIcon />
                      <a
                        href="document_format.xlsx"
                        target="_blank"
                        style={{
                          textDecoration: "underline",
                          fontWeight: "600",
                          color: "#242424",
                        }}
                      >
                        Download format here
                      </a>
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      marginTop="15px"
                      spacing={1}
                    >
                      <Button
                        sx={{
                          width: "60%",
                          fontWeight: 600,
                          borderRadius: "8px",
                          p: 2,
                        }}
                        variant="outlined"
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>
                      <Button
                        sx={{
                          width: "60%",
                          fontWeight: 600,
                          borderRadius: "8px",
                          p: 2,
                        }}
                        onClick={onSubmit}
                      >
                        Attach Files
                      </Button>
                    </Stack>
                  </div>
                )}
              />

              <NewDialog
                onClose={() => setSaveModalOpen(false)}
                open={saveModalOpen}
                title={
                  <Text fontSize="18px" fontWeight="500">
                    Preview of your excel file
                  </Text>
                }
                disableCloseIcon
                maxWidth="lg"
                contentComponent={() => (
                  <div>
                    <CustomGrid
                      editMode="row"
                      autoHeight
                      list={importListDataID}
                      getRowId={(row) => row.id}
                      columnHeaderHeight={46}
                      rowHeight={72}
                      columns={importTableHeader}
                      rowCount={importListDataID.length}
                      paginationModel={importListDataID.length}
                      isLoading={false}
                      // count={importListDataID.length}
                      pagination={false}
                      rowModesModel={rowModesModel}
                      onRowModesModelChange={handleRowModesModelChange}
                      onRowEditStop={handleRowEditStop}
                      processRowUpdate={processRowUpdate}
                    />
                  </div>
                )}
                actionComponent={() => (
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    style={{ backgroundColor: "#E5EEFF", margin: "10px" }}
                  >
                    <Button
                      sx={{
                        width: "200px",
                        fontWeight: 600,
                        borderRadius: "8px",
                        p: 1,
                        m: 2,
                      }}
                      variant="outlined"
                      onClick={handleAttachCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      sx={{
                        width: "200px",
                        fontWeight: 600,
                        borderRadius: "8px",
                        p: 1,
                        m: 2,
                      }}
                      onClick={handleSaveClick}
                    >
                      Add customers
                    </Button>
                  </Stack>
                )}
              />
              <NewDialog
                onClose={() => setCongratsModalOpen(false)}
                open={congratsModalOpen}
                disableCloseIcon
                maxWidth="sm"
                contentComponent={() => (
                  <Stack
                    direction="column"
                    spacing={1.5}
                    sx={{ width: "100%" }}
                    alignItems="center"
                    justifyContent="center"
                    style={{ marginTop: "10px" }}
                  >
                    <Avatar
                      style={{ width: "50px", height: "50px" }}
                      src={Right_CLick}
                    />
                    <Stack p={1} textAlign="center">
                      <Text fontSize="20px" fontWeight="600">
                        Customers have been added successfully !
                      </Text>
                      <Text
                        fontSize="14px"
                        fontWeight="400"
                        color="#667085"
                        padding="10px"
                      >
                        The customer have been added and now they can start
                        their <br />
                        investing journey.
                      </Text>
                    </Stack>
                    <Button
                      sx={{
                        width: "90%",
                        fontWeight: 600,
                        borderRadius: "8px",
                        p: 2,
                        m: 2,
                      }}
                      onClick={handleCongrats}
                    >
                      Okay
                    </Button>
                  </Stack>
                )}
              />
              <SendEmail />
            </Stack>
          </Stack>
        </Box>
        <Box px="15px">
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={1.5}
            sx={{ width: "100%" }}
            alignItems="center"
            style={{ marginTOp: "50px" }}
          >
            <Box style={{ width: "500px", marginTop: "10px" }}>
              <Stack direction="row">
                <TextField
                  sx={{ maxWidth: "500px" }}
                  placeholder="Search Name"
                  value={postData.search}
                  name="search"
                  onChange={(e) => handleInputChange(e, "search")}
                />
                <SearchIcon
                  style={{
                    position: "relative",
                    left: "-42px",
                    marginTop: "12px",
                    color: "#676C76",
                  }}
                />
              </Stack>
            </Box>
            <AddFilter
              getClearFilter={getClearFilter}
              postData={postData}
              setPostData={setPostData}
              applyBtn={handleFilter}
            />
          </Stack>
        </Box>
        <Stack style={{ marginTop: "15px" }}>
          <StyledDataGrid
            sx={{
              // disable cell selection style
              ".MuiDataGrid-cell:focus": {
                outline: "none",
              },
              // pointer cursor on ALL rows
              "& .MuiDataGrid-row:hover": {
                cursor: "pointer",
              },
            }}
            autoHeight
            list={customersData}
            columnHeaderHeight={45}
            rowHeight={72}
            columns={tableHeaderData}
            rowCount={totalCount}
            getRowId={(row) => row?.user_id}
            getRowClassName={(params) => {
              return params?.row?.approve
                ? "super-app-theme--approve"
                : "super-app-theme--not-approve";
            }}
            onCellClick={(e) => {
              if (e && e?.row?.onboard_status === "REJECTED" || e?.row?.onboard_status === "PENDING" ) {
                setIsDrawerOpen(true);
                setCustomerId(e?.row?.user_id);
                setViewData(e.row);
                // navigate(`/customer-detail`, { state: { data: e?.row } });
              } else if (e && e?.row?.onboard_status === "ACCEPTED") {
                setIsOpen(true);
                setViewData(e?.row);
                setCustomerId(e?.row?.user_id);
                navigate(`/customer-detail`);
                  // navigate(`/customer-detail`, { state: { BlockUserID: e?.row?.user_id, BlockUserName: e?.row?.name } });
  
                dispatch(toastActions.setBreadCrumbData(e?.row));
              } else {
                return "";
              }
            }}
            paginationModel={paginationModel}
            paginationMode="server"
            isLoading={false}
            onPaginationModelChange={setPaginationModel}
            loading={isLoading}
          />
        </Stack>
        {customerId === 0 ? (
          ""
        ) : (
          <ViewCustomer
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
            customerId={customerId}
            setCustomerId={setCustomerId}
            getCustomers={getCustomers}
          />
        )}
      </Paper>
    </div>
  );
};

export default CustomerContainer;
