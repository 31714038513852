import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const reducerName = "commonReducer";
const initialState = {
  isLoading: false,
  dashboardData: null, // Add a new field for storing dashboard data

  // Auth
  isAuthenticated: false,

  // user Details
  userDetails: {
    user_id: null,
    name: "",
    email: "",
    family_id: null,
    onboard_status: "",
    onboard_status_reason: "",
    current_stage: null,
    role_id: null,
    role_name: "",
    is_admin: 0,
    is_email_verified: "",
    is_mobile_verified: "",
    is_broker_missing: null,
    jwtToken: null,
    mappedTo:""
  },

  // user Role
  userRoleCode: null,
};

const commonSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setLoading: (state, action) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    resetLoading: (state, action) => {
      return {
        ...state,
        isLoading: false,
      };
    },
    setDashboardData: (state, action) => {
      return {
        ...state,
        dashboardData: action.payload,
      };
    },

    // Auth
    setAuth: (state, action) => {
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    },

    logout: (state, action) => {
      return {
        ...initialState,
      };
    },

    // User details
    setUserDetails: (state, action) => ({
      ...state,
      userDetails: action.payload,
    }),
    resetUserDetails: (state, action) => ({
      ...state,
      userDetails: {
        ...initialState.userDetails,
      },
    }),

    // Set Onboard state
    setOnboardStatus: (state, action) =>({
      ...state,
      userDetails: {
        ...state.userDetails,
        onboard_status: action.payload.status,
        current_stage: action.payload.stage
      }
    }),

    // User Role
    setUserRole: (state, action) => ({
      ...state,
      userRoleCode: action.payload,
    }),
  },
});

export const commonActions = commonSlice.actions;
export const commonReducers = persistReducer(
  { key: reducerName, storage, whitelist: ["auth"] },
  commonSlice.reducer
);
