import Button from "components/common/Button";
import Dialog from "components/common/Dialog";
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import Box from "components/common/Box";
import CustomDataGrid from "components/datagrid/CustomGrid";
import React from "react";

import { useState, useEffect } from "react";
import { modelTableRows, modelTableColumns } from "helpers/constants";

import {
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
//Import Trade
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import Avatar from "components/common/Avatar";
import infoIcon from "asset/icons/infoIcon.svg";
import Popover from "@mui/material/Popover";
import CheckIcon from "@mui/icons-material/Check";

import _get from "lodash/get";

const TradesPreviewModal = (props) => {
  const {
    isOpen = false,
    handleCancel = () => {},
    ProceedToSelectRiskProfile,
    importListData = [],
    validationMessage,
  } = props;

  const [rowModesModel, setRowModesModel] = useState({});
  const [anchorEls, setAnchorEls] = useState({});
  const [importListDataID, setImportListDataID] = useState([]);

  // LS: did update
  useEffect(() => {
    const newArrayWithUUIDs = importListData.map((item, id) => ({
      ...item,
      id,
    }));
    setImportListDataID(newArrayWithUUIDs);
  }, [importListData]);

  // Import table action handle
  const handleClick = (event, id) => {
    setAnchorEls((prevState) => ({
      ...prevState,
      [id]: event.currentTarget,
    }));
  };

  const handleClose = (id) => {
    setAnchorEls((prevState) => ({
      ...prevState,
      [id]: null,
    }));
  };

  const isPopoverOpen = (id) => Boolean(anchorEls[id]);

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };
  const handleImportSave = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };
  const handleDeleteClick = (id) => () => {
    setImportListDataID(importListDataID.filter((row) => row.id !== id));
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setImportListDataID(
      importListDataID.map((row) => (row.id === newRow.id ? updatedRow : row))
    );
    return updatedRow;
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  // Import Tale crud
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  // Table column: Import uploaded data
  const importTableHeader = [
    {
      field: "product_id",
      headerName: "Product ID",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" textAlign="center">
          <Text
            color="#242424"
            fontSize="14px"
            fontWeight="500"
            style={{ cursor: "pointer" }}
          >
            {" "}
            {params?.row?.product_id}
          </Text>
        </Stack>
      ),
      editable: true,
    },
    // {
    //   field: "customer_id",
    //   headerName: "Customer ID",
    //   flex: 1,
    //   renderCell: (params) => (
    //     <>
    //       <Stack direction="column">
    //         <Text
    //           color="#676C76"
    //           fontWeight="400"
    //           fontSize="14px"
    //           alignItems="textCenter"
    //         >
    //           {params?.row?.customer_id}
    //         </Text>
    //       </Stack>
    //     </>
    //   ),
    //   editable: true,
    // },
    {
      field: "customer_name",
      headerName: "Customer Name",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
              marginTop="2px"
            >
              {params?.row?.customer_name}
            </Text>
          </Stack>
        </>
      ),
      editable: true,
    },
    {
      field: "broker_name",
      headerName: "Broker Name",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#6F6F6F"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.broker_name}
            </Text>
          </Stack>
        </>
      ),
      editable: true,
    },
    {
      field: "login_id",
      headerName: "Broker Login ID",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#6F6F6F"
              fontWeight="500"
              fontSize="12px"
              alignItems="textCenter"
            >
              {params?.row?.login_id}
            </Text>
          </Stack>
        </>
      ),
      editable: true,
    },
    // {
    //   field: "risk_profile_id",
    //   headerName: "Risk Profile ID",
    //   flex: 1,
    //   renderCell: (params) => (
    //     <>
    //       <Stack direction="column">
    //         <Text
    //           color="#6F6F6F"
    //           fontWeight="500"
    //           fontSize="14px"
    //           alignItems="textCenter"
    //         >
    //           {params?.row?.risk_profile_id}
    //         </Text>
    //       </Stack>
    //     </>
    //   ),
    //   editable: false,
    // },
    {
      field: "risk_name",
      headerName: "Risk Profile Name",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#6F6F6F"
              fontWeight="500"
              fontSize="12px"
              alignItems="textCenter"
            >
              {params?.row?.risk_name}
            </Text>
          </Stack>
        </>
      ),
      editable: true,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#6F6F6F"
              fontWeight="500"
              fontSize="12px"
              alignItems="textCenter"
            >
              {params?.row?.action}
            </Text>
          </Stack>
        </>
      ),
      editable: true,
    },
    {
      field: "symbol",
      headerName: "Stock Name",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#6F6F6F"
              fontWeight="500"
              fontSize="12px"
              alignItems="textCenter"
            >
              {params?.row?.symbol}
            </Text>
          </Stack>
        </>
      ),
      editable: true,
    },
    {
      field: "exchange",
      headerName: "Index",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#6F6F6F"
              fontWeight="500"
              fontSize="12px"
              alignItems="textCenter"
            >
              {params?.row?.exchange}
            </Text>
          </Stack>
        </>
      ),
      editable: true,
    },

    {
      field: "quantity",
      headerName: "Quantity",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="column">
          <Text
            color="#6F6F6F"
            fontWeight="500"
            fontSize="12px"
            alignItems="textCenter"
          >
            {params?.row?.quantity}
          </Text>
        </Stack>
      ),
      editable: true,
    },
    {
      field: "order_type",
      headerName: "Order Type",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="column">
          <Text
            color="#6F6F6F"
            fontWeight="500"
            fontSize="12px"
            alignItems="textCenter"
          >
            {params?.row?.order_type}
          </Text>
        </Stack>
      ),
      editable: true,
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="column">
          <Text
            color="#6F6F6F"
            fontWeight="500"
            fontSize="12px"
            alignItems="textCenter"
          >
            {params?.row?.price}
          </Text>
        </Stack>
      ),
      editable: true,
    },
    {
      field: "is_percent",
      headerName: "Is Percent",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="column">
          <Text
            color="#6F6F6F"
            fontWeight="500"
            fontSize="12px"
            alignItems="textCenter"
          >
            {params?.row?.is_percent}
          </Text>
        </Stack>
      ),
      editable: true,
    },
    {
      field: "percent",
      headerName: "Percent",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="column">
          <Text
            color="#6F6F6F"
            fontWeight="500"
            fontSize="12px"
            alignItems="textCenter"
          >
            {params?.row?.percent}
          </Text>
        </Stack>
      ),
      editable: true,
    },
    {
      field: "is_stop_loss",
      headerName: "Is Stop Loss",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="column">
          <Text
            color="#6F6F6F"
            fontWeight="500"
            fontSize="12px"
            alignItems="textCenter"
          >
            {params?.row?.is_stop_loss}
          </Text>
        </Stack>
      ),
      editable: true,
    },
    {
      field: "stop_loss",
      headerName: "Stop Loss",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="column">
          <Text
            color="#6F6F6F"
            fontWeight="500"
            fontSize="12px"
            alignItems="textCenter"
          >
            {params?.row?.stop_loss}
          </Text>
        </Stack>
      ),
      editable: true,
    },
    {
      field: "is_target",
      headerName: "Is Target",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="column">
          <Text
            color="#6F6F6F"
            fontWeight="500"
            fontSize="12px"
            alignItems="textCenter"
          >
            {params?.row?.is_target}
          </Text>
        </Stack>
      ),
      editable: true,
    },
    {
      field: "target",
      headerName: "Target",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="column">
          <Text
            color="#6F6F6F"
            fontWeight="500"
            fontSize="12px"
            alignItems="textCenter"
          >
            {params?.row?.target}
          </Text>
        </Stack>
      ),
      editable: true,
    },
    {
      field: "expiry_date",
      headerName: "Expiry Date",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="column">
          <Text
            color="#6F6F6F"
            fontWeight="500"
            fontSize="12px"
            alignItems="textCenter"
          >
            {params?.row?.expiry_date}
          </Text>
        </Stack>
      ),
      editable: true,
    },
    {
      field: "expiry_time",
      headerName: "Expiry Time",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="column">
          <Text
            color="#6F6F6F"
            fontWeight="500"
            fontSize="12px"
            alignItems="textCenter"
          >
            {params?.row?.expiry_time}
          </Text>
        </Stack>
      ),
      editable: true,
    },
    {
      field: "lot_size",
      headerName: "Lot Size",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="column">
          <Text
            color="#6F6F6F"
            fontWeight="500"
            fontSize="12px"
            alignItems="textCenter"
          >
            {params?.row?.lot_size}
          </Text>
        </Stack>
      ),
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      renderCell: (params) => {
        const isInEditMode =
          rowModesModel[params.id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<CheckIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleImportSave(params.id)}
              color="inherit"
            />,
          ];
        }

        return [
          <>
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(params.id)}
              color="inherit"
            />
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(params.id)}
              color="inherit"
            />

            {params?.row?.status ? (
              <Box ml="25px"></Box>
            ) : (
              <Stack direction="row" marginLeft="2px">
                <Avatar
                  style={{ width: "20px", height: "20px" }}
                  src={infoIcon}
                  aria-describedby={params?.id}
                  variant="contained"
                  onClick={(event) => handleClick(event, params?.id)}
                />
                <Popover
                  id={params.id}
                  open={isPopoverOpen(params?.id)}
                  anchorEl={anchorEls[params?.id]}
                  onClose={() => handleClose(params?.id)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <Text sx={{ p: 2 }}>{params?.row?.message}</Text>
                </Popover>
              </Stack>
            )}
          </>,
        ];
      },
    },
  ];

  return (
    <Dialog
      onClose={handleClose}
      open={isOpen}
      title={
        <>
          <Text fontSize="18px" fontWeight="500">
            Preview of your excel file
          </Text>
        </>
      }
      disableCloseIcon
      maxWidth="xl"
      contentComponent={() => (
        <div>
          <CustomDataGrid
            editMode="row"
            autoHeight
            list={importListDataID}
            getRowId={(row) => row.id}
            columnHeaderHeight={46}
            rowHeight={72}
            columns={importTableHeader}
            rowCount={importListDataID.length}
            // paginationModel={importListDataID.length}
            // count={importListDataID.length}
            pagination={false}
            handleEditClick={handleEditClick}
            rowModesModel={rowModesModel}
            handleRowModesModelChange={handleRowModesModelChange}
            handleRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
          />
        </div>
      )}
      actionComponent={() => (
        <Stack
          direction="row"
          justifyContent="flex-end"
          style={{ backgroundColor: "#E5EEFF", margin: "10px" }}
        >
          {validationMessage && (
            <p
              className="validation-message"
              style={{ color: "red", marginTop:"30px" ,marginRight: "10px",fontSize: "20px" }}
            >
             <b>{validationMessage}</b> 
            </p>
          )}
          <Button
            sx={{
              width: "160px",
              fontWeight: 600,
              borderRadius: "8px",
              fontSize: "14px",
              p: "14px 28px",
              m: 2,
              backgroundColor: "#FFF",
            }}
            variant="outlined"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            sx={{
              // width: "30%",
              fontWeight: 600,
              borderRadius: "8px",
              fontSize: "14px",
              p: "14px 28px",
              m: 2,
            }}
            onClick={() => ProceedToSelectRiskProfile(importListDataID)}
          >
            Proceed
          </Button>
        </Stack>
      )}
    />
  );
};

export default TradesPreviewModal;
