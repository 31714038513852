import React, { useState, useEffect } from "react";
import SideDrawer from "components/common/SideDrawer";
import Stack from "components/common/Stack";
import Button from "components/common/Button";
import Box from "components/common/Box";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import Text from "components/common/Text";
import Checkbox from "components/common/Checkbox";
import NewTextField from "../common/TextField";
import FileUpload from "components/common/FileUpload";
import { toastActions } from "stores/redux/slices/toastSlice";
import { customersApiAction } from "stores/redux/apiSlices/customers/customersApiSlice";
import { set } from "date-fns";
import { useDispatch } from "react-redux";

const SendEmail = () => {
  const dispatch = useDispatch();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const dataShow = customersApiAction.getProductId();
  const checkboxData = dataShow?.data?.products;
  const [postSendEmailData] = customersApiAction.postEmailApi();
  const [formAttachmentData, setFormAttachementData] = useState("");
  const [formData, setFormData] = useState({
    product_id: [],
    mailHeader: "",
    mailContent: "",
    mailAttachment: [
      {
        filename: "",
        content: "",
        contentType: "",
      },
    ],
  });

  const [errorMessages, setErrorMessages] = useState({
    product: "",
    mailHeader: "",
    mailContent: "",
    // mailAttachment: "",
  });

  const handleCheckboxChange = (type, product_id) => {
    setFormData((prevData) => {
      const updatedData = { ...prevData };
      if (!updatedData[type]) {
        updatedData[type] = [];
      }
      if (updatedData[type].includes(product_id)) {
        updatedData[type] = updatedData[type].filter((id) => id !== product_id);
      } else {
        updatedData[type] = [...updatedData[type], product_id];
      }
      return updatedData;
    });
  };

  const handleInputChange = (e, field) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: e.target.value,
    }));
  };

  const handleFileUnselect = () => {
    setFormData((prevData) => ({
      ...prevData,
      mailAttachment: [
        {
          filename: "",
          content: "",
          contentType: "",
        },
      ],
    }));
  };


  const handleFileSubmit = async (props) => {
    const selectedFiles = props.files;
    if (selectedFiles && selectedFiles.length > 0) {
      const filesArray = [];
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        const base64String = await fileToBase64(file);
        filesArray.push({
          filename: file.name,
          content: base64String,
          contentType: "base64",
        });
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        mailAttachment: filesArray,
      }));
    }
  };

  const fileToBase64 = (file) => {
    if (file) {
      return new Promise((resolve) => {
        let baseURL = "";
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          baseURL = reader.result.substr(reader.result.indexOf(",") + 1);
          resolve(baseURL);
        };
      });
    }
    return null;
  };

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setFormData("");
    setErrorMessages("");
  };

  const validation = () => {
    // let hasError = false;
    // if (!formData.product_id || formData.product_id.length === 0) {
    //   setErrorMessages((prevMessages) => ({
    //     ...prevMessages,
    //     product: "Please select at least one product.",
    //   }));
    //   hasError = true;
    // }

    // // Check if subject is filled
    // if (!formData.mailHeader) {
    //   setErrorMessages((prevMessages) => ({
    //     ...prevMessages,
    //     mailHeader: "Please fill in the subject.",
    //   }));
    //   hasError = true;
    // }

    // // Check if content is filled
    // if (!formData.mailContent) {
    //   setErrorMessages((prevMessages) => ({
    //     ...prevMessages,
    //     mailContent: "Please fill in the content.",
    //   }));
    //   hasError = true;
    // }

    // if (hasError) {
    //   return;
    // }
  };

  const handleSendEmailData = async () => {
    let hasError = false;
    if (!formData.product_id || formData.product_id.length === 0) {
      setErrorMessages((prevMessages) => ({
        ...prevMessages,
        product: "Please select at least one product.",
      }));
      hasError = true;
    }

    // Check if subject is filled
    if (!formData.mailHeader) {
      setErrorMessages((prevMessages) => ({
        ...prevMessages,
        mailHeader: "Please fill in the subject.",
      }));
      hasError = true;
    }

    // Check if content is filled
    if (!formData.mailContent) {
      setErrorMessages((prevMessages) => ({
        ...prevMessages,
        mailContent: "Please fill in the content.",
      }));
      hasError = true;
    }

    if (hasError) {
      return;
    }
    const data = formData;
    if ((data.mailAttachment === undefined) | null) {
      data.mailAttachment = [
        {
          filename: "",
          content: "",
          contentType: "",
        },
      ];
    }
    try {
      const response = await postSendEmailData(data)
        .unwrap()
        .then((res) => {
          if (res.status === 1) {
            const toastMessage =
              res.status === 1 ? res.message
               : "Failed to send email.";
            dispatch(
              toastActions.setToastData({
                message: toastMessage,
                variant: res.status === 1 ? res.message : "error",
              })
            );
          }
          if (res.status === 1) {
            setIsDrawerOpen(false);
            setErrorMessages('');
            setFormData('');
          }
          
        });
      console.log("response", response);
    } catch (error) {
      console.error("Error saving data:", error);
    }
};


  useEffect(() => {
  }, [formData]);

  return (
    <Stack>
      <Button
        sx={{ fontWeight: 600, borderRadius: "8px", p: 1, width: "140px" }}
        onClick={openDrawer}
        startIcon={<EmailOutlinedIcon />}
      >
        Send Email
      </Button>
      {isDrawerOpen && (
        <SideDrawer
          anchor="right"
          open={isDrawerOpen}
          closeDrawer={closeDrawer}
          title="Send Email"
          contentTitle=""
          handleSubmit={handleSendEmailData}
          cancelButtonText="Cancel"
          submitButtonText="Send"
          subtitle="Here you can create email and send to customers."
        >
          <Box>
            <Text variant="small" fontWeight="500" fontSize="16px">
              Send email to customers of
            </Text>
            <Stack
              direction="row"
              justifyContent="space-between"
              style={{ marginLeft: "15px" }}
            >
              {(checkboxData ?? []).length > 0 &&
                checkboxData.map((items, i) => (
                  <Checkbox
                    key={items.product_id}
                    label={
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Text
                          variant="small"
                          fontWeight="500"
                          fontSize="14px"
                          style={{ marginLeft: "10px" }}
                        >
                          {items?.product_name}
                        </Text>
                      </Box>
                    }
                    size="small"
                    name="sendData"
                    checked={formData?.product_id?.includes(items.product_id)}
                    onChange={() =>
                      handleCheckboxChange("product_id", items.product_id)
                    }
                  />
                ))}
            </Stack>
            {errorMessages.product && (
              <Text variant="small" color="red">
                {errorMessages.product}
              </Text>
            )}
          </Box>
          <Box sx={{ m: 2 }}>
            <Text
              variant="small"
              component="h4"
              fontSize="16px"
              fontWeight="500"
              marginBottom="3px"
            >
              Subject
            </Text>
            <NewTextField
              placeholder="Enter subject here"
              name="mailHeader"
              value={formData.mailHeader}
              onChange={(e) => handleInputChange(e, "mailHeader")}
              sx={{ width: "100%" }}
            />
          </Box>
          {errorMessages.mailHeader && (
            <Text variant="small" color="red">
              {errorMessages.mailHeader}
            </Text>
          )}
          <Box sx={{ m: 2 }}>
            <Text
              variant="small"
              component="h4"
              fontSize="16px"
              fontWeight="500"
              marginBottom="3px"
            >
              Brief
            </Text>
            <NewTextField
              inputProps={{ style: { fontSize: "14px" } }}
              placeholder="Enter brief of the remark here"
              multiline
              rows={4}
              name="mailContent"
              value={formData.mailContent}
              onChange={(e) => handleInputChange(e, "mailContent")}
              sx={{ width: "100%" }}
            />
          </Box>
          {errorMessages.mailContent && (
            <Text variant="small" color="red">
              {errorMessages.mailContent}
            </Text>
          )}
          <Box sx={{ m: 2 }}>
            <Text
              variant="small"
              component="h4"
              fontSize="16px"
              fontWeight="500"
            >
              Attachment file
            </Text>
            <FileUpload
              multipleFiles={true}
              handleFileUnselect={handleFileUnselect} 
              handleChange={({ mailAttachment, value }) => {
                handleFileSubmit({ files: value });
              }}
              sx={{ width: "100%" }}
              acceptedFiles={['application/pdf']}
            />
          </Box>
        </SideDrawer>
      )}
    </Stack>
  );
};

export default SendEmail;
