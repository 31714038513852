// localStore.js

import { appConstants } from "helpers/constants/appConstants";
import { localstorageService } from "helpers/services/localstorageService";

const localStore = {};

localStore.setToken = (v) =>
  localstorageService.set("token", v);

localStore.getToken = () =>
  localstorageService.get("token");

localStore.resetToken = () =>
  localstorageService.remove("token");

// Add a method to set the user's name in local storage
localStore.setName = (userName) =>
  localstorageService.set(appConstants.localStorage.userNameKey, userName);

// Add a method to get the user's name from local storage
localStore.getName = () =>
  localstorageService.get(appConstants.localStorage.userNameKey);

localStore.setHeaderData = (dashboardData) => {

  if (dashboardData) {
    try {
      const { sebiregno, baslNo, expirydate } = dashboardData;
      const dataToStore = { sebiregno, baslNo, expirydate };

      const jsonString = JSON.stringify(dataToStore);

      localstorageService.set(
        appConstants.localStorage.dashboardDataKey,
        jsonString
      );
    } catch (error) {
      console.error("Error during JSON.stringify:", error);
    }
  }
};

// Add a method to get dashboardData from local storage
localStore.getHeaderData = () => {
  const storedData = localstorageService.get(
    appConstants.localStorage.dashboardDataKey
  );
  return storedData ? JSON.parse(storedData) : null;
};

export { localStore };
