import React, { useEffect, useState } from "react";
import Box from "components/common/Box";
import Text from "components/common/Text";
import TextField from "components/common/TextField";
import FileUpload from "components/common/FileUpload";
import DatePicker from "components/common/DatePicker";
import Button from "components/common/Button";
import AddIcon from "@mui/icons-material/Add";
import DownloadButton from "components/common/DownloadButton";
import Grid from "components/common/Grid";
import { uuid } from "helpers/utility";
import { customersApiAction } from "stores/redux/apiSlices/customers/customersApiSlice";
import dayjs from "dayjs";
import Stack from "components/common/Stack";
import { toastActions } from "stores/redux/slices/toastSlice";
import {useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { clickAndDownloadFile } from "helpers/fileFormat"

const Additional = () => {
	//hooks
	const dispatch = useDispatch();
	const location =useLocation();
	const select = useSelector((state) => state);
	const BlockUserID = location.state && location.state.BlockUserID;
	const BlockUserName = location.state && location.state.BlockUserName;
  
	const user_id =  BlockUserID === null ? select?.toast?.breadCrumbData?.user_id : BlockUserID ;
	const [updateAdditionalDetails] = customersApiAction.updateAdditionalDocApi();
	const [getAdditionalDetail, additionalDetail = []] =
		customersApiAction.getAdditionalDetails(user_id);
	const [downloadDocAPI] = customersApiAction.postProfileDocApi(user_id);
	const [downloadRiskDocAPI] = customersApiAction.getCustomerSelectedAnswer()
	const [addnewDoc] = customersApiAction.postAdditionalDetailsDoc();

	//Constants
	const elementHeight = 50;

	//State
	const [isEditable, setIsEditable] = useState(false);
	const [isAddDocVisible, setIsAddDocVisible] = useState(false);
	const [postData, setPostData] = useState({
		demat_acc_no: null,
		demat_acc_statement: null,
		bank_name: null,
		acc_no: null,
		nominee_name: null,
		nominee_relationship: null,
		dob: null,
		investment_policy_statement: null,
		documents: [],
	});
	const [newDoc, setNewDoc] = useState({
		docName: null,
		doc: null,
	});
	const [docNameError, setDocNameError] = useState(false);
    const [docError, setDocError] = useState(false);

	//useEffect

	useEffect(() => {
		getAdditionalDetail(user_id);
	}, []);

	useEffect(() => {
		if (!additionalDetail?.data) {
			return;
		}
		let newData = {};
		newData.demat_acc_no = additionalDetail?.data?.demat_acc_no ?? null;
		newData.demat_acc_statement = additionalDetail?.data?.demat_acc_statement ?? null;
		newData.bank_name = additionalDetail?.data?.bank_name ?? null;
		newData.acc_no = additionalDetail?.data?.acc_no ?? null;
		newData.nominee_name = additionalDetail?.data?.nominee_name ?? null;
		newData.nominee_relationship = additionalDetail?.data?.nominee_relationship ?? null;
		newData.dob = additionalDetail?.data?.dob ?? null;
		newData.investment_policy_statement =
			additionalDetail?.data?.investment_policy_statement ?? null;
		newData.documents =
			additionalDetail?.data?.documents?.map((item) => ({
				document_id: item.document_id,
				document_name: item.document_name,
				document_url: item.document_url,
				id: uuid(),
				isUpdated: false,
			})) ?? [];

		setPostData(newData);
	}, [additionalDetail?.data]);

	//Handler Methods

	const handleNewDocChange = async ({ name, value }) => {
		let newDocData = { ...newDoc };
		if (name === "doc") {
			const base64Content = await readFileAsync(value);
			const file = `data:application/pdf;base64,${base64Content}`;
			newDocData[name] = file;
			setNewDoc(newDocData);
			return;
		}
		newDocData[name] = value;
		setNewDoc(newDocData);
	};

	const handlePostDataChange = async ({ name, value, isFile = false }) => {
		let newPostData = { ...postData };
		if (isFile) {
			const base64Content = await readFileAsync(value);
			const file = `data:application/pdf;base64,${base64Content}`;
			newPostData[name] = file;
			setPostData(newPostData);
			return;
		}
		if (["demat_acc_no", "acc_no"].includes(name) && isNaN(value)) {
			return;
		}
		newPostData[name] = value;
		setPostData(newPostData);
	};

	const handleDocChange = async ({ name, value, id }) => {
		let documents = [...postData.documents];
		documents.forEach(async (item) => {
			if (item.id === id) {
				const base64Content = await readFileAsync(value);
				const fileObject = `data:application/pdf;base64,${base64Content}`;
				item.newDoc = fileObject;
				item.isUpdated = true;
			}
		});
		setPostData({ ...postData, documents });
	};

	const handleRiskDownload = async () => {
		try {
		  if (user_id !== undefined && user_id !== 0) {
			const res = await downloadRiskDocAPI({ user_id: user_id });
			if (res.data.file) {
				const {
				  file,
				  filename
				} = res.data;
				await clickAndDownloadFile({base64File: file, fileName: filename});
			}

		  }
		} catch (error) {
		  console.error("Error downloading document:", error);
		}
	  };

	const handleDownloadDoc = async (type) => {
		try {
			const requestBody = {
				user_id: user_id,
				type: type,
			};
			let res = await downloadDocAPI(requestBody).unwrap();
			const result = res?.result;
			if (result?.file) {
				const base64 = await fetch(result.file);
				const blob = await base64.blob();
				const a = document.createElement("a");
				document.body.appendChild(a);
				const url = window.URL.createObjectURL(blob);
				a.href = url;
				a.download = result.filename || "downloaded_file.png";
				a.click();
			} else {
				console.error("No file found in the API response.");
			}
		} catch (err) {
			console.error("Error during download:", err);
		}
	};

	const handleAddNewDoc = async () => {
		try {
			 // Validate if both fields are filled
			 if (!newDoc.docName) {
                setDocNameError(true);
            }
            if (!newDoc.doc) {
                setDocError(true);
            }
            
            // If any field is empty, exit the function
            if (!newDoc.docName || !newDoc.doc) {
                return;
            }

			let payload = {
				display_name: newDoc.docName,
				file: newDoc.doc,
			};
			let res = await addnewDoc({ user_id, payload });
			if (res.data.status) {
				setIsAddDocVisible(false);
				setNewDoc({});
				getAdditionalDetail(user_id);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const handleDocRemove = (id) => {
		let documents = [...postData.documents];
		documents.forEach(async (item) => {
			if (item.id === id) {
				delete item.newDoc;
				item.isUpdated = false;
			}
		});
		setPostData({ ...postData, documents });
	};

	const handleStatementDocRemove = (name) => {
		let newPostData = { ...postData };
		delete newPostData[name];
	};

	const readFileAsync = (file) => {
		return new Promise((resolve) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(reader.result.split(",")[1]);
			reader.readAsDataURL(file);
		});
	};

	const handleDatePickerChange = (date) => {
		const dob = dayjs(date).format("MM-DD-YYYY");
		// setSelectedExpiryDate(expiryDate);
		setPostData((prevPostData) => ({
			...prevPostData,
			dob: dob,
		}));
	};

	const handleSave = async () => {
		try {
			let newPostData = { ...postData };
			delete newPostData["new_investment_policy_statement"];
			delete newPostData["demat_acc_statement"];

			let payload = {};
			payload.demat_acc_no = postData?.demat_acc_no;
			payload.bank_name = postData?.bank_name;
			payload.acc_no = postData?.acc_no;
			payload.nominee_name = postData?.nominee_name;
			payload.nominee_relationship = postData?.nominee_relationship;
			payload.dob = null;

			if (postData?.new_demat_acc_statement) {
				payload.demat_acc_statement = postData.new_demat_acc_statement;
			} else {
				payload.demat_acc_statement = postData.demat_acc_statement;
			}

			if (postData?.new_investment_policy_statement) {
				payload.investment_policy_statement = postData.new_investment_policy_statement;
			} else {
				payload.investment_policy_statement = postData.investment_policy_statement;
			}
			payload.documents = [];
			postData?.documents.forEach((item) => {
				if (item?.isUpdated) {
					payload.documents.push(item);
				}
			});

			let res = await updateAdditionalDetails({ user_id, payload });
			if (res.data.status === 1) {
				setIsEditable(false);
				getAdditionalDetail(user_id);
				const toastMessage = "Additional details added Successfully!";
				dispatch(
				  toastActions.setToastData({
					message: toastMessage,
					variant: "success",
				  })
				);
			}
			else {
				const toastMessage = res?.data?.message || "Failed to Additional details added.";
				dispatch(
				  toastActions.setToastData({
					message: toastMessage,
					variant: "error",
				  })
				);
			  }
		} catch (err) {
			console.log(err);
		}
	};
	console.log("postData : ", postData);
	return (
		<>
			<Stack alignItems="flex-end">
				{!isEditable ? (
					<>
						<Button
							onClick={() => {
								setIsEditable(true);
							}}
							size="large"
						>
							Edit
						</Button>
					</>
				) : (
					<>
						<Stack spacing={1} direction="row">
							<Button size="large" onClick={handleSave}>
								Save
							</Button>
							<Button
								variant="outlined"
								onClick={() => {
									setIsEditable(false);
								}}
								size="large"
							>
								Cancel
							</Button>
						</Stack>
					</>
				)}
			</Stack>
			<Grid container spacing={1.5}>
				<Grid item xs={6}>
					<Box>
						<Text fontSize="16px" fontWeight="500">
						   Risk Assissment details
						</Text>
						<DownloadButton
									variant="outlined"
									onClick={handleRiskDownload}
									style={{ marginTop: "8px" }}
								>
							RiskAssessment.pdf
						</DownloadButton>
					</Box>
				</Grid>
			</Grid>
			<Grid container spacing={1.5} sx={{marginTop:'3px'}}>
				<Grid item xs={6}>
					<Box>
						<Text fontSize="16px" fontWeight="500">
							Demat account number
						</Text>
						<TextField
							style={{ marginTop: "6px" }}
							placeholder="Enter demat account number"
							name="demat_acc_no"
							value={postData?.demat_acc_no}
							onChange={(e) =>
								handlePostDataChange({
									name: e.target.name,
									value: e.target.value,
								})
							}
							disabled={!isEditable}
						/>
					</Box>
				</Grid>
				<Grid item xs={6}>
					<Box>
						<Text fontSize="16px" fontWeight="500">
							Bank Name
						</Text>
						<TextField
							style={{ marginTop: "6px" }}
							placeholder="Enter Bank Name"
							name="bank_name"
							value={postData?.bank_name}
							onChange={(e) =>
								handlePostDataChange({
									name: e.target.name,
									value: e.target.value,
								})
							}
							disabled={!isEditable}
						/>
					</Box>
				</Grid>
			</Grid>
			<Grid container spacing={1.5} style={{ marginTop: "0px" }}>
				<Grid item xs={6}>
					<Box>
						<Text fontSize="16px" fontWeight="500">
							Account number
						</Text>
						<TextField
							style={{ marginTop: "6px" }}
							placeholder="Enter bank account number"
							name="acc_no"
							value={postData?.acc_no}
							onChange={(e) =>
								handlePostDataChange({
									name: e.target.name,
									value: e.target.value,
								})
							}
							disabled={!isEditable}
						/>
					</Box>
				</Grid>
				<Grid item xs={6}>
					<Box>
						<Text fontSize="16px" fontWeight="500">
							Nominee Name
						</Text>
						<TextField
							style={{ marginTop: "6px" }}
							placeholder="Enter Nominee Name"
							name="nominee_name"
							value={postData?.nominee_name}
							onChange={(e) =>
								handlePostDataChange({
									name: e.target.name,
									value: e.target.value,
								})
							}
							disabled={!isEditable}
						/>
					</Box>
				</Grid>
			</Grid>
			<Grid container spacing={1.5} style={{ marginTop: "0px" }}>
				<Grid item xs={6}>
					<Box>
						<Text fontSize="16px" fontWeight="500">
							Nominee Relationship
						</Text>
						<TextField
							style={{ marginTop: "6px" }}
							placeholder="Enter Nominee Relationship"
							name="nominee_relationship"
							value={postData?.nominee_relationship}
							onChange={(e) =>
								handlePostDataChange({
									name: e.target.name,
									value: e.target.value,
								})
							}
							disabled={!isEditable}
						/>
					</Box>
				</Grid>
				<Grid item xs={6}>
					<Box>
						<Text fontSize="16px" fontWeight="500">
							Date of birth
						</Text>
						{!isEditable ? (
							<DatePicker
								value={dayjs(postData.dob).format("MM-DD-YYYY") ?? ""}
								sx={{ width: "100%", height: "55px", marginTop: "6px" }}
								placeholder="Date"
								disabled
							/>
						) : (
							<DatePicker
								onChange={handleDatePickerChange}
								sx={{ width: "100%", height: "55px", marginTop: "6px" }}
								value={dayjs(postData.dob)}
							
							/>
						)}
					</Box>
				</Grid>
			</Grid>

			<Grid container spacing={1.5} style={{ marginTop: "0px" }}>
				<Grid item xs={6}>
					<Box>
						<Text fontSize="16px" fontWeight="500">
							Investment policy statement
						</Text>
						{!isEditable ? (
							additionalDetail?.data?.investment_policy_statement ?
							<DownloadButton
								style={{ marginTop: "8px" }}
								variant="outlined"
								onClick={() => handleDownloadDoc("investment_policy_statement")}
							>
								{additionalDetail?.data?.investment_policy_statement}
							</DownloadButton>
							:
							<FileUpload
							handleChange={({ name, value }) => {
								handlePostDataChange({
									name: name,
									value: value[0],
									isFile: true,
								});
							}}
							style={{
								height: `${elementHeight}px !important`,
								marginTop: "6px",
							}}
							name="new_investment_policy_statement"
							handleFileUnselect={() => {
								handleStatementDocRemove("new_investment_policy_statement");
							}}
						/>
						) : (
							<FileUpload
								handleChange={({ name, value }) => {
									handlePostDataChange({
										name: name,
										value: value[0],
										isFile: true,
									});
								}}
								acceptedFiles={['application/pdf']}
								style={{
									height: `${elementHeight}px !important`,
									marginTop: "6px",
								}}
								name="new_investment_policy_statement"
								handleFileUnselect={() => {
									handleStatementDocRemove("new_investment_policy_statement");
								}}
							/>
						)}
					</Box>
				</Grid>
				<Grid item xs={6}>
					<Box>
						<Text fontSize="16px" fontWeight="500">
							Demat account statement
						</Text>
						{!isEditable ? (
							additionalDetail?.data?.demat_acc_statement?
							<DownloadButton
								variant="outlined"
								onClick={() => handleDownloadDoc("demat_acc_statement")}
								style={{ marginTop: "8px" }}
							>
								{additionalDetail?.data?.demat_acc_statement}
							</DownloadButton>
							:
							<FileUpload
								handleChange={({ name, value }) => {
									handlePostDataChange({
										name: name,
										value: value[0],
										isFile: true,
									});
								}}
								style={{ marginTop: "6px" }}
								name="new_demat_acc_statement"
								handleFileUnselect={() => {
									handleStatementDocRemove("new_demat_acc_statement");
								}}
							/>
						) : (
							<FileUpload
								handleChange={({ name, value }) => {
									handlePostDataChange({
										name: name,
										value: value[0],
										isFile: true,
									});
								}}
								acceptedFiles={['application/pdf']}
								style={{ marginTop: "6px" }}
								name="new_demat_acc_statement"
								handleFileUnselect={() => {
									handleStatementDocRemove("new_demat_acc_statement");
								}}
							/>
						)}
					</Box>
				</Grid>
				{postData?.documents.map((document) => (
					<Grid item xs={6}>
						<Box>
							<Text fontSize="16px" fontWeight="500">
								{document?.document_name}
							</Text>
							{!isEditable ? (
								<DownloadButton
									variant="outlined"
									onClick={() => handleDownloadDoc(document?.document_name)}
									style={{ marginTop: "8px" }}
								>
									{document?.document_url}
								</DownloadButton>
							) : (
								<FileUpload
									handleChange={({ name, value }) => {
										handleDocChange({
											name: document?.document_name,
											value: value[0],
											id: document.id,
										});
									}}
									acceptedFiles={['application/pdf']}
									name={document?.document_name}
									style={{ marginTop: "6px" }}
									handleFileUnselect={() => {
										handleDocRemove(document.id);
									}}
								/>
							)}
						</Box>
					</Grid>
				))}
			</Grid>
			{isAddDocVisible ? (
				<Grid container spacing={1.5} style={{ marginTop: "2px" }}>
					<Grid item xs={6}>
						<Box sx={{}}>
							<Text fontSize="16px" fontWeight="500" 	sx={{mb:"7px"}}>
								Name of document
							</Text>
							<TextField
								placeholder="Enter Name of document"
								name="docName"
								error={docNameError}
                                helperText={docNameError ? "This field is required" : ""}
							
								// value={postData.document_name}
								onChange={(e) =>
									handleNewDocChange({
										name: e.target.name,
										value: e.target.value,
									})
					
								}
							/>
						</Box>
					</Grid>

					<Grid item xs={6}>
						<Box >
						<Text fontSize="16px" fontWeight="500">
								Upload document
						</Text>
						<FileUpload
						 error={docNameError}
						 helperText={docNameError ? "This field is required" : ""}
							handleChange={({ name, value }) => {
								handleNewDocChange({
									name: name,
									value: value[0],
								});
							}}
							acceptedFiles={['application/pdf']}
							name="doc"
				
						/>
						</Box>
					</Grid>

			
					<Grid item xs={6}>

					</Grid>
					<Grid item xs={6} >
						<Stack
							spacing={1}
							sx={{ height: "100%" }}
							direction="row"
							justifyContent="flex-end"
							marginTop="6px"
						>
							<Button size="large" onClick={handleAddNewDoc}>
								Save
							</Button>
							<Button
								variant="outlined"
								size="large"
								onClick={() => {
									setIsAddDocVisible(false);
								}}
							>
								Cancel
							</Button>
						</Stack>
					</Grid>
				</Grid>
			) : (
				<Box style={{ marginTop: "20px" }}>
					<Button
						variant="outlined"
						startIcon={<AddIcon />}
						onClick={() => {
							setIsAddDocVisible(true);
						}}
					>
						Add another document
					</Button>
				</Box>
			)}
		</>
	);
};

export default Additional;
