import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Chip } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CustomGrid from "components/datagrid/CustomGrid";
import { familyApiAction } from "stores/redux/apiSlices/familyMembers/familyApiSlice";
import { toastActions } from "stores/redux/slices/toastSlice";
import EyeIcon from "asset/icons/EyeIcon";
import SearchIcon from "@mui/icons-material/Search";
import AlertIcon from "asset/icons/AlertIcon";
import Stack from "components/common/Stack";
import IconButton from "components/common/IconButton";
import Button from "components/common/Button";
import NewDialog from "components/common/Dialog";
import Link from "components/common/Link";
import Box from "components/common/Box";
import Text from "components/common/Text";
import SideDrawer from "components/common/SideDrawer";
import TextField from "components/common/TextField";
import Divider from "components/common/Divider";
import { validationHelper } from "helpers/validation";
import useDebounce from "hooks/Debounce";
import MultiSelect from "components/common/MultiSelect";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function FamilyMembersContainer() {
  const [formData, setFormData] = useState({
    selectedHighRiskCustomers: [],
    isCustomersSelected: false,
    selectedCustomers: [],
    isRiskProfileSelected: true,
    highRiskSearch: null,
  });

  const [isDrawerAddFamily, setDrawerAddFamily] = useState(false);
  const [isDrawerAddMember, setIsDrawerAddMember] = useState(false);
  const [isDrawerEye, setIsDrawerEye] = useState(false);
  const [familyName, setFamilyName] = useState("");
  const [selectedFamilyName, setSelectedFamilyName] = useState("");
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  // const [selectedUsers, setSelectedUsers] = useState([]);
  const [addMemberFamilyDetails, setAddMemberFamilyDetails] = useState(null);
  const [eyeDrawerDetails, setEyeDrawerDetails] = useState(null);
  const [congratsModalOpen, setCongratsModalOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(true);
  const [isAdminChipVisible, setIsAdminChipVisible] = useState(false);
  const [selectedNamesForAddMember, setSelectedNamesForAddMember] = useState(
    []
  );

  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryList, setSearchQueryList] = useState("");

  const [addFormError, setAddFormError] = useState({
    familyName: "",
    usersName: "",
  });

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });

  const handleAddValidation = () => {
    let newAddError = { ...addFormError };
    const familyNameValidation = validationHelper.required(familyName);
    newAddError.familyName = familyNameValidation.message;

    const usersNameValidation = validationHelper.required(selectedUserId);
    newAddError.usersName = usersNameValidation.message;

    setAddFormError(newAddError);

    return familyNameValidation.isValid && usersNameValidation.isValid;
  };
  const dispatch = useDispatch();

  const [getFamilyApiRefetch, { data: familyData = [] }] =
    familyApiAction.getFamilyApi();

  const familyDataDetail = familyData.data;

  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  React.useEffect(() => {
    getFamilyApiRefetch({
      page: paginationModel.page * paginationModel.pageSize,
      pageSize: paginationModel.pageSize,
      searchQuery: debouncedSearchQuery,
    });
  }, [
    paginationModel.page,
    paginationModel.pageSize,
    debouncedSearchQuery,
    getFamilyApiRefetch,
  ]);

  const handlePaginationChange = (newPaginationModel) => {
    setPaginationModel({
      ...paginationModel,
      page: newPaginationModel.page,
      pageSize: newPaginationModel.pageSize,
    });
  };

  const [getFamilyUsersList, { data: familyListing }] =
    familyApiAction.getFamilyUsersList();

  const debouncedSearchQueryList = useDebounce(searchQueryList, 500);

  React.useEffect(() => {
    getFamilyUsersList({
      searchQueryList: debouncedSearchQueryList,
    });
  }, [debouncedSearchQueryList, getFamilyUsersList]);

  const idMatch = addMemberFamilyDetails?.family_details?.map(
    (item) => item.user_id
  );

  const familyListingIds = Array.isArray(familyListing)
    ? familyListing.map((item) => item.user_id)
    : [];

  const commonIds = idMatch?.filter((id) => familyListingIds?.includes(id));

  const [handleNewFamilyApi] = familyApiAction.newFamily();

  const [handleUpdateFamilyApi] = familyApiAction.updateFamily();

  const [handleUpdateAdminApi] = familyApiAction.updateAdmin();

  React.useEffect(() => {
    if (isDrawerEye) {
      let newDetail = familyDataDetail.find((item) => {
        return item.family_id === eyeDrawerDetails.family_id;
      });
      setEyeDrawerDetails(newDetail);
    }
  }, [familyDataDetail]);

  if (!familyDataDetail) {
    return <div>..loading</div>;
  }

  const familyDataDetailArray = Object.values(familyDataDetail);

  const familyDataDetailWithIds = familyDataDetailArray.map((item, index) => ({
    ...item,
    id: index + 1,
  }));

  const handleFamilyNameChange = (e) => {
    const { value } = e.target;
    setFamilyName(value);
  };

  const handlePreviewSave = () => {
    setCongratsModalOpen(true);
  };

  const AddFamilyDrawer = () => {
    setDrawerAddFamily(true);
    setSelectedFamilyName("");
  };
  const addMemberDrawer = (familyDetails) => {
    setAddMemberFamilyDetails(familyDetails);
    setIsDrawerAddMember(true);
    setSelectedNamesForAddMember(
      familyDetails.family_details?.map((member) => member.user_id)
    );
    setFormData((prevState) => ({
      ...prevState,
      selectedHighRiskCustomers: [
        ...prevState.selectedHighRiskCustomers,
        ...familyDetails.family_details,
      ],
    }));
  };


  const eyeDrawer = (familyDetails) => {
    setEyeDrawerDetails(familyDetails);
    setIsDrawerEye(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerEye(false);
  };

  const handleAction = () => {
    setIsAdmin(!isAdmin);
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchInputChangeList = (event) => {
    setSearchQueryList(event.target.value);
  };

  const filteredfamilyDataDetail = familyDataDetailWithIds.filter((item) => {
    return item?.name?.toLowerCase()?.includes(searchQuery.toLowerCase());
  });

  const handleFormChange = ({ name, value }) => {
    let newFormData = { ...formData };
    newFormData[name] = value;
    setFormData(newFormData);

    if (name === "selectedHighRiskCustomers") {
      setSelectedUserId(value);
    }
  };

  const filteredHighRiskCustomers = formData.highRiskSearch
    ? familyListing?.filter((item) => {
        if (item.name.match(formData.highRiskSearch)) {
          return {
            ...item,
            value: item.user_id,
            label: item.name,
          };
        }
      })
    : familyListing;

  const members = [
    {
      name: "Jay Shah",
      role: "Admin",
      email: "jayshah123@gmail.com",
      phone: "+91 9876543212",
      actionColor: "#EB5757",
      actionText: "Remove admin",
    },
    {
      name: "Jay Shah",
      role: "Admin",
      email: "jayshah123@gmail.com",
      phone: "+91 9876543212",
      actionColor: "#EB5757",
      actionText: "Remove admin",
    },
  ];

  const FamilyMemberHeader = () => {
    const data = familyDataDetail;
    return (
      <>
        <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Text
                sx={{ color: "primary", mr: 1, fontWeight: 500 }}
                color="primary"
                variant="button"
              >
                Family Members{" "}
              </Text>

              <Box
                sx={{
                  borderRadius: "16px",
                  backgroundColor: "#E5EEFF",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100px",
                  height: "25px",
                }}
              >
                <Text sx={{ fontSize: "14px" }}>
                  {familyData?.familiesRecords} families
                </Text>
              </Box>
            </Stack>
            <Text variant="small" sx={{ color: "#667085" }}>
              Here you can create families and add members to it.
            </Text>
          </Box>

          <Box>
            <Button sx={{ padding: "10px 16px" }} onClick={AddFamilyDrawer}>
              + Create new family
            </Button>
          </Box>
        </Box>
        <Box
          style={{ width: "500px", marginTop: "10px", marginBottom: "16px " }}
        >
          <Stack direction="row">
            <TextField
              sx={{ maxWidth: "500px", ml: 2 }}
              placeholder="Search"
              name="search"
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
            <SearchIcon
              style={{
                position: "relative",
                left: "-42px",
                marginTop: "12px",
                color: "#676C76",
              }}
            />
          </Stack>
        </Box>
      </>
    );
  };

  const handleFamilySubmit = async () => {
    const userIds = selectedUserId?.map((item) => item.user_id);

    if (!handleAddValidation()) {
      return;
    }

    if (selectedUserId !== null) {
      const payload = {
        name: familyName,
        users: userIds,
      };

      try {
        let res = await handleNewFamilyApi(payload);

        if (res) {
          const toastMessage =
            res.data.status === 1 ? "Family Added Successfully" : res.message;

          dispatch(
            toastActions.setToastData({
              message: toastMessage,
              variant: res.data.status === 1 ? "success" : "error",
            })
          );
        }

        if (res.data.status === 1) {
          setSelectedFamilyName(res.data.name);
          getFamilyApiRefetch({
            page: paginationModel.page * paginationModel.pageSize,
            pageSize: paginationModel.pageSize,
          });
          setDrawerAddFamily(false);

          setFormData((prevState) => ({
            ...prevState,
            selectedHighRiskCustomers: [],
          }));
        }
      } catch (error) {
        console.error("Error in API call:", error);
        console.log("Complete error object:", error);
      }
    } else {
      console.error("Selected user ID is null");
    }
  };

  const handleAddMember = async () => {
    const userIds = selectedUserId?.map((item) => item.user_id);

    setAddMemberFamilyDetails(addMemberFamilyDetails);

    if (selectedUserId !== null) {
      const payload = {
        name: addMemberFamilyDetails?.name,
        familyId: addMemberFamilyDetails?.family_id,
        users: userIds,
      };

      try {
        let res = await handleUpdateFamilyApi(payload);
        if (res) {
          const toastMessage =
            res.data.status === 1
              ? "Family Members Added Successfully"
              : res.message;

          dispatch(
            toastActions.setToastData({
              message: toastMessage,
              variant: res.data.status === 1 ? "success" : "error",
            })
          );
        }

        if (res.data.status === 1) {
          setSelectedFamilyName(res.data.name);
          getFamilyApiRefetch({
            page: paginationModel.page * paginationModel.pageSize,
            pageSize: paginationModel.pageSize,
          });
          getFamilyUsersList();
          setIsDrawerAddMember(false);

          setFormData((prevState) => ({
            ...prevState,
            selectedHighRiskCustomers: [],
          }));
        }
      } catch (error) {
        console.error("Error in API call:", error);
        console.log("Complete error object:", error);
      }
    } else {
      console.error("Selected user ID is null");
    }
  };

  const handleUpdateAdmin = async (member) => {
    const payload = {
      isAdmin: member.is_admin ? 0 : 1,
      familyId: eyeDrawerDetails?.family_id,
      userId: member.user_id,
    };

    try {
      let res = await handleUpdateAdminApi(payload);

      if (res) {
        const toastMessage =
          res.data.status === 1 ? "Admin Updated Successfully" : res.message;

        dispatch(
          toastActions.setToastData({
            message: toastMessage,
            variant: res.data.status === 1 ? "success" : "error",
          })
        );
      }

      if (res.data.status === 1) {
        setSelectedFamilyName(res.data.name);
        getFamilyApiRefetch({
          page: paginationModel.page * paginationModel.pageSize,
          pageSize: paginationModel.pageSize,
        });
        setIsAdminChipVisible(!member.is_admin);
      }
    } catch (error) {
      console.error("Error in API call:", error);
      console.log("Complete error object:", error);
    }
  };

  const FamilyMemberColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "family_details",
      headerName: "Members Name",
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.family_details?.slice(0, 3).map((member, index) => (
            <Chip
              key={member.user_id}
              label={member.name}
              sx={{ backgroundColor: "#E5EEFF", color: "#142E56", mr: "5px" }}
            />
          ))}
          {params?.row?.family_details?.length > 3 && (
            <Chip
              label={`+${params?.row?.family_details?.length - 3}`}
              sx={{ backgroundColor: "#E5EEFF", color: "#142E56", mr: "5px" }}
            />
          )}
        </>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      renderCell: (params) => (
        <Stack direction="row" alignItems="center">
          <IconButton
            onClick={() => {
              eyeDrawer(params.row);
            }}
          >
            <EyeIcon
              sx={{ width: "20px", height: "20px" }}
              onClick={() => {
                eyeDrawer(params.row);
              }}
            />
          </IconButton>

          <Button
            variant="outlined"
            onClick={() => {
              addMemberDrawer(params.row);
            }}
            sx={{ fontSize: "14px", fontWeight: 500, ml: "20px" }}
          >
            Add/Remove
          </Button>
        </Stack>
      ),
      flex: 1,
    },
  ];

  const totalCount = familyDataDetail?.length;

  return (
    <>
      <Box sx={{ mt: "24px" }}>
        <CustomGrid
          autoHeight
          list={filteredfamilyDataDetail}
          columnHeaderHeight={72}
          rowHeight={72}
          columns={FamilyMemberColumns}
          pagination
          header={FamilyMemberHeader}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={handlePaginationChange}
          rowCount={familyData?.familiesRecords}
        />
      </Box>

      <SideDrawer
        open={isDrawerAddFamily}
        closeDrawer={() => setDrawerAddFamily(false)}
        title="Create New Family"
        subtitle="Here you can create new family by adding details"
        cancelButtonText="Cancel"
        submitButtonText="Save"
        handleSubmit={handleFamilySubmit}
      >
        <Box>
          <Text sx={{ fontSize: "14px", fontWeight: 500 }}>Family name</Text>
          <TextField
            placeholder="Enter family name "
            value={familyName}
            onChange={handleFamilyNameChange}
          />
          {addFormError.familyName ? (
            <span style={{ color: "red" }}>{addFormError.familyName}</span>
          ) : null}
        </Box>

        <Box>
          <Text sx={{ fontSize: "14px", fontWeight: 500 }}>Users</Text>

          <MultiSelect
            onChange={(e) => {
              handleFormChange({
                name: "selectedHighRiskCustomers",
                value: e.target.value,
              });
            }}
            onClose={() => {
              handleFormChange({
                name: "highRiskSearch",
                value: "",
              });
            }}
            onSearchChange={handleSearchInputChangeList}
            options={filteredHighRiskCustomers}
            value={formData.selectedHighRiskCustomers}
            idKeyName="user_id"
          />

          {addFormError.usersName ? (
            <span style={{ color: "red" }}>{addFormError.usersName}</span>
          ) : null}
        </Box>
      </SideDrawer>

      <SideDrawer
        open={isDrawerAddMember}
        closeDrawer={() => {
          setIsDrawerAddMember(false);
          setFormData({ selectedHighRiskCustomers: [] });
        }}
        title="Add member"
        subtitle="Here you can add member."
        cancelButtonText="Cancel"
        submitButtonText="Save"
        handleSubmit={handleAddMember}
      >
        <Box>
          <Box sx={{ display: "flex", mb: "20px" }}>
            <Text
              sx={{
                color: "#676C76",
                fontWeight: 400,
                fontSize: "16px",
                display: "flex",
              }}
            >
              Family name <Text sx={{ ml: "20px", mr: "20px" }}>:</Text>{" "}
            </Text>
            <Text sx={{ color: "#242424", fontSize: "16px", fontWeight: 500 }}>
              {addMemberFamilyDetails?.name ?? "Select a family"}
            </Text>
          </Box>

          <Box sx={{}}>
            <Text
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                color: "#242424",
                mb: "6px",
              }}
            >
              User
            </Text>

            <MultiSelect
              onChange={(e) => {
                handleFormChange({
                  name: "selectedHighRiskCustomers",
                  value: e.target.value,
                });
              }}
              onClose={() => {
                handleFormChange({
                  name: "highRiskSearch",
                  value: "",
                });
              }}
              onSearchChange={handleSearchInputChangeList}
              options={filteredHighRiskCustomers}
              value={formData.selectedHighRiskCustomers}
              getOptionLabel={(option) => option.label} // Use name as label
              getOptionValue={(option) => option.value} // Use user_id as value
            />
          </Box>
        </Box>
      </SideDrawer>

      <SideDrawer
        open={isDrawerEye}
        closeDrawer={handleDrawerClose}
        title={eyeDrawerDetails ? eyeDrawerDetails.name : "Family Details"}
        subtitle="Here you can see all the details of the members in this family"
        handleSubmit={handleDrawerClose}
        submitButtonText="Done"
        showPrimaryButton={true}
        showSecondaryButton={false}
      >
        {eyeDrawerDetails?.family_details?.map((member, index) => (
          <React.Fragment key={index}>
            <Box>
              <Box>
                <Stack direction="row">
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Text sx={{ fontSize: "16px", color: "#676C76" }}>
                      Name
                    </Text>
                    <Text sx={{ ml: 2.5 }}>:</Text>
                    <Text
                      sx={{ fontSize: "16px", color: "#242424", width: "50%" }}
                    >
                      {member?.name ?? "durgesh"}
                    </Text>
                  </Box>

                  {member?.is_admin === 1 ? (
                    <>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Text>
                          <Chip
                            label="Admin"
                            size="small"
                            sx={{
                              fontSize: "14px",
                              color: "#142E56",
                              backgroundColor: "#E5EEFF",
                            }}
                          />
                        </Text>
                        <Box>
                          <Link
                            href="#"
                            onClick={() => {
                              handleUpdateAdmin(member, addMemberFamilyDetails);
                            }}
                            sx={{
                              color: "red",
                              fontSize: "16px",
                              fontWeight: 600,
                              // color: "#1A54B9",
                            }}
                          >
                            {console.log("member : ", member)}
                            Remove admin
                            {/* {member.isAdmin ? "Remove admin" : "Make Admin"} */}
                          </Link>
                        </Box>
                      </Box>
                    </>
                  ) : (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text></Text>
                      <Link
                        href="#"
                        onClick={() => {
                          handleUpdateAdmin(member, addMemberFamilyDetails);
                        }}
                        sx={{
                          color: "#1A54B9",
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "#1A54B9",
                        }}
                      >
                        {console.log("member : ", member)}
                        {member.isAdmin ? "Remove admin" : "Make Admin"}
                      </Link>
                    </Box>
                  )}
                </Stack>
              </Box>

              <Box sx={{ display: "flex", mt: "12px" }}>
                <Text sx={{ fontSize: "16px", color: "#676C76" }}>
                  Email <span style={{ marginLeft: "42px" }}>:</span>
                </Text>
                <Text sx={{ fontSize: "16px", color: "#242424", ml: "20px" }}>
                  {member.email}
                </Text>
              </Box>

              <Box sx={{ display: "flex", mt: "12px" }}>
                <Text sx={{ fontSize: "16px", color: "#676C76" }}>
                  Phone <span style={{ marginLeft: "36px" }}>:</span>
                </Text>
                <Text sx={{ fontSize: "16px", color: "#242424", ml: "20px" }}>
                  {member.mobile}
                </Text>
              </Box>
            </Box>

            <Divider />
          </React.Fragment>
        ))}
      </SideDrawer>

      <NewDialog
        onClose={() => setCongratsModalOpen(false)}
        open={congratsModalOpen}
        disableCloseIcon
        maxWidth="sm"
        contentComponent={() => (
          <Stack
            direction="column"
            spacing={1.5}
            sx={{ width: "100%" }}
            alignItems="center"
            justifyContent="center"
            style={{ marginTop: "10px" }}
          >
            {/* <CheckCircleOutlinedIcon /> */}
            <AlertIcon sx={{ width: "58px", height: "58px" }} />
            <Text
              fontSize="20px"
              fontWeight="600"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              Are you sure you want to remove this user?
            </Text>
            <Text
              width="500px"
              fontSize="14px"
              fontWeight="400"
              color="#667085"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              textAlign="center"
            >
              By clicking on yes you are making sure that you want to remove
              this user permanently from this family member.
            </Text>

            <Button
              sx={{
                width: "80%",
                height: "56px",
                fontWeight: 600,
                borderRadius: "8px",
                p: "18px 20px",
                mt: "32px",
              }}
            >
              Okay
            </Button>
          </Stack>
        )}
      />
    </>
  );
}

export default FamilyMembersContainer;
