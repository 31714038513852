import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";

const chartSetting = {
  width: 400,
  height: 300,
};

const valueFormatter = (value) => `${value}`;

export default function AlgoCustomHorizontalBar({ investmentChart }) {
  // Check if investmentChart is valid
  if (!Array.isArray(investmentChart) || investmentChart.length === 0) {
    return <div>No data to display</div>;
  }

  // Create datasets for the BarChart - separate arrays for amount and profit
  const amountData = investmentChart?.map((item) => item.amount || 0); // Data for amount
  const profitData = investmentChart?.map((item) => item.profit || 0);  // Data for profit
  // You can also map the labels dynamically if needed
  const labels = investmentChart?.map((item, index) => ``); // Labels for the Y-axis in horizontal layout
  return (
    <BarChart
      xAxis={[
        {
          label: `Amount in ₹ ${investmentChart?.[0]?.graph_amount}`, // X-axis represents the values now
        },
      ]}
      yAxis={[
        {
          data: labels, // Y-axis labels (entries in horizontal layout)
          scaleType: "band", // Set the y-axis scale to "band" for horizontal bar chart
          // label: "Entries",
        },
      ]}
      series={[
        {
          data:amountData ?? [] ,
          valueFormatter,
          barSize: "20px", // Bar size for amount
          color: "#142E56", // Color for amount bar
          label: 'Amount',
        },
        {
          data: profitData ?? [],
          valueFormatter,
          barSize: "20px", // Bar size for profit
          color: "#9FC4FF", // Color for profit bar
          label: 'Profit',
        },
      ]}
      layout="horizontal" // Horizontal layout for bars
      {...chartSetting}
    />
  );
}