import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Chip, styled, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Button from "components/common/Button";
import Box from "components/common/Box";
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import SideDrawer from "components/common/SideDrawer";
import Radio from "components/common/Radio";
import Card from "components/common/Card";
import TextField from "components/common/TextField";
import { tradesApiAction } from "stores/redux/apiSlices/trades/tradesApiSlice";
import OnOffButton from "components/addCustomer/OnOffButton";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";

// Styled Components
const ChipStyle = styled(Chip)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  background: "#EAFFF3",
  borderRadius: "152px",
  color: "#219653",
  fontSize: "12px",
  fontWeight: 500,
  "&::before": {
    position: "relative",
    left: 6,
    bottom: 6,
    content: '"\\2022"',
    width: "6px",
    height: "6px",
    color: "#219653",
  },
}));

const NoActionMsgWraper = styled(Stack)(() => ({
  alignItems: "center",
  justifyContent: "center",
  minHeight: "90px",
  border: "1px solid #d8d8d8",
  borderRadius: "6px",
}));

const TextFieldStyled = styled(TextField)(({ theme }) => ({
  input: {
    height: "8px",
  },
  width: "45px",
}));

// Main Component
const StockTradeDrawer = (props) => {
  const {
    sideDrawerOpen1,
    closeSideDrawer1,
    apiResponse,
    recallDataOnUpdate,
    productId,
  } = props;

  const [selectedRadio, setSelectedRadio] = useState("actionPending");
  // State
  const [handleTradeCallUpdateApi] = tradesApiAction.getTradeCallUpdate();
  const [handlePendingTradeApi] = tradesApiAction.executePendingTrade();
  const [editedQuantities, setEditedQuantities] = useState({});
  const [editedBuyPrices, setEditedBuyPrices] = useState({});
  const [orderType, setOrderType] = useState("LIMIT");
  const [orderTypeCard, setOrderTypeCard] = useState("LIMIT");
  const [initialSelectedStock, setInitialSelectedStock] = useState(null);
  const [formData, setFormData] = useState({
    selectedStock: initialSelectedStock,
    quantity: "",
    buyPrice: "",
    buyPriceCard: "",
  });
  const [editedCardIndex, setEditedCardIndex] = useState(null);
  const [orderTypes, setOrderTypes] = useState([]);
  const [originalQuantities, setOriginalQuantities] = useState({});
  const [originalBuyPrices, setOriginalBuyPrices] = useState({});

  useEffect(() => {
    if (apiResponse && apiResponse.data && apiResponse.data.length > 0) {
      setInitialSelectedStock(apiResponse.data[0].stock_name);
      setFormData((prevData) => ({
        ...prevData,
        selectedStock: apiResponse.data[0].stock_name,
      }));
    }
  }, [apiResponse]);

  useEffect(() => {
    if (apiResponse && apiResponse.data) {
      // Initialize orderTypes array with "LIMIT" for each item in apiResponse data
      setOrderTypes(Array(apiResponse.data.length).fill("LIMIT"));
    }
  }, [apiResponse]);

  useEffect(() => {
    if (apiResponse && apiResponse.data) {
      const initialQuantities = {};
      const initialBuyPrices = {};
      apiResponse.data.forEach((item, index) => {
        initialQuantities[index] = item.quantity;
        initialBuyPrices[index] = item.buy_price;
      });
      setOriginalQuantities(initialQuantities);
      setOriginalBuyPrices(initialBuyPrices);
    }
  }, [apiResponse]);

  // useEffect for updating editedQuantities and editedBuyPrices
  useEffect(() => {
    if (apiResponse && apiResponse.data) {
      const initialQuantities = {};
      const initialBuyPrices = {};
      apiResponse.data.forEach((item, index) => {
        initialQuantities[index] = item.quantity;
        initialBuyPrices[index] = item.buy_price;
      });
      setEditedQuantities(initialQuantities);
      setEditedBuyPrices(initialBuyPrices);
    }
  }, [apiResponse]);

  useEffect(() => {
    if (!sideDrawerOpen1) {
      setInitialSelectedStock(null);
      setFormData({
        selectedStock: null,
        quantity: "",
        buyPrice: "",
        buyPriceCard: "",
      });
      setEditedQuantities({});
      setEditedBuyPrices({});
      setOrderType("LIMIT");
      setOrderTypeCard("LIMIT");
      setEditedCardIndex(null);
      setOrderTypes([]);
      setOriginalQuantities({});
      setOriginalBuyPrices({});
    }
  }, [sideDrawerOpen1]);

  // Close drawer function
  const closeTradeDrawer = () => {
    closeSideDrawer1();
  };
  // Handle radio button change

  // Handle input change for form fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle quantity change
  const handleQuantityChange = (event, index) => {
    const { value } = event.target;
    setEditedQuantities((prevState) => ({
      ...prevState,
      [index]: value,
    }));
  };

  // Handle buy price change
  const handleBuyPriceChange = (event, index) => {
    const { value } = event.target;
    setEditedBuyPrices((prevState) => ({
      ...prevState,
      [index]: value,
    }));
    // Update formData.buyPrice when the user edits the buy price
    setFormData((prevFormData) => ({
      ...prevFormData,
      buyPrice: value,
    }));
  };

  const handleBuyPriceChangeCard = (event, index) => {
    const { value } = event.target;
    setEditedBuyPrices((prevState) => ({
      ...prevState,
      [index]: value,
    }));
    // Update formData.buyPrice when the user edits the buy price
    setFormData((prevFormData) => ({
      ...prevFormData,
      buyPriceCard: value,
    }));
  };

  // Handle save changes for each item
  const handleSaveChanges = async (item, index) => {
    const payload = {
      productId: productId,
      transaction_id: item.transaction_id,
      exchange: item.exchange,
      stock_name: item.stock_name,
      quantity:
        editedQuantities[index] !== undefined
          ? editedQuantities[index]
          : item.quantity || 0,
      price:
        editedBuyPrices[index] !== undefined
          ? editedBuyPrices[index]
          : item.buy_price || 0,
      order_type: orderTypes[index],
      // Add other properties from the item as needed
    };
    try {
      const response = await handleTradeCallUpdateApi(payload);
      recallDataOnUpdate({ tradeId: item.trade_id });
      setFormData({
        ...formData,
        buyPriceCard: "",
      });
      setOrderTypeCard("LIMIT");
      console.log("API Response:", response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleOrderTypeChange = (index) => {
    // Create a copy of the order types array
    const newOrderTypes = [...orderTypes];
    // Toggle the order type for the specific card index
    newOrderTypes[index] =
      newOrderTypes[index] === "LIMIT" ? "MARKET" : "LIMIT";
    // Update the state with the modified order types array
    setOrderTypes(newOrderTypes);

    // If the new order type is "MARKET", set the buy price for the specific index to an empty string
    if (newOrderTypes[index] === "MARKET") {
      setEditedBuyPrices((prevState) => ({
        ...prevState,
        [index]: "",
      }));
    }
  };

  const handleOrderTypeChangeCard = (event) => {
    const newOrderType = event.target.checked ? "LIMIT" : "MARKET";
    setOrderType(newOrderType);
  };

  // Handle save new changes
  const handleSaveNewChanges = async () => {
    const payload = {
      trade_id: apiResponse?.data[0]?.trade_id,
      stock_name: formData.selectedStock,
      quantity: formData.quantity,
      price: orderType === "MARKET" ? null : formData.buyPrice,
      exchange: apiResponse?.data[0]?.exchange,
      action: apiResponse?.data[0]?.action,
      order_type: orderType,
    };
    try {
      const response = await handlePendingTradeApi(payload);
      recallDataOnUpdate({ tradeId: apiResponse?.data[0]?.trade_id });
      setFormData({
        ...formData,
        buyPrice: "",
        quantity: "",
      });
      setOrderType("LIMIT");
      console.log("API Response:", response);
      s;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Filtered data based on radio selection
  const filteredData = apiResponse?.data?.filter((item) => {
    if (selectedRadio === "actionTaken") {
      return item.status === "COMPLETE" || item.status === "REJECTED";
    } else if (selectedRadio === "actionPending") {
      return item.status === "PENDING";
    }
  });
  const handleRadioChange = (value) => {
    setSelectedRadio(value);
  };

  const handleEditIconClick = (index) => {
    // Set the index of the card being edited
    setEditedCardIndex(index);

    setEditedQuantities({
      ...editedQuantities,
      [index]: originalQuantities[index],
    });
    setEditedBuyPrices({
      ...editedBuyPrices,
      [index]: originalBuyPrices[index],
    });
  };

  const handleCancelClick = () => {
    setEditedCardIndex(null);
    setOrderType("LIMIT");
    setOrderTypes(orderTypes.map(() => "LIMIT"));
    setEditedQuantities(originalQuantities);
    setEditedBuyPrices(originalBuyPrices);
  };

  return (
    <>
      <SideDrawer
        open={sideDrawerOpen1}
        showSecondaryButton={false}
        closeDrawer={closeSideDrawer1}
        title="Stocks for trade"
        submitButtonText="Done"
        handleSubmit={closeTradeDrawer}
        subtitle="Here you can view all the details about the stocks."
        drawerWidth="550"
      >
        <>
          <Text>Trade</Text>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Radio
              onChange={() => handleRadioChange("actionTaken")}
              checked={selectedRadio === "actionTaken"}
            />
            <Text sx={{ ml: "12px", mr: "24px" }}>Action taken</Text>

            <Radio
              onChange={() => handleRadioChange("actionPending")}
              checked={selectedRadio === "actionPending"}
            />
            <Text sx={{ ml: "12px" }}>Action pending</Text>
          </Box>

          {selectedRadio === "actionTaken" && (
            <>
              {filteredData && filteredData.length ? (
                filteredData.map((item, index) => (
                  <Accordion key={index}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{
                        backgroundColor:
                          item.status == "REJECTED" ? "#ffecec" : "",
                      }}
                    >
                      <Text>{item.customer_name}</Text>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack direction="column" spacing={2}>
                        <Card sx={{ p: "12px 16px", height: "150px" }}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Text
                              sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                                mr: "16px",
                              }}
                            >
                              {item.stock_name}
                            </Text>
                            <ChipStyle label="Action Taken" size="small" />
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mt: 4,
                            }}
                          >
                            <Text sx={{ fontSize: "14px", mr: "auto" }}>
                              Quantity : {item.quantity}
                            </Text>
                            <Text sx={{ fontSize: "14px", mr: "auto" }}>
                              Buy price : ₹ {item.buy_price}
                            </Text>
                            <Text sx={{ fontSize: "14px" }}>
                              Invested : ₹ {item.amount_invested}
                            </Text>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              mt: 2,
                            }}
                          >
                            <Text sx={{ fontSize: "14px", mr: "auto" }}>
                              Sell price : ₹ {item.sell_price}
                            </Text>
                            <Text sx={{ fontSize: "14px" }}>
                              Profit : ₹ {item.total_gains}
                            </Text>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mt: 2,
                              p: "2px 12px",
                              backgroundColor: "#F7F8FF",
                              borderRadius: "4px",
                            }}
                          >
                            <Text sx={{ fontSize: "12px", mr: "auto" }}>
                              Date : {item.buy_date}
                            </Text>
                            <Text sx={{ fontSize: "12px", mr: "auto" }}>
                              Buy time : {item.buy_time}
                            </Text>
                            <Text sx={{ fontSize: "12px" }}>
                              Sell time :{item.sell_time}
                            </Text>
                          </Box>
                        </Card>
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                ))
              ) : (
                <NoActionMsgWraper>
                  <Text>None of the customers have taken action yet.</Text>
                </NoActionMsgWraper>
              )}
            </>
          )}

          {selectedRadio === "actionPending" &&
          filteredData &&
          filteredData.length > 0 ? (
            <>
              <>
                <Box>
                  <Card
                    sx={{
                      p: "12px 16px",
                      height: "235px",
                      backgroundColor: "#F7F8FF",
                    }}
                  >
                    <Text
                      sx={{ fontSize: "14px", fontWeight: 500, mr: "16px" }}
                    >
                      Change quantity and price at once for everyone
                    </Text>

                    <Box
                      sx={{ display: "flex", flexDirection: "column", mt: 2 }}
                    >
                      <Text
                        sx={{
                          fontSize: "14px",
                          fontWeight: 400,
                          color: "#676C76",
                          mb: "3px",
                        }}
                      >
                        {}
                      </Text>

                      <TextField
                        select
                        size="small"
                        value={formData.selectedStock}
                        onChange={handleInputChange}
                        name="selectedStock"
                        sx={{ backgroundColor: "#FFF" }}
                      >
                        {filteredData &&
                          Array.from(
                            new Set(filteredData.map((item) => item.stock_name))
                          ).map((stockName, index) => (
                            <MenuItem
                              key={index}
                              value={stockName}
                              selected={stockName === initialSelectedStock}
                            >
                              {stockName}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Box>

                    <Box sx={{ display: "flex" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          mt: 2,
                          width: "50%",
                          mr: 1,
                        }}
                      >
                        <Text
                          sx={{
                            fontSize: "14px",
                            fontWeight: 400,
                            color: "#676C76",
                            mb: "3px",
                          }}
                        >
                          {productId === 3 ? `LOT` : `Quantity`}
                        </Text>
                        <TextField
                          size="small"
                          value={formData.quantity}
                          onChange={handleInputChange}
                          name="quantity"
                          sx={{ backgroundColor: "#FFF" }}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          mt: 2,
                          width: "50%",
                          ml: 1,
                        }}
                      >
                        <Text
                          sx={{
                            fontSize: "14px",
                            fontWeight: 400,
                            color: "#676C76",
                            mb: "3px",
                          }}
                        >
                          Buy price
                        </Text>
                        <TextField
                          size="small"
                          value={
                            orderType === "MARKET" ? "" : formData.buyPrice
                          }
                          onChange={handleBuyPriceChange}
                          name="buyPrice"
                          sx={{ backgroundColor: "#FFF" }}
                          disabled={orderType === "MARKET"}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          mt: 2,
                          width: "50%",
                          ml: 1,
                        }}
                      >
                        <Text
                          sx={{
                            fontSize: "14px",
                            fontWeight: 400,
                            color: "#676C76",
                            mb: "9px",
                          }}
                        >
                          Order Type
                        </Text>
                        <OnOffButton
                          checked={orderType === "LIMIT"}
                          onChange={handleOrderTypeChangeCard}
                          opacity={1}
                          cursor="pointer"
                          onText="Limit"
                          offText="Market"
                        />
                      </Box>
                    </Box>

                    <Button
                      onClick={() => handleSaveNewChanges()}
                      sx={{ width: "100%", mt: "12px" }}
                    >
                      Apply
                    </Button>
                  </Card>
                </Box>
              </>
              {filteredData &&
                filteredData.map((item, index) => (
                  <>
                    <Card
                      key={item.transaction_id}
                      sx={{
                        p: "12px 16px",
                        display: "flex",
                        overflow: "unset",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Text
                            sx={{
                              fontSize: "16px",
                              fontWeight: 500,
                              mr: "16px",
                            }}
                          >
                            {item.customer_name}
                          </Text>
                          <ChipStyle
                            sx={{
                              background:
                                item.status === "PENDING"
                                  ? "#FFEDD2"
                                  : "#EAFFF3",
                              color:
                                item.status === "PENDING"
                                  ? "#7A4A02"
                                  : "#219653",
                            }}
                            label={
                              item.status === "PENDING"
                                ? "Action pending"
                                : "Action taken"
                            }
                            size="small"
                          />
                        </Box>

                        <Box sx={{ display: "flex" }}>
                          <Box>
                            <Text
                              sx={{
                                fontSize: "14px",
                                fontWeight: 400,
                                color: "#676C76",
                                mb: "9px",
                              }}
                            >
                              Order Type
                            </Text>
                            <OnOffButton
                              checked={orderTypes[index] === "LIMIT"}
                              onChange={() => handleOrderTypeChange(index)}
                              opacity={1}
                              cursor="pointer"
                              onText="Limit"
                              offText="Market"
                              disabled={editedCardIndex !== index}
                            />
                          </Box>

                          {!editedCardIndex === undefined ||
                          editedCardIndex !== index ? (
                            <Box>
                              <IconButton
                                onClick={() => handleEditIconClick(index)}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </Box>
                          ) : null}
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 3,
                          mb: 3,
                        }}
                      >
                        <Text sx={{ fontSize: "14px", mr: 1 }}>
                          {productId === 3 ? `LOT :` : ` Lot Size :`}
                        </Text>
                        <TextFieldStyled
                          size="small"
                          value={
                            editedCardIndex === index
                              ? editedQuantities[index] !== undefined
                                ? editedQuantities[index]
                                : item.quantity
                              : item.quantity // Use the original value if not being edited
                          }
                          onChange={(event) =>
                            handleQuantityChange(event, index)
                          }
                          disabled={editedCardIndex !== index}
                        />
                        <Text sx={{ fontSize: "14px", mr: 1, ml: 3 }}>
                          Buy price :
                        </Text>
                        <TextFieldStyled
                          size="small"
                          value={
                            editedCardIndex === index
                              ? editedBuyPrices[index] !== undefined
                                ? editedBuyPrices[index]
                                : item.buy_price
                              : item.buy_price // Use the original value if not being edited
                          }
                          onChange={(event) =>
                            handleBuyPriceChangeCard(event, index)
                          }
                          sx={{ width: "55px" }}
                          disabled={
                            editedCardIndex !== index ||
                            orderTypes[index] === "MARKET"
                          }
                        />
                        <Text sx={{ fontSize: "14px", ml: 3 }}>
                          Invested : ₹{" "}
                          {item.amount_invested ? item.amount_invested : "NA"}
                        </Text>
                      </Box>

                      {(!editedCardIndex === undefined ||
                        editedCardIndex === index) && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Button
                            sx={{ width: "45%" }}
                            disabled={editedCardIndex !== index}
                            onClick={handleCancelClick}
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={() => handleSaveChanges(item, index)}
                            variant="contained"
                            color="primary"
                            sx={{ width: "45%" }}
                            disabled={editedCardIndex !== index}
                          >
                            Save Changes
                          </Button>
                        </Box>
                      )}
                    </Card>
                  </>
                ))}
            </>
          ) : (
            <Box></Box>
          )}
          {selectedRadio === "actionPending" &&
          filteredData &&
          filteredData.length == 0 ? (
            <Box sx={{ position: "relative" }}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  textAlign: "center",
                  backdropFilter: "blur", // Reduced blur effect for the background
                  backgroundColor: "rgba(255, 255, 255, 0.5)", // Semi-transparent white background for contrast
                  borderRadius: "8px", // Optional: Add border radius for better appearance
                  padding: "16px", // Optional: Add padding for spacing
                  zIndex: 1, // Ensure it's above the blurred background
                }}
              >
                <Text sx={{ color: "#FF0000" }}>No action pending</Text>
              </Box>

              <Box sx={{ filter: "blur(2px)" }}>
                <Card
                  sx={{
                    p: "12px 16px",
                    height: "235px",
                    backgroundColor: "rgba(255, 255, 255, 0.5)",
                  }}
                >
                  <Text sx={{ fontSize: "14px", fontWeight: 500, mr: "16px" }}>
                    Change quantity and price at once for everyone
                  </Text>

                  <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
                    <Text
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                        color: "#676C76",
                        mb: "3px",
                      }}
                    >
                      {}
                    </Text>

                    <TextField
                      select
                      size="small"
                      disabled
                      name="selectedStock"
                      sx={{ backgroundColor: "#FFF" }}
                    ></TextField>
                  </Box>

                  <Box sx={{ display: "flex" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        mt: 2,
                        width: "50%",
                        mr: 1,
                      }}
                    >
                      <Text
                        sx={{
                          fontSize: "14px",
                          fontWeight: 400,
                          color: "#676C76",
                          mb: "3px",
                        }}
                      >
                        Quantity
                      </Text>
                      <TextField
                        size="small"
                        name="quantity"
                        disabled
                        sx={{ backgroundColor: "#FFF" }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        mt: 2,
                        width: "50%",
                        ml: 1,
                      }}
                    >
                      <Text
                        sx={{
                          fontSize: "14px",
                          fontWeight: 400,
                          color: "#676C76",
                          mb: "3px",
                        }}
                      >
                        Buy price
                      </Text>
                      <TextField
                        size="small"
                        name="buyPrice"
                        disabled
                        sx={{ backgroundColor: "#FFF" }}
                      />
                    </Box>
                  </Box>

                  <Button sx={{ width: "100%", mt: "12px" }}>Apply</Button>
                </Card>
              </Box>
            </Box>
          ) : (
            <Box></Box>
          )}
        </>
      </SideDrawer>
    </>
  );
};

export default StockTradeDrawer;
