import Dialog from "components/common/Dialog";
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import Image from "components/common/Image";
import Button from "components/common/Button";
import { useEffect, useState } from "react";
import { tradesApiAction } from "stores/redux/apiSlices/trades/tradesApiSlice";
import useDebounce from "hooks/Debounce";
import Box from "components/common/Box";
import Radio from "components/common/Radio";
import TextField from "components/common/TextField";
import { Autocomplete, styled } from "@mui/material";

//Styled Component
const TextStyled = styled(Text)(({ theme }) => ({
  fontSize: "14px",
  color: "#242424",
  fontWeight: 500,
  marginTop: "20px",
  marginBottom: "14px",
}));

const RiskProfileModal = (props) => {
  const {
    isOpen = false,
    handleClose = () => {},
    // handleRiskProfileClick = () => {},
    // selectedRiskProfile = null,
    // proceedToImportTrade = () => {},
    backToPreviewTable,
    prefilledData,
    PRODUCT_ID,
    setImportTradeCurrentSteps,
    ImportTradeSteps,
    setPreviewModalOpen,
    setImportTradeResponse,
  } = props;

  // State to keep track of the selected risk profile
  const [selectedProfile, setSelectedProfile] = useState([1]);

  //useState
  const [formData, setFormData] = useState({
    stockName: null,
    index: "NSE",
  });

  //Api's
  const [
    getAllStockName,
    { data: companyData, isFetching: StockListisFetching },
  ] = tradesApiAction.getStockListApi();
  const [
    getAllStockNameFno,
    { data: companyDataFno, isFetching: StockListFnoisFetching },
  ] = tradesApiAction.getStockListFnoApi();

  const [proceedToImportTradeApi] = tradesApiAction.proceedToImportTrade();


  const [formDataSearch, setFormDataSearch] = useState("");

  const debouncedSearchValue = useDebounce(formDataSearch, 10);

  //useEffects

  useEffect(() => {
    if (debouncedSearchValue.length >= 3) {
      let payload = {
        search: debouncedSearchValue,
        exchange: formData.index,
        noGlobalLoading: true,
      };
      if (PRODUCT_ID == 3) {
        getAllStockNameFno(payload);
      } else {
        getAllStockName(payload);
      }
    }
  }, [debouncedSearchValue, formData.index]);

  //Api's Function
  const proceedToImportTrade = async (
    riskProfile = [],
    importedChangedData
  ) => {
    try {
      const payload = {
        productId: PRODUCT_ID,
        stockName: formData.stockName,
        index: formData.index,
      };
  
      if (PRODUCT_ID === 1) {
        payload.riskProfile = riskProfile;
      }
  
      const res = await proceedToImportTradeApi(payload);
      // Store the response in state
      setImportTradeResponse(res.data);
  
      if (res && res.data && res.data.length) {
        setPreviewModalOpen(false);
        // setImportTradeCurrentSteps(ImportTradeSteps.IMPORT_FILE_MODAL);
      } else {
        // alert no data found.
      }
    } catch (e) {
      console.log("Error ProceedToImportTrade: ", e);
    }
  };
  
  // Function to handle risk profile click
  const handleClick = (profile) => {
    const newProfile = [...selectedProfile];
    const hasProfile = newProfile.findIndex((p) => p === profile);
    if (hasProfile === -1) {
      newProfile.push(profile);
    } else {
      newProfile.splice(hasProfile, 1);
    }
    setSelectedProfile(newProfile); // Update the selected profile state
    // handleRiskProfileClick(profile); // Pass the selected profile value to the container
  };

  //Handler Methods
  const handleFormChange = ({ name, value }) => {
    let newFormData = { ...formData };
    if (name == "index") {
      setFormDataSearch("");
    }

    newFormData[name] = value;
    setFormData(newFormData);
  };

  const stockNameList = PRODUCT_ID == 3 ? companyDataFno : companyData;

  return (
    <Dialog
      onClose={() => handleClose(false)}
      open={isOpen}
      enableBackdropClick={false}
      title={
        <Text
          fontSize="20px"
          fontWeight="600"
          marginBottom="20px"
          marginTop="20px"
          marginLeft="30px"
        >
          Select one risk profile of the customers to whom the following trade
          will apply
        </Text>
      }
      disableCloseIcon
      maxWidth="lg"
      contentComponent={() => (
        <>
          {" "}
          <Box>
            <TextStyled>Index</TextStyled>
            <Box sx={{ display: "flex", gap: "24px", mb: "22px" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Radio
                  checked={formData.index === "NSE"}
                  onChange={() =>
                    handleFormChange({
                      name: "index",
                      value: "NSE",
                    })
                  }
                />
                <Text sx={{ fontSize: "16px", fontWeight: 400, ml: "12px" }}>
                  NSE
                </Text>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Radio
                  checked={formData.index === "BSE"}
                  onChange={() =>
                    handleFormChange({
                      name: "index",
                      value: "BSE",
                    })
                  }
                />
                <Text sx={{ fontSize: "16px", fontWeight: 400, ml: "12px" }}>
                  BSE
                </Text>
              </Box>
            </Box>

            <Text
              sx={{
                mt: "24px",
                mb: "6px",
                fontSize: "14px",
                fontWeight: 500,
                color: "#242424",
              }}
            >
              Stock name
            </Text>

            {Array.isArray(prefilledData) && prefilledData.length > 0 ? (
              <TextField
                value={formData.stockName || ""}
                disabled
                placeholder="Stock name is prefilled"
              />
            ) : (
              <Autocomplete
                sx={{ width: "100%", mb: "30px" }}
                placeholder="Enter stock name"
                value={
                  Array.isArray(stockNameList)
                    ? stockNameList.find(
                        (option) => option.symbol === formData.stockName
                      ) || null
                    : null
                }
                onChange={(event, value) => {
                  setFormData({
                    ...formData,
                    stockName: value ? value.symbol : "",
                  });
                }}
                loading={StockListisFetching || StockListFnoisFetching}
                loadingText={"loading..."}
                disablePortal
                options={stockNameList || []}
                getOptionLabel={(option) => option.symbol || ""}
                renderInput={(params) => (
                  <TextField
                    onChange={(event) => {
                      setFormDataSearch(event.target.value);
                    }}
                    value={formDataSearch}
                    {...params}
                    placeholder="Enter a Stock Name"
                  />
                )}
              />
            )}
          </Box>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {/* Card 1 */}
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              onClick={() => handleClick(1)} // Pass "aggressive" when clicked
              sx={{
                border: "1px solid #ccc",
                borderRadius: "8px",
                padding: "50px",
                width: "250px",
                height: "60px",
                cursor: "pointer",
                backgroundColor: selectedProfile.includes(1)
                  ? "#e5e8fc"
                  : "transparent",
              }}
            >
              <Image src="/images/aggressive.svg" />
              <Text fontSize="20px" fontWeight="500" color="red" sx={{ mt: 2 }}>
                Aggressive risk profile
              </Text>
            </Stack>

            {/* Card 2 */}
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              onClick={() => handleClick(2)} // Pass "moderate" when clicked
              sx={{
                border: "1px solid #ccc",
                borderRadius: "8px",
                padding: "50px",
                width: "250px",
                height: "60px",
                cursor: "pointer",
                backgroundColor: selectedProfile.includes(2)
                  ? "#e5e8fc"
                  : "transparent",
              }}
            >
              <Image src="/images/moderate.svg" />
              <Text
                fontSize="20px"
                fontWeight="500"
                color="orange"
                sx={{ mt: 2 }}
              >
                Moderate risk profile
              </Text>
            </Stack>

            {/* Card 3 */}
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              onClick={() => handleClick(3)} // Pass "conservative" when clicked
              sx={{
                border: "1px solid #ccc",
                borderRadius: "8px",
                padding: "50px",
                width: "250px",
                height: "60px",
                cursor: "pointer",
                backgroundColor: selectedProfile.includes(3)
                  ? "#e5e8fc"
                  : "transparent",
              }}
            >
              <Image src="/images/conservative.svg" />
              <Text
                fontSize="20px"
                fontWeight="500"
                color="green"
                sx={{ mt: 2 }}
              >
                Conservative risk profile
              </Text>
            </Stack>
          </Stack>
        </>
      )}
      actionComponent={() => (
        <Stack
          alignItems="flex-end"
          sx={{
            flexFlow: "row nowrap",
            justifyContent: "end",
            alignItems: "center",
            gap: "20px",
            mt: "25px",
            mb: "25px",
          }}
        >
          {/* <Button
            onClick={backToPreviewTable}
            variant="outlined"
            sx={{
              fontWeight: 600,
              borderRadius: "8px",
              fontSize: "14px",
              p: "14px 28px",
            }}
          >
            Previous
          </Button> */}
          <Button
            sx={{
              fontWeight: 600,
              borderRadius: "8px",
              fontSize: "14px",
              p: "14px 28px",
              mr: 6.5,
            }}
            onClick={() => proceedToImportTrade(selectedProfile)}
            disabled={!selectedProfile.length}
          >
            Proceed to next step
          </Button>
        </Stack>
      )}
    />
  );
};

export default RiskProfileModal;
