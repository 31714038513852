import React, { useEffect, useState } from "react";
import { makeStyles, createStyles } from "@mui/styles";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Stack from "components/common/Stack";
import AppBar from "components/common/AppBar";
import ToolBar from "components/common/ToolBar";
import { styled } from "@mui/system";
import Button from "components/common/Button";
import Avatar from "components/common/Avatar";
import Box from "components/common/Box";
import Text from "components/common/Text";
import { useNavigate } from "react-router-dom";
import { localStore } from "stores/localStore";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "components/common/IconButton";
import { appConstants } from "helpers/constants/appConstants";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { CustomerDashboardApiAction } from "stores/redux/customerDashBoard/customerDashBoardApiSlice";
import Divider from "components/common/Divider";
import Badge from "components/common/Badge";

// User
const userRole = appConstants.userRoles;

const PROFILE_ROUTES = {
  [userRole.ADMIN.code]: "/admin/profile",
  [userRole.RIA.code]: "/profile",
  [userRole.CUSTOMER.code]: "/customer/profile",
  [userRole.SUBBROKER.code]: "/profile",
};

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: "40px",
  height: "40px",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    width: "40px",
    height: "40px",
  },
}));

const Header = (props) => {
  const {
    onLogoutClick,
    isDrawerExpanded,
    onDrawerToggle,
    selectedRoute,
    userRoleCode,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const dispatch = useDispatch();

  const dashboardData = useSelector(
    (state) => state.commonReducer.dashboardData
  );

  const [fetchNotifications, { data, isLoading, error }] =
    CustomerDashboardApiAction.getNotifications();
  
  const [markAsSeen] = CustomerDashboardApiAction.markNotificationsAsSeen();

  const notificationCount = data?.notificationCount || 0;
  const notifications = data?.notifications || [];
  console.log("notifications+++",notifications);
  

  useEffect(() => {
    // Fetch notifications on component mount
    fetchNotifications();
  }, []);

  // const newdashboardData = useSelector((state) => state.commonActions.dashboardData);
  // console.log("Dashboard Data from Redux:", newdashboardData);
  // const dashboardData = useSelector(
  //   (state) => state.commonReducer.dashboardData
  // );

  // Now you can use dashboardData in your component
  // console.log("Dashboard Data in YourComponent:", dashboardData);

  // const [dashboardData, setDashboardData] = useState({});

  const classes = useStyles();
  const navigate = useNavigate();
  // const [isLoading, setIsLoading] = React.useState(true);

  // useEffect to handle loading state
  // React.useEffect(() => {
  //   setIsLoading(true);

  //   // Check if dashboardData is available
  //   if (dashboardData) {
  //     setIsLoading(false);
  //   }
  // }, [dashboardData]);

  // Show loading indicator until dashboardData is available
  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }

  const formatExpiryDate = (dateString) => {
    const parts = dateString.split("-");
    // Rearrange the parts and join them with "/"
    return `${parts[2]}-${parts[1]}-${parts[0]}`;
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationIconClick = (event) => {
    setAnchorEl(event.currentTarget);
    
    // Call the markAsSeen mutation with the list of notification IDs
    const notificationIds = notifications.map(notification => notification.notification_id);
    markAsSeen(notificationIds);
    fetchNotifications();

};
  // Set Profile route

  const profilePath = PROFILE_ROUTES[userRoleCode];

  return (
    <>
      <AppBar className={classes.appBarRoot} position="sticky" elevation={0}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box ml={2}>
            <Text color="#242424" variant="body1" sx={{ fontWeight: 500 }}>
              {selectedRoute}
            </Text>
          </Box>

          {/* Conditionally render the Box component for /dashboard route */}
          {selectedRoute === "Dashboard" &&
            userRoleCode == appConstants.userRoles.RIA.code &&
            dashboardData && (
              <Box
                display="flex"
                flexDirection="row"
                px={2}
                justifyContent="center"
                alignItems="center"
                sx={{
                  width: "auto",
                  backgroundColor: "#E5EEFF",
                  height: "36px",
                  borderRadius: "8px",
                }}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  sx={{ width: "auto" }}
                >
                  <Text
                    variant="small"
                    color="#667085"
                    sx={{ marginRight: "6px" }}
                  >
                    SEBI registration no. :
                  </Text>
                  <Text variant="small" color="#101828">
                    {dashboardData?.sebiregno}
                  </Text>
                </Box>
                <Box
                  ml={4}
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  sx={{ width: "auto" }}
                >
                  <Text
                    variant="small"
                    color="#667085"
                    sx={{ marginRight: "6px" }}
                  >
                    BASL no. :{" "}
                  </Text>
                  <Text variant="small" color="#101828">
                    {" "}
                    {dashboardData?.baslNo}
                  </Text>
                </Box>
                <Box
                  ml={4}
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  sx={{ width: "auto" }}
                >
                  <Text
                    variant="small"
                    color="#667085"
                    sx={{ marginRight: "6px" }}
                  >
                    Expiry date :{" "}
                  </Text>
                  <Text variant="small" color="#101828">
                    {formatExpiryDate(dashboardData?.expirydate)}
                  </Text>
                </Box>
              </Box>
            )}

          <ToolBar className={classes.toolBarRoot}>
            <Box direction="row" justifyContent="flex-end" alignItems="center">
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={1.5}
                sx={{ width: "100%" }}
                alignItems="center"
              >
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1.5}
                >
                 <IconButton onClick={notificationCount > 0 ? handleNotificationIconClick : null} disabled={notificationCount === 0}>
                    <Badge
                      badgeContent={notificationCount}
                      color="error"
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <NotificationsIcon />
                    </Badge>
                  </IconButton>

                  <Menu
                    anchorEl={anchorEl}
                    open={isMenuOpen}
                    onClose={handleMenuClose}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                  >
                    {isLoading ? (
                      <MenuItem>Loading...</MenuItem>
                    ) : error ? (
                      <MenuItem>Error loading notifications</MenuItem>
                    ) : (
                      <Stack spacing={0}>
                        {notifications
                          .slice(0, 10)
                          .map((notification, index) => (
                            <React.Fragment key={notification.id}>
                              <MenuItem onClick={handleMenuClose}>
                                {notification.message}
                              </MenuItem>
                              {index <
                                notifications.slice(0, 10).length - 1 && (
                                <Divider />
                              )}
                            </React.Fragment>
                          ))}
                      </Stack>
                    )}
                  </Menu>

                  {/* <Button variant="outlined" startIcon={<NotificationsIcon />}>
                    Others
                  </Button> */}
                  {/* <Button variant="outlined" startIcon={<NotificationsIcon />}>
                    Trades
                  </Button> */}
                  <StyledAvatar
                    variant="h3"
                    src="./images/avatar.jpg"
                    title={"Faisal"}
                    onClick={() => {
                      navigate(profilePath);
                    }}
                  />
                  {/* <IconButton size="small" onClick={onLogoutClick}>
								<LogoutIcon fontSize="small" />
							</IconButton> */}
                </Stack>
              </Stack>
            </Box>
          </ToolBar>
        </Box>{" "}
      </AppBar>
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    appBarRoot: {
      backgroundColor: `${theme.palette.background.paper} !important`,
      color: "#1e1e1e !important",
      display: "flex",
      flexDirection: "row",
      boxShadow: "0px 0px 10px #ebebeb",
    },

    toolBarRoot: {
      justifyContent: "flex-end",
    },
  })
);

export default React.memo(Header);
