import React, { useState, useEffect } from "react";
import Grid from "components/common/Grid";
import Box from "components/common/Box";
import Text from "components/common/Text";
import styled from "@emotion/styled";
import Stack from "components/common/Stack";
import ModelAllocationPieChart from "components/common/ModelAllocationPieChart";

const MainGrid = styled(Grid)(() => ({
  marginBottom: "24px",
  padding: "24px",
  background: "#FFF",
  border: "1px solid #E7E7E7",
  borderRadius: "8px",
  boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10)",
}));

const PortfolioTitile = styled(Text)(() => ({
  color: "#101828",
  fontSize: "18px",
  fontweight: 500,
  mb: "8px",
}));

const PortfolioSubTitile = styled(Text)(() => ({
  color: "#676C76",
  fontSize: "14px",
  fontweight: 500,
  width: "460px",
  mb: "20px",
}));

function ModelPortfolioAllocation(props) {

  const { apiResponse, summaryData } = props;
  const [investmentSummary, setInvestmentSummary] = useState({
    invested_amt: 0,
    remaining_cash: 0,
    total_cash: 0,
  });
  useEffect(() => {
    if (summaryData?.length > 0) {
      const { invested_amt, remaining_cash, total_cash } =
        summaryData[0];
      setInvestmentSummary({
        invested_amt,
        remaining_cash,
        total_cash,
      });
    }
  }, [summaryData]);
  const { invested_amt, remaining_cash, total_cash } = investmentSummary;
  const investedPercentage = (invested_amt / total_cash) * 100;
  const maxInvestablePercentage = 100;
  const remainingInvestablePercentage = maxInvestablePercentage - investedPercentage;

  return (
    <>
      <MainGrid>
        <Grid item xs={12}>
          <Box>
            <Text sx={{ mb: "50px" }}>Model Portfolio allocation</Text>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <ModelAllocationPieChart data={apiResponse?.[0]?.model_portfolio_details} name={apiResponse?.[0]?.product_category_name} />
              <ModelAllocationPieChart data={apiResponse?.[1]?.model_portfolio_details} name={apiResponse?.[1]?.product_category_name} />
              <ModelAllocationPieChart data={apiResponse?.[2]?.model_portfolio_details} name={apiResponse?.[2]?.product_category_name} />
            </Stack>
            <Box
              sx={{
                backgroundColor: "#F7F8FF",
                borderRadius: "16px",
                padding: "17px",
                width: "72rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#EBEDF7",
                  padding: "17px",
                  borderRadius: "8px",
                  width: "calc(33.33% - 10px)", // Adjusted width for "Total Capital"
                }}
              >
                <Text variant="body1" sx={{ fontWeight: 500 }}>
                  Total Capital
                </Text>
                <Text variant="body1">{total_cash}</Text>
              </Box>

              <Box
                sx={{
                  backgroundColor: "#EBEDF7",
                  padding: "17px",
                  borderRadius: "8px",
                  width: "calc(33.33% - 10px)", // Adjusted width for "Invested Amount"
                }}
              >
                <Text variant="body1" sx={{ fontWeight: 500 }}>
                  Invested Amount
                </Text>
                <Text variant="body1">{invested_amt}</Text>
              </Box>

              <Box
                sx={{
                  backgroundColor: "#EBEDF7",
                  padding: "17px",
                  borderRadius: "8px",
                  width: "calc(33.33% - 10px)", // Adjusted width for "Remaining Amount"
                }}
              >
                <Text variant="body1" sx={{ fontWeight: 500 }}>
                  Remaining Amount
                </Text>
                <Text variant="body1">{remaining_cash}</Text>
              </Box>
            </Box>

            {
              (total_cash || total_cash != 0) &&
                (invested_amt || invested_amt != 0) ?
                <Box
                  sx={{
                    backgroundColor: "#F7F8FF",
                    borderRadius: "16px",
                    padding: "17px",
                    width: "72rem",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#EBEDF7",
                      padding: "17px",
                      borderRadius: "8px",
                      width: "calc(50% - 10px)", // Adjusted width for "Invested Percentage"
                    }}
                  >
                    <Text variant="body1" sx={{ fontWeight: 500 }}>
                      You have till Invested (%)
                    </Text>
                    <Text variant="body1">{investedPercentage.toFixed(2)}%</Text>
                  </Box>

                  <Box
                    sx={{
                      backgroundColor: "#EBEDF7",
                      padding: "17px",
                      borderRadius: "8px",
                      width: "calc(50% - 10px)", // Adjusted width for "Remaining Investable Percentage"
                    }}
                  >
                    <Text variant="body1" sx={{ fontWeight: 500 }}>
                      You can still invest up to (%)
                    </Text>
                    <Text variant="body1">{remainingInvestablePercentage.toFixed(2)}%</Text>
                  </Box>
                </Box>
                : null
            }
          </Box>
        </Grid>
      </MainGrid>
    </>
  );
}

export default ModelPortfolioAllocation;
