import React, { useState, useEffect } from "react";
import Tab from "components/common/Tab";

import TradeContainer from "./TradeContainer";
import { useLocation } from "react-router-dom";

const MODEL_PORTFOLIO = "MODEL_PORTFOLIO";
const ALGO = "ALGO";
const CUSTOM = "CUSTOM";

const tradeTabs = [
  { id: 1, label: "Custom", value: CUSTOM },
  { id: 2, label: "Model portfolio", value: MODEL_PORTFOLIO },
  { id: 3, label: "Algo (F&O)", value: ALGO, disabled:false },
];

const TradesContainer = () => {
  const location = useLocation();
  const id = location.state && location.state.productIdNo;
  const [tabList,setTabList] = useState(tradeTabs);

  const [tradeType, setTradeType] = useState(() => {
    if (id) {
      const tab = tradeTabs.find((tab) => tab.id === id);
      return tab ? tab.value : tradeTabs[0].value;
    } else {
      return tradeTabs[0].value;
    }
  });

  useEffect(() => {
    if (id) {
      const tab = tradeTabs.find((tab) => tab.id === id);
      if (tab) {
        setTradeType(tab.value);
      }
    }
  }, [id]);

  const handleTabChange = (e, newValue) => {
    setTradeType(newValue);
  };

  const goToCustomTab = () => {
    setTradeType(CUSTOM); // Set to CUSTOM tab
  };

  const handleAlgoSubscribed = (data) => {
    if (data === 0) {
      const updatedTabs = tradeTabs.map(tab => 
        tab.id === 3 ? { ...tab, disabled: true } : tab
      );
      setTabList(updatedTabs);
    }
  };

  const PRODUCT_ID = {
    CUSTOM: 1,
    MODEL_PORTFOLIO: 2,
    ALGO: 3,
  };

  return (
    <>
      <Tab
        tabs={tabList}
        onChange={handleTabChange}
        value={tradeType}
        sx={{ maxWidth: "calc(100% + 48px)", margin: "0 -24px" }}
        tabSx={{ p: "18px 48px" }}
      />
      {tradeType === MODEL_PORTFOLIO && (
        <TradeContainer
          PRODUCT_ID={PRODUCT_ID.MODEL_PORTFOLIO}
          goToCustomTab={goToCustomTab}
        />
      )}
      {tradeType === ALGO && (
        <TradeContainer
          PRODUCT_ID={PRODUCT_ID.ALGO}
          goToCustomTab={goToCustomTab}
        />
      )}
      {tradeType === CUSTOM && (
        <TradeContainer
          PRODUCT_ID={PRODUCT_ID.CUSTOM}
          goToCustomTab={goToCustomTab}
          handleAlgoSubscribed={handleAlgoSubscribed}
        />
      )}
    </>
  );
};

export default TradesContainer;
