import React, { useState } from "react";
import Dialog from "components/common/Dialog";
import Box from "components/common/Box";
import Text from "components/common/Text";
import FileUpload from "components/common/DragNdDrop";
import Stack from "components/common/Stack";
import Button from "components/common/Button";
import { Link } from "@mui/material";
// Import the TradesPreviewModal component

const TradeImportModal = (props) => {
  const {
    isOpen = false,
    handleClose = () => {},
    handleSave = () => {},
    importTradeResponse 
  } = props;
  

  const [file, setFile] = useState(null);

  const handleFileChange = ({ value }) => {
    setFile(value[0]);
  };

  // Function to download the Excel file from base64
  const downloadFile = () => {
    if (!importTradeResponse) return;

    // Assuming the file is sent as base64 encoded string in the response
    const linkSource = importTradeResponse;
    const downloadLink = document.createElement("a");
    const fileName = "import_trade_format.xlsx";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={isOpen}
        title={
          <Text fontSize="18px" fontWeight="600" marginBottom="-15px">
            Upload and attach file
          </Text>
        }
        disableCloseIcon
        maxWidth="sm"
        contentComponent={() => (
          <Box>
            <Text
              fontSize="14px"
              fontWeight="500"
              color="#667085"
              mt="2px"
              mb="16px"
            >
              Rorem ipsum dolor sit amet, consectetur adipiscing elit.
            </Text>

            <FileUpload
              handleChange={handleFileChange}
              acceptedFileTypes={[
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                "text/csv",
              ]}
            />

            {/* Download file */}
            <Stack
              sx={{
                alignItems: "center",
                justifyContent: "start",
                flexFlow: "row nowrap",
                mt: "6px",
              }}
            >
              <Box
                component="img"
                alt="Exclamation"
                src="./images/download.svg"
                mr="2px"
				mt="10px"
              />
              <Link
                onClick={downloadFile} // Call the download function
                style={{
                  textDecoration: "underline",
                  fontWeight: "600",
                  color: "#242424",
                  marginTop: "10px",
                  cursor: "pointer"
                }}
              >
                Download format here
              </Link>
            </Stack>

            {/* Action buttons */}
            <Stack
              direction="row"
              justifyContent="space-around"
              marginTop="10px"
            >
              <Button
                sx={{
                  width: "50%",
                  fontWeight: 600,
                  borderRadius: "8px",
                  p: "15px 20px",
                  mr: 1,
                  mt: "10px",
                }}
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  width: "50%",
                  fontWeight: 600,
                  borderRadius: "8px",
                  p: "15px 20px",
                  ml: 1,
                  mt: "10px",
                }}
                onClick={() => {
                  handleSave(file);
                }}
              >
                Attach Files
              </Button>
            </Stack>
          </Box>
        )}
      />
    </>
  );
};

export default TradeImportModal;
