import { createApi } from "@reduxjs/toolkit/query/react";
import { apiSliceInterceptor } from "stores/redux/apiSliceInterceptor";

const signUpApi = createApi({
  reducerPath: "signUp",
  baseQuery: apiSliceInterceptor.baseQueryWithInterceptor,
  tagTypes: [],
  endpoints: (builder) => ({
    sendOtp: builder.mutation({
      query: (payload) => ({
        url: "api/customer/signup/send-otp",
        method: "POST",
        body: payload,
      }),
    }),

    confirmEmailOtp: builder.mutation({
      query: (payload) => {
        return {
          url: `api/customer/signup/verify-otp?code=${payload.code}`,
          method: "POST",
          body: payload,
        };
      },
    }),

    confirmPhoneOtp: builder.mutation({
      query: (payload) => ({
        url: "api/customer/signup/verify-otp",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const signUpApiReducer = signUpApi.reducer;

export const signUpApiAction = {
  middleware: signUpApi.middleware,
  reducerPath: signUpApi.reducerPath,
  sendOtp: signUpApi.useSendOtpMutation,
  confirmEmailOtp: signUpApi.useConfirmEmailOtpMutation,
  confirmPhoneOtp: signUpApi.useConfirmPhoneOtpMutation,
};
