import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
 
const reducerName = "toast";
const initialState = {
  toast: {
    open: false,
    message: "",
    variant: "",
    key:""
  },
  breadCrumbData: {
    name: "",
    user_id:'',
    is_block:''
  },
  performanceSection: {
    isInvestmentType: false,
  },
  filterTableData: [],

//   userDetails:{
// 	user_id: null,
// 	name: "",
// 	email: "",
// 	family_id: null,
// 	role_id: null,
// 	role_name:"",
// 	is_admin: 0,
// 	is_email_verified:"",
// 	is_mobile_verified:"",
// 	jwtToken: null,
//   },

//   role_code: ""
};
 
const toastSlice = createSlice({
	name: reducerName,
	initialState,
	reducers: {
		setToastData: (state, action) => ({
			...state,
			toast: {
				open: true,
				message: action.payload.message,
				timeout: action.payload.timeout ?? 4000,
				key: action.payload.key,
			},
		}),
		resetToastData: (state) => ({
			...state,
			toast: {
				...initialState.toast,
			},
		}),

    setBreadCrumbData: (state, action) => {
      return {
        ...state,
        breadCrumbData:
         {
          name: action.payload.name,
          user_id: action.payload.user_id,
          is_block: action.payload.is_block,
         },
      };
    },
	setUserBlockStatus: (state, action) => {
		return {
			...state,
			breadCrumbData: {
				...state.breadCrumbData,
				is_block: action.payload.is_block,
			},
		};
	},
    setFilterTableData: (state, action) => {
      return {
        ...state,
        filterTableData: action.payload,
      };
    },
	},
});
 
export const toastActions = toastSlice.actions;
export const toastReducers = persistReducer(
	{ key: reducerName, storage, whitelist: [] },
	toastSlice.reducer
);
