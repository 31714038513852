//Package Import
import { useState, useEffect, useRef } from "react";
import Chip from "@mui/material/Chip";
import dayjs from "dayjs";

//helpers
import useDebounce from "hooks/Debounce";
import { getRiskProfileChipColor } from "helpers/utility";

//Components
import Stack from "components/common/Stack";
import Box from "components/common/Box";
import Card from "components/common/Card";
import NewDialog from "components/common/Dialog";
import Text from "components/common/Text";
import SideDrawer from "components/common/SideDrawer";
import { styled } from "@mui/system";
import CancelIcon from "@mui/icons-material/Cancel";

import AddTradeDrawer from "components/Trade/customPortfolio/customAddTradeDrawer";
import ModelCheckPage from "components/Trade/customPortfolio/customCheckPage";
import TradeImportModal from "components/Trade/importTrades/TradeImportModal";
import TradesPreviewModal from "components/Trade/importTrades/TradesPreviewModal";
import RiskProfileModal from "components/Trade/importTrades/RiskProfileModal";
import SelectCustomerSendCallsTable from "components/Trade/SelectCustomerSendCallsTable";
import ActiveTradeDrawer from "components/Trade/importTrades/ActiveTradeDrawer";
import TradeUpdateCallDetailTable from "components/Trade/importTrades/TradeUpdateCallDetailTable";
import TradesCountStacks from "components/Trade/TradesCountStacks";
import TradeHeader from "components/Trade/TradeHeader";
import EditIcon from "@mui/icons-material/Edit";
import CustomAddFilter from "components/Trade/customPortfolio/CustomAddFilter";
import WarningPopup from "components/Trade/WarningPopUp";

import { tradesApiAction } from "stores/redux/apiSlices/trades/tradesApiSlice";
import CustomDataGrid from "components/datagrid/CustomGrid";
import TradeUpdateStackTable from "components/Trade/TradeUpdateStackTable";
import { clickAndDownloadFile } from "helpers/fileFormat";

import _get from "lodash/get";
import Tooltip from "components/common/Tooltip";
import Button from "components/common/Button";
import TextField from "components/common/TextField";
import IconButton from "components/common/IconButton";

// Constants
const AddSingleTradeStep = {
  ADD_TRADE_FORM: "ADD_TRADE_FORM",
  CUSTOMER_DETAIL_TABLE: "CUSTOMER_DETAIL_TABLE",
  ADD_TRADE_UPDATE_CALL: "ADD_TRADE_UPDATE_CALL",
};

const ImportTradeSteps = {
  IMPORT_FILE_MODAL: "IMPORT_FILE_MODAL",
  IMPORT_TRADE_PREVIEW_TABLE: "IMPORT_TRADE_PREVIEW_TABLE",
  IMPORT_RISK_PROFILE_MODAL: "IMPORT_RISK_PROFILE_MODAL",
  CUSTOMER_DETAILS_TABLE: "CUSTOMER_DETAILS_TABLE",
};

const SQUARE_OFF_STATUS = {
  PENDING: "PENDING", // square off pending from RIA, sub-broker
  INPROGREES: "INPROGREES", // call sent &, approval to be done by client
  COMPLETED: "COMPLETED", // square off done.
  CANCELLED: "CANCELLED", // Cancelled by broker, (HTX)
};

// let PRODUCT_ID = 1; // product code for custom

const TradeContainer = (props) => {
  const {
    PRODUCT_ID, // product code for custom CUSTOM=1, MODEL_PORTFOLIO=2, ALGO=3
    tradeId,
    goToCustomTab,
    handleAlgoSubscribed,
    tradeTabs,
  } = props;

  //states
  const [tradesDetails, setTradesDetails] = useState([]);
  const [tradesTotalRecord, setTradesTotalRecord] = useState(0);
  // const [tradesAlgoSubscribed,setTradesAlgoSubscribed] = useState();
  const [tradesPaginationModel, setTradesPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });

  const [currentStep, setCurrentStep] = useState(1);
  const [sqareOffApiLoading, setSqareOffApiLoading] = useState(false);
  const [isCustomFilterDrawerOpen, setIsCustomFilterDrawerOpen] =
    useState(false);
  const [isImportTradeInitiated, setIsImportTradeInitiated] = useState(false);
  const [filterData, setFilterData] = useState({
    profitFrom: null,
    profitTo: null,
    fromDate: null,
    toDate: null,
    clearAll: 0,
    search: "",
    isSquareOff: null,
  });

  //State: Add Single Trade
  const [isTradeDrawerOpen, setIsTradeDrawerOpen] = useState(false);
  const [isEditTradeDrawerOpen, setEditIsTradeDrawerOpen] = useState(false);
  const [isEditTradeUpdateDrawerOpen, setEditIsTradeUpdateDrawerOpen] =
    useState(false);

  const [addTradeCurrentStep, setAddTradeCurrentStep] = useState(null);
  const [newAddedTradeDetails, setNewAddedTradeDetails] = useState(null);
  const [tradeUpdateId, setTradeUpdateId] = useState(null);
  const [addSingleTradeSelectedDetails, setAddSingleTradeSelectedDetails] =
    useState([]);
  const [callPendingData, setCallPendingData] = useState();
  const [showTradesDetailsTable, setShowTradesDetailsTable] = useState(false);
  const [showTradesUpdateDetailsTable, setShowTradesUpdateDetailsTable] =
    useState(false);
  const [checkTradePending, setCheckTradePending] = useState(false);
  const [editedTradeId, setEditedTradeId] = useState();

  //State: Import Trade
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [isImportTradeInProcess, setIsImportTradeInProcess] = useState(false);
  const [importTradeCurrentStep, setImportTradeCurrentSteps] = useState(null);
  const [importListData, setImportListData] = useState([]);
  const [importValidateData, setImportValidateData] = useState([]);

  // loading...
  const [tradeLoading, setTradeLoading] = useState(false);

  // pop up
  const [warningMessage, setWarningMessage] = useState("");
  const [apiResponse, setApiResponse] = useState("");

  //filter
  const [showCheckboxes, setShowCheckboxes] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);

  //bulk order states
  const [importTradeResponse, setImportTradeResponse] = useState(null);

  //modify order state
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [editableQuantity, setEditableQuantity] = useState("");
  const [editablePrice, setEditablePrice] = useState("");
  const [editableStopLoss, setEditableStopLoss] = useState("");
  const [validationMessage, setValidationMessage] = useState("");

  //reload table
  const [reloadKey, setReloadKey] = useState(0);

  //add trade result

  const [result, setResult] = useState(null);  // Initialize result state


  // APIs: Import flow
  const [importTradeSendFile] = tradesApiAction.importTradeSendFileApi();
  const [postAttachImportFileData] = tradesApiAction.importProceedSelectRisk();
  const [importProceedSelectRisk] =
    tradesApiAction.importProceedCustomerTrade();
  const [proceedToImportTradeApi] = tradesApiAction.proceedToImportTrade();
  const [cancelOrderApi] = tradesApiAction.cancelOrderApi();
  const [cancelOrderApiUpdate] = tradesApiAction.cancelOrderApiUpdate();

  const [cancelOrderApiSingle] = tradesApiAction.cancelOrderApiSingle();

  const [updatePendingTrade] = tradesApiAction.updatePendingApi();

  const [updatePendingTradeUpdate] = tradesApiAction.updatePendingApiUpdate();


  const intervalRef = useRef(null);

  const handleRowSelection = (selectedIds) => {
    // Assuming each row in tradesDetails has an `id` field
    const selected = tradesDetails.filter((row) =>
      selectedIds.includes(row.id)
    );

    console.log("selected++++",selected);
    

    // Assuming you want to extract `customer_id` and `trade_id`
    const selectedDetails = selected.map((row) => ({
      customer_id: row.customerId,
      trade_id: row.tradeId,
    }));

    // Update the state with selected row IDs
    setSelectedRows(selected);
  };

  //modify order function
  const handleOpenModal = (rowData) => {
    setSelectedRow(rowData);
    setEditableQuantity(rowData.quantity);
    setEditablePrice(rowData.price);
    setEditableStopLoss(rowData.stop_loss);
    setOpen(true);
  };

  const handleImportButtonClick = () => {
    setIsImportTradeInitiated(true); // Update state
    initateImportTrade(); // Call the trade initiation logic
  };

  const handleOpenModalUpdate = (rowData) => {
    setSelectedRow(rowData);
    setEditableQuantity(rowData.quantity); // Set the quantity to be editable
    setEditablePrice(rowData.price);
    setEditableStopLoss(rowData.stop_loss);
    setOpenUpdate(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  const handleCloseModalUpdate = () => {
    setOpenUpdate(false);
    setSelectedRow(null);
  };

  const handleSquareOff = async () => {
    setSqareOffApiLoading(true);
    const payload = {
      squareOfTradeIds: selectedRows.map((row) => ({
        trade_id: row.tradeId,
        customer_id: row.customerId,
        transaction_id: row.transactionId,
      })),
    };
    try {
      let res = await squareOffTrades(payload);
      setSelectedRows([]); // set selected data
      getAndSetTradeListingData(); // recall fetch api
      setSqareOffApiLoading(false);
      // Handle API response as needed
    } catch (error) {
      console.error("Error:", error);
      setSqareOffApiLoading(false);
    } finally {
      setSqareOffApiLoading(false);
    }
  };
  // -----> Import Trade
  const ProceedToSelectRiskProfile = async (importListDataID) => {
    try {
      const res = await postAttachImportFileData(importListDataID);

      if (res.data.status === 0) {
        // Set validation message when the file data is invalid
        setValidationMessage("Please Put Correct Data");
        return; // Stop further execution if data is invalid
      }

      if (res && res.data && res.data.status === 1) {
        const proceedResponse = await importProceedSelectRisk(importListDataID); // Call the new API here

        try {
          if (proceedResponse.data.status === 1) {
            let { parsedRes, selectUserIds } =
              parseBulkTradeResponse(proceedResponse);
            setNewAddedTradeDetails(parsedRes);
            setAddSingleTradeSelectedDetails(selectUserIds);
            setPreviewModalOpen(false);
            setImportTradeCurrentSteps(ImportTradeSteps.CUSTOMER_DETAILS_TABLE);

            // Clear validation message if data is valid
            setValidationMessage("");
          } else {
            setValidationMessage("Failed to proceed with customer trade.");
          }
        } catch (err) {
          console.log("error : ", err);
        }
      }
    } catch (e) {
      console.log("Error ProceedToImportTrade: ", e);
      setValidationMessage("An error occurred while processing the data");
    }
  };

  const backToPreviewTable = () => {
    setPreviewModalOpen(true);
    setImportTradeCurrentSteps(ImportTradeSteps.IMPORT_TRADE_PREVIEW_TABLE);
  };

  const backToImportFileModal = () => {
    setPreviewModalOpen(false);
    setImportTradeCurrentSteps(ImportTradeSteps.IMPORT_FILE_MODAL);
  };

  const backToImportModal = () => {
    setPreviewModalOpen(false);
    setValidationMessage(false);
    setImportTradeCurrentSteps(ImportTradeSteps.IMPORT_FILE_MODAL);
  };

  const proceedToImportTrade = async (
    riskProfile = [],
    importedChangedData
  ) => {
    try {
      const payload = {
        trades: importedChangedData || importListData,
        productId: PRODUCT_ID,
      };

      if (PRODUCT_ID == 1) {
        payload.riskProfile = riskProfile;
      }

      const res = await proceedToImportTradeApi(payload);
      if (res && res.data && res.data.length) {
        // Note: While Import trade trade id could be multiple as per the selected stocks.
        const tradeIDs = [];
        const selectUserIds = [];
        const tradeCustomer = res.data.map((d) => {
          if (!tradeIDs.includes(d.trade_id)) {
            tradeIDs.push(d.trade_id);
          }
          selectUserIds.push(d.customer_id + d.trade_id);
          return {
            ...d,
            id: d.customer_id + d.trade_id,
          };
        });

        setPreviewModalOpen(false);
        setImportTradeCurrentSteps(ImportTradeSteps.CUSTOMER_DETAILS_TABLE);

        const tradeData = {
          customers: tradeCustomer,
        };
        setNewAddedTradeDetails(tradeData);
        setAddSingleTradeSelectedDetails(selectUserIds);
      } else {
        // alert no data found.
      }
    } catch (e) {
      console.log("Error ProceedToImportTrade: ", e);
    }
  };

  //bulk order function

  const handleProceedToImportTrade = (response) => {
    setImportTradeResponse(response);
  };

  // ---> Single Trade

  //Apis: single trade
  const [getTradesListing] = tradesApiAction.getTradesApi();
  const [addSingleTrade] = tradesApiAction.addTradesApi();
  const [squareOffTrades] = tradesApiAction.squareOffTradesApi();
  const [editSingleTrade] = tradesApiAction.editTradesApi();
  const [editBulkTrade] = tradesApiAction.editBulkApi();
  const [editSingleTradeUpdate] = tradesApiAction.editTradesUpdateApi();

  const [sendSingleTradeCall] = tradesApiAction.sendTradeCallsApi();
  const [multipleTradeCalls] = tradesApiAction.sendMultipleTradeCallsApi();
  const [getStackList, { data: StackList, isFetching: stackListFetching }] =
    tradesApiAction.getStackListApi();

  // API call to get trade table data based on trade ID
  const [getTradeDetailsUsingId] = tradesApiAction.getTradeDetailsUsingId();


  //
  useEffect(() => {
    // Call the function immediately, and then every 5 seconds
    getAndSetTradeListingData();

    intervalRef.current = setInterval(getAndSetTradeListingData, 45000); // 5000ms = 5 seconds


    // Cleanup the interval when the component unmounts
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []); // Empty dependency array ensures this runs once on mount

  // LS: did mount
  useEffect(() => {
    getStackList({ productId: PRODUCT_ID, noGlobalLoading: true });
  }, []);

  // const [downloadExcelData, { data: downloadedExcelFile = {} }] = tradesApiAction.downloadExcelData();
  const [downloadExcelData] = tradesApiAction.downloadExcelData();
  const [fetchedData, setFetchedData] = useState(null);
  const [expandedTradeId, setExpandedTradeId] = useState(null);
  const [StackTradeId, setStackTradeId] = useState();
  const [tableData, setTableData] = useState();

  const [getTradeTableUsingId] = tradesApiAction.getTradeUsingIds();

  const pendingCallStackList = StackList?.result?.filter(
    (trade) => trade.flag === 0
  );

  const pendingTradeCallStackList = StackList?.result?.filter(
    (trade) => trade.flag === 1
  );

  // Filter, Search, get listing
  const debouncedValue = useDebounce(filterData?.search, 500);

  // LS: didUpdate
  useEffect(() => {
    getAndSetTradeListingData();
  }, [debouncedValue, filterData?.clearAll, tradesPaginationModel]);

  // Get Api details methods
  const getAndSetTradeListingData = async () => {
    let res = [];
    let payload = {};
    let product_id = PRODUCT_ID; // Product ID 1 for custom trade

    try {
      setTradeLoading(true);

      // Add search and filter parameters
      payload.search = filterData?.search;
      if (filterData.fromDate) {
        payload.date_range_from = dayjs(filterData.fromDate).format(
          "YYYY/MM/DD"
        );
      }
      if (filterData.toDate) {
        payload.date_range_to = dayjs(filterData.toDate).format("YYYY/MM/DD");
      }
      if (filterData.profitFrom) {
        payload.profit_range_from = filterData.profitFrom;
      }
      if (filterData.profitTo) {
        payload.profit_range_to = filterData.profitTo;
      }
      if (filterData.isSquareOff) {
        payload.is_square_off = filterData.isSquareOff;
      }

      const { page, pageSize } = tradesPaginationModel;
      payload.noGlobalLoading = true; // Restricts global loading.

      res = await getTradesListing({
        product_id: product_id,
        page,
        pageSize,
        payload: payload,
        isSquareOff: filterData.isSquareOff,
      });

      if (res?.data) {
        const { data, totalRecord, isAlgoSubscribed } = res.data;
        setTradesDetails(data);
        setTradesTotalRecord(totalRecord);
        handleAlgoSubscribed(isAlgoSubscribed);
        // Set the checkbox display state based on filterData.isSquareOff
        setShowCheckboxes(filterData.isSquareOff == "0");

        setIsCustomFilterDrawerOpen(false);
      }
      setTradeLoading(false);
    } catch (e) {
      setTradeLoading(false);
    }
  };

  const updateTableListData = (data = []) => {
    if (data && data?.length) {
      const id = data.map((d) => d.customer_id);

      handleCustomerChangeInAddSingleTrade(id);
      setTableData(data);
    }
  };

  // Filter params changes
  const handleFilterDataChange = async ({ name, value, clearAll = 0 }) => {
    if (clearAll) {
      let newFilterData = { ...filterData };
      newFilterData.fromDate = null;
      newFilterData.toDate = null;
      newFilterData.profitFrom = null;
      newFilterData.profitTo = null;
      newFilterData.isSquareOff = null;
      newFilterData.clearAll = newFilterData.clearAll + 1;
      setFilterData(newFilterData);
      return;
    }

    if (["profitFrom", "profitTo"].includes(name) && isNaN(value)) {
      return;
    }

    let newFilterData = { ...filterData };
    if (["toDate", "fromDate"].includes(name) && value && value["$d"]) {
      newFilterData[name] = value["$d"];
    } else {
      newFilterData[name] = value;
    }

    setFilterData(newFilterData);
  };

  //Handler methods
  const handleCustomFilterDrawer = () => {
    setIsCustomFilterDrawerOpen(!isCustomFilterDrawerOpen);
  };

  const handleTradeDrawerOpen = () => {
    setIsTradeDrawerOpen(true);
    setAddTradeCurrentStep(AddSingleTradeStep.ADD_TRADE_FORM);
  };

  // Edit Single Trade
  const onEditIsTradeDrawerOpen = async ({ tradeId }) => {
    setEditIsTradeDrawerOpen(true);
    setEditedTradeId(tradeId);
  };

  const onEditUpdateIsTradeDrawerOpen = async ({ tradeId }) => {
    setEditIsTradeUpdateDrawerOpen(true);
    setEditedTradeId(tradeId);
  };

  // Add Single Trade Function
  const handleAddSingleTrade = async (tradeData) => {
    let payload = createAddSingleTradePayload(tradeData);
    let res = await addSingleTrade(payload);

    try {
      const { statusCode, result } = _get(res, "data", {});
      
      if (statusCode === 200) {
        let { parsedRes, selectUserIds } = parseAddSingleTradeResponse(result);
        setNewAddedTradeDetails(parsedRes);
        setAddSingleTradeSelectedDetails(selectUserIds);
        setAddTradeCurrentStep(AddSingleTradeStep.CUSTOMER_DETAIL_TABLE);
        setResult(result);
      }
    } catch (err) {
      console.log("error : ", err);
    }
  };

  const handleEditSingleTrade = async (tradeData) => {
    let payload = createEditSingleTradePayload(tradeData);
    let res = await editSingleTrade({ payload, editedTradeId });

    try {
      const { statusCode, result } = _get(res, "data", {});

      if (statusCode === 200) {
        let { parsedRes, selectUserIds } = parseAddSingleTradeResponse(result);
        setNewAddedTradeDetails(parsedRes);
        setAddSingleTradeSelectedDetails(selectUserIds);
        setAddTradeCurrentStep(AddSingleTradeStep.CUSTOMER_DETAIL_TABLE);
        getTradeTableUsingId({ StackTradeId: editedTradeId });
        setEditIsTradeDrawerOpen(false);
        setReloadKey((prevKey) => prevKey + 1);
      }
    } catch (err) {
      console.log("error : ", err);
    }
  };

  const handleImportTradeSubmit = async (tradeData) => {
    let payload = createBulkOrderTradePayload(tradeData);
    let res = await editBulkTrade({ payload });
    try {
      const { statusCode, result } = _get(res, "data", {});
      setImportTradeResponse(res.data.result);

      if (statusCode === 200) {
        let { parsedRes, selectUserIds } = parseAddSingleTradeResponse(result);
        setNewAddedTradeDetails(parsedRes);
        setAddSingleTradeSelectedDetails(selectUserIds);
        // setAddTradeCurrentStep(AddSingleTradeStep.CUSTOMER_DETAIL_TABLE);
        setImportTradeCurrentSteps(ImportTradeSteps.IMPORT_FILE_MODAL);
        // getTradeTableUsingId({ StackTradeId: editedTradeId });
        setEditIsTradeDrawerOpen(false);
        // setReloadKey((prevKey) => prevKey + 1);
      }
    } catch (err) {
      console.log("error : ", err);
    }
  };

  const handleSubmit = isImportTradeInitiated
    ? handleImportTradeSubmit
    : handleEditSingleTrade;

  const handleEditSingleTradeUpdate = async (tradeData) => {
    let payload = createEditSingleTradePayload(tradeData);
    let res = await editSingleTradeUpdate({ payload, editedTradeId });

    try {
      const { statusCode, result } = _get(res, "data", {});

      if (statusCode === 200) {
        let { parsedRes, selectUserIds } = parseAddSingleTradeResponse(result);
        setNewAddedTradeDetails(parsedRes);
        setAddSingleTradeSelectedDetails(selectUserIds);
        setAddTradeCurrentStep(AddSingleTradeStep.CUSTOMER_DETAIL_TABLE);
        getTradeTableUsingId({ StackTradeId: editedTradeId });
        setEditIsTradeUpdateDrawerOpen(false);
        setReloadKey((prevKey) => prevKey + 1);
      }
    } catch (err) {
      console.log("error : ", err);
    }
  };

  const parseAddSingleTradeResponse = (res) => {
    let parsedRes = {};
    let selectUserIds = [];
    let parsedCustomer = res?.customers?.map((item) => {
      selectUserIds.push(item.customer_id);
      return {
        ...item,
        id: item.customer_id,
      };
    });
    parsedRes.trade_id = res.trade_id ?? null;
    parsedRes.customers = parsedCustomer;
    return { parsedRes, selectUserIds };
  };

  const parseBulkTradeResponse = (proceedResponse) => {
    let parsedRes = {};
    let selectUserIds = [];
    let parsedCustomer = proceedResponse?.data?.data?.map((item) => {
      selectUserIds.push(item.customer_id);
      return {
        ...item,
        id: item.customer_id,
      };
    });

    parsedRes.trade_id =
      parsedCustomer.map((item) => {
        return item.trade_id;
      }) || null;
    parsedRes.customers = parsedCustomer;
    return { parsedRes, selectUserIds };
  };

  const createAddSingleTradePayload = (tradeData) => {
    let payload = {};
    payload.stock_name = tradeData?.stockName;
    payload.index = tradeData?.index;
    payload.type = tradeData?.tradeType;
    payload.product_type = tradeData?.productType;
    payload.is_percent = tradeData?.buySellType === "PERCENTAGE" ? "Y" : "N";
    payload.is_manual_trade = tradeData?.is_manual_trade;
    if (tradeData.is_target) {
      payload.is_target = tradeData?.isTargetChecked ? "Y" : "N";
    } else {
      payload.is_target = "";
    }

    payload.percent =
      tradeData?.buySellType === "PERCENTAGE" ? tradeData?.percentage : "0";
    payload.quantity =
      tradeData?.buySellType === "QUANTITY" ? tradeData?.quantity : "0";
    payload.order_type = tradeData?.orderType;
    if (tradeData?.orderType === "LIMIT") {
      payload.price = tradeData?.price;
    }
    if (tradeData?.orderType === "MARKET") {
      payload.price = tradeData?.price;
    }

    // payload.is_stop_loss = tradeData?.isStopLossChecked === true ? "Y" : "N";

    if (tradeData?.is_stop_loss) {
      payload.is_stop_loss = tradeData?.isStopLossChecked ? "Y" : "N";
    } else {
      payload.is_stop_loss = "";
    }

    payload.stop_loss = tradeData?.stopLossPerc;

    payload.target = tradeData?.target;
    payload.expiry_date = dayjs(tradeData?.maxBuySellDate).format("YYYY-MM-DD");

    if (tradeData?.maxBuySellTime) {
      payload.expiry_time = dayjs(tradeData?.maxBuySellTime).format("HH:mm:ss");
    } else {
      payload.expiry_time = "15:29:00";
    }
    payload.is_risk_profile = tradeData?.isRiskProfileSelected;
    payload.product_id = PRODUCT_ID;

    let customers = [];
    if (tradeData?.isRiskProfileSelected) {
      if (tradeData?.isHighRiskChecked) {
        let highRiskCustomers = tradeData?.selectedHighRiskCustomers.map(
          (item) => {
            return {
              customer_id: item.user_id,
              risk_profile_id: item.risk_profile_id,
            };
          }
        );
        customers = [...customers, ...highRiskCustomers];
      }
      if (tradeData?.isModerateRiskChecked) {
        let moderateRiskCustomers = tradeData?.selectedMediumRiskCustomers.map(
          (item) => {
            return {
              customer_id: item.user_id,
              risk_profile_id: item.risk_profile_id,
            };
          }
        );
        customers = [...customers, ...moderateRiskCustomers];
      }
      if (tradeData?.isLowRiskChecked) {
        let lowRiskCustomers = tradeData?.selectedLowRiskCustomers.map(
          (item) => {
            return {
              customer_id: item.user_id,
              risk_profile_id: item.risk_profile_id,
            };
          }
        );
        customers = [...customers, ...lowRiskCustomers];
      }
    }
    if (tradeData?.isCustomersSelected) {
      let allCustomers = tradeData?.selectedCustomers?.map((item) => {
        return {
          customer_id: item.user_id,
          risk_profile_id: item.risk_profile_id,
        };
      });
      customers = [...customers, ...allCustomers];
    }

    payload.customers = customers;

    return payload;
  };

  const createEditSingleTradePayload = (tradeData) => {
    let payload = {};
    payload.stock_name = tradeData?.stockName;
    payload.index = tradeData?.index;
    payload.type = tradeData?.tradeType;
    payload.product_type = tradeData?.productType;

    payload.is_percent = tradeData?.buySellType === "PERCENTAGE" ? "Y" : "N";

    if (tradeData?.is_target) {
      payload.is_target = tradeData?.isTargetChecked ? "Y" : "N";
    } else {
      payload.is_target = "";
    }

    payload.percent =
      tradeData?.buySellType === "PERCENTAGE" ? tradeData?.percentage : "0";
    payload.quantity =
      tradeData?.buySellType === "QUANTITY" ? tradeData?.quantity : "0";
    payload.order_type = tradeData?.orderType;
    if (tradeData?.orderType === "LIMIT") {
      payload.price = tradeData?.price;
    }
    if (tradeData?.orderType === "MARKET") {
      payload.price = tradeData?.price;
    }
    // payload.is_stop_loss = tradeData?.isStopLossChecked === true ? "Y" : "N";

    if (tradeData?.is_stop_loss) {
      payload.is_stop_loss = tradeData?.isStopLossChecked ? "Y" : "N";
    } else {
      payload.is_stop_loss = "";
    }

    payload.stop_loss = tradeData?.stopLossPerc;

    payload.target = tradeData?.target;
    payload.expiry_date = dayjs(tradeData?.maxBuySellDate).format("YYYY-MM-DD");

    if (tradeData?.maxBuySellTime) {
      payload.expiry_time = dayjs(tradeData?.maxBuySellTime).format("HH:mm:ss");
    } else {
      payload.expiry_time = "15:29:00";
    }
    payload.is_risk_profile = tradeData?.isRiskProfileSelected;
    payload.product_id = PRODUCT_ID;
    payload.pro

    let customers = [];
    if (tradeData?.isRiskProfileSelected) {
      if (tradeData?.isHighRiskChecked) {
        let highRiskCustomers = tradeData?.selectedHighRiskCustomers.map(
          (item) => {
            return {
              customer_id: item.user_id,
              risk_profile_id: item.risk_profile_id,
            };
          }
        );
        customers = [...customers, ...highRiskCustomers];
      }
      if (tradeData?.isModerateRiskChecked) {
        let moderateRiskCustomers = tradeData?.selectedMediumRiskCustomers.map(
          (item) => {
            return {
              customer_id: item.user_id,
              risk_profile_id: item.risk_profile_id,
            };
          }
        );
        customers = [...customers, ...moderateRiskCustomers];
      }
      if (tradeData?.isLowRiskChecked) {
        let lowRiskCustomers = tradeData?.selectedLowRiskCustomers.map(
          (item) => {
            return {
              customer_id: item.user_id,
              risk_profile_id: item.risk_profile_id,
            };
          }
        );
        customers = [...customers, ...lowRiskCustomers];
      }
    }
    if (tradeData?.isCustomersSelected) {
      let allCustomers = tradeData?.selectedCustomers?.map((item) => {
        return {
          customer_id: item.user_id,
          risk_profile_id: item.risk_profile_id,
        };
      });
      customers = [...customers, ...allCustomers];
    }

    payload.customers = customers;

    return payload;
  };

  const createBulkOrderTradePayload = (tradeData) => {
    let payload = {};

    payload.stock_name = tradeData?.stockName;
    payload.index = tradeData?.index;
    payload.type = tradeData?.tradeType;
    payload.product_type = tradeData?.productType;
    payload.is_percent = tradeData?.buySellType === "PERCENTAGE" ? "Y" : "N";

    if (tradeData?.is_target) {
      payload.is_target = tradeData?.isTargetChecked ? "Y" : "N";
    } else {
      payload.is_target = "";
    }

    payload.percent =
      tradeData?.buySellType === "PERCENTAGE" ? tradeData?.percentage : "0";
    payload.quantity =
      tradeData?.buySellType === "QUANTITY" ? tradeData?.quantity : "0";
    payload.order_type = tradeData?.orderType;

    if (tradeData?.orderType === "LIMIT" || tradeData?.orderType === "MARKET") {
      payload.price = tradeData?.price;
    }

    if (tradeData?.is_stop_loss) {
      payload.is_stop_loss = tradeData?.isStopLossChecked ? "Y" : "N";
    } else {
      payload.is_stop_loss = "";
    }

    payload.stop_loss = tradeData?.stopLossPerc;
    payload.target = tradeData?.target;
    payload.expiry_date = dayjs(tradeData?.maxBuySellDate).format("YYYY-MM-DD");

    if (tradeData?.maxBuySellTime) {
      payload.expiry_time = dayjs(tradeData?.maxBuySellTime).format("HH:mm:ss");
    } else {
      payload.expiry_time = "15:29:00";
    }

    payload.is_risk_profile = tradeData?.isRiskProfileSelected;
    payload.product_id = PRODUCT_ID;

    // Construct risk_profileIds based on selections
    let riskProfileIds = [];

    // High Risk
    if (tradeData?.isHighRiskChecked) {
      riskProfileIds.push({ risk_profile_id: 1 }); // Assuming 1 is the ID for high risk
    }
    if (tradeData?.selectedHighRiskCustomers?.length > 0) {
      riskProfileIds = riskProfileIds.concat(
        tradeData.selectedHighRiskCustomers.map((item) => ({
          risk_profile_id: item.risk_profile_id,
        }))
      );
    }

    // Moderate Risk
    if (tradeData?.isModerateRiskChecked) {
      riskProfileIds.push({ risk_profile_id: 2 }); // Assuming 2 is the ID for moderate risk
    }
    if (tradeData?.selectedMediumRiskCustomers?.length > 0) {
      riskProfileIds = riskProfileIds.concat(
        tradeData.selectedMediumRiskCustomers.map((item) => ({
          risk_profile_id: item.risk_profile_id,
        }))
      );
    }

    // Low Risk
    if (tradeData?.isLowRiskChecked) {
      riskProfileIds.push({ risk_profile_id: 3 }); // Assuming 3 is the ID for low risk
    }
    if (tradeData?.selectedLowRiskCustomers?.length > 0) {
      riskProfileIds = riskProfileIds.concat(
        tradeData.selectedLowRiskCustomers.map((item) => ({
          risk_profile_id: item.risk_profile_id,
        }))
      );
    }

    // Set risk_profileIds
    payload.risk_profileIds = riskProfileIds;

    return payload;
  };

  const stepToSendCall = () => {
    // Update the state to show the Customer Details Table
    setAddTradeCurrentStep(AddSingleTradeStep.CUSTOMER_DETAIL_TABLE);
    setShowTradesDetailsTable(true); // Set this to true to show the table
  };

  const pendingTradeCheck = () => {
    // Update the state to show the Customer Details Table
    setAddTradeCurrentStep(AddSingleTradeStep.ADD_TRADE_UPDATE_CALL);
    setCheckTradePending(true); // Set this to true to show the table
  };

  const backtoMainScreen = () => {
    // Trade pending state
    setAddTradeCurrentStep(null);
    setCheckTradePending(false); // Set this to true to show the table

    // Send calls state
    setShowTradesDetailsTable(false); // Set this to true to show the table

    // back from update trade details
    setShowTradesUpdateDetailsTable(false);
    setIsTradeDrawerOpen(false);

    // Recall API: Update Stack list
    getStackList({ productId: PRODUCT_ID, noGlobalLoading: true });

    // import
    setIsImportTradeInProcess(false);
    setImportTradeCurrentSteps(null);
    setNewAddedTradeDetails(null);
    setAddSingleTradeSelectedDetails([]);

    // auto scroll to top
    document.getElementById("mainAppWrapper").scrollTo(0, 0);
  };

  const handleCustomerChangeInAddSingleTrade = (data) => {
    const selected = data || [];
    setAddSingleTradeSelectedDetails(selected);
  };

  const sendSingleTradeCalls = async (
    trade_id = null,
    tradeDataForTableWithId = null
  ) => {
    const payload = {};

    if (!trade_id) {
      let customers = addSingleTradeSelectedDetails.map((id) => ({
        customer_id: id,
      }));
      payload.trade_id = newAddedTradeDetails.trade_id;
      payload.customers = customers;
    } else {
      let customers1 = tradeDataForTableWithId.map((item) => ({
        customer_id: item.customer_id,
      }));
      payload.trade_id = trade_id;
      payload.customers = customers1;
    }

    try {
      let res = await sendSingleTradeCall(payload);
      const resTradeId = _get(res, "data.result.trade_id[0]", null);
      setTradeUpdateId(resTradeId);

      let data = _get(res, "data.result", null);
      if (!data || !data.length || !Array.isArray(data)) {
        let res2 = await getTradeDetailsUsingId({
          productId: PRODUCT_ID,
          StackTradeId: resTradeId,
        });
        data = _get(res2, "data.result", null);
      }

      if ((data && data.length) || !Array.isArray(data)) {
        setAddTradeCurrentStep(AddSingleTradeStep.ADD_TRADE_UPDATE_CALL);
        if (trade_id) {
          setShowTradesDetailsTable(false);
          setShowTradesUpdateDetailsTable(true);
        }
      }

      // Check for lessCashUsers
      const lessCashUsers = _get(res, "data.lessCashUsers", []);
      setApiResponse(lessCashUsers);
      if (lessCashUsers.length > 0) {
        setWarningMessage(`don't have insufficient funds to send the call`);
      }

      // setCallPendingData(data);
    } catch (err) {
      console.log("err : ", err);
    }
  };

  // Send Multiple trade calls
  const sendMultipleTradeCalls = async () => {
    const tradeList = newAddedTradeDetails?.customers.filter((d) =>
      addSingleTradeSelectedDetails.includes(d.id)
    );
    const submitPayload = tradeList.map((d) => ({
      customer_id: d.customer_id,
      trade_id: d.trade_id,
    }));

    try {
      // submit API call
      const res = await multipleTradeCalls({ trades: submitPayload });
      getStackList({ productId: PRODUCT_ID, noGlobalLoading: true });
      backtoMainScreen();
      setImportTradeCurrentSteps(null);
      pendingTradeCheck();
      setTimeout(() => {
        const element = document.getElementById("mainAppWrapper");
        element.scrollTo({ top: element.scrollHeight, behavior: "smooth" });
      }, 1500);
    } catch (err) {
      console.log("err : ", err);
    }
  };

  //Import Trade Functions

  const initateImportTrade = () => {
    setIsImportTradeInProcess(true);
    setEditIsTradeDrawerOpen(true);
  };

  const resetCancelImport = () => {
    // setIsImportTradeInProcess(false);
    // setImportTradeCurrentSteps(null);
    backtoMainScreen();
  };

  // Import: upload file & set list
  const importTradeFileUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await importTradeSendFile(formData).unwrap();
      setPreviewModalOpen(true);
      setImportListData(response);
      setImportTradeCurrentSteps(ImportTradeSteps.IMPORT_TRADE_PREVIEW_TABLE);
    } catch (error) {
      console.error("Error importing file", error);
    }
  };

  const onDownloadExcel = async (filtered) => {
    let payload = {};
    let product_id = PRODUCT_ID; // Product ID 1 for custome trade

    payload.search = filtered?.search;

    // add filter
    if (filtered.fromDate) {
      payload.date_range_from = dayjs(filtered.fromDate).format("YYYY/MM/DD");
    }
    if (filtered.toDate) {
      payload.date_range_to = dayjs(filtered.toDate).format("YYYY/MM/DD");
    }
    if (filtered.profitFrom) {
      payload.profit_range_from = filtered.profitFrom;
    }
    if (filtered.profitTo) {
      payload.profit_range_to = filtered.profitTo;
    }

    payload.noGlobalLoading = true; // restrics global loading.

    try {
      let file = await downloadExcelData({
        product_id: product_id,
        payload: payload,
      });
      const filename = [
        "Custom-trade-list",
        "Model-trade-list",
        "Algo-trade-list",
      ];
      if (file && file.data) {
        await clickAndDownloadFile({
          base64File: file.data,
          fileName: filename[product_id - 1],
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  // const handleSave = async () => {
  //   if (selectedRow) {
  //     // Create the payload in the required format
  //     const payload = {
  //       tradeIds: [
  //         {
  //           customer_id: selectedRow.customer_id,
  //           trade_id: selectedRow.trade_id,
  //           transaction_id: selectedRow.transaction_id,
  //           quantity: editableQuantity, // Updated quantity from the state
  //           product_type: selectedRow.product_type,
  //           order_type: selectedRow.order_type,
  //           target: selectedRow.target,
  //           stop_loss: selectedRow.stop_loss,
  //         },
  //       ],
  //     };

  //     try {
  //       let res = await updatePendingTrade({ payload });

  //       if (res.data && res.data.status == 1) {
  //         // Refetch or update the data here after save is successful
  //         const updatedData = await getTradeTableUsingId({
  //           PRODUCT_ID,
  //           StackTradeId: StackTradeId,
  //         });

  //         if (updatedData && updatedData.data && updatedData.data.result) {
  //           const updatedTableData = updatedData.data.result;
  //           setTableData(updatedTableData); // Update table data here to reload CustomDataGrid
  //         }
  //         handleCloseModal();
  //       }
  //     } catch (error) {
  //       console.error("Error updating trade:", error);
  //     }
  //   }
  // };

  const handleCancelOrder = async (trade) => {
    const payload = {
      tradeIds: [
        {
          user_id: trade.customer_id, // Replace with the correct field from `trade`
          order_id: trade.order_id, // Replace with the correct field from `trade`
        },
      ],
    };

    // Call the API mutation
    try {
      const response = await cancelOrderApi(payload);
      backtoMainScreen();
    } catch (error) {
      console.error("Error canceling order:", error);
    }
  };

  const handleCancelOrderUpdate = async (trade) => {
    const payload = {
      tradeIds: [
        {
          customer_id: trade.customer_id, // Replace with the correct field from `trade`
          trade_id: trade.trade_id,
          transaction_id: trade.transaction_id, // Replace with the correct field from `trade`
        },
      ],
    };

    // Call the API mutation
    try {
      const response = await cancelOrderApiUpdate(payload);
      backtoMainScreen();
    } catch (error) {
      console.error("Error canceling order:", error);
    }
  };

  const handleCancelOrderSingle = async (trade) => {
    const payload = {
      tradeIds: [
        {
          customer_id: trade.customer_id, // Replace with the correct field from `trade`
          trade_id: trade.trade_id, // Replace with the correct field from `trade`
        },
      ],
    };

    // Call the API mutation
    try {
      const response = await cancelOrderApiSingle(payload);
      backtoMainScreen();
    } catch (error) {
      console.error("Error canceling order:", error);
    }
  };

  if (!StackList) {
    return <></>;
  }
  const ChipStyle = styled(Chip)(({ theme, riskprofile }) => {
    let backgroundColor;
    let color;
    if (riskprofile === "Aggressive") {
      backgroundColor = "#FFEDED";
      color = "#EB5757";
    } else if (riskprofile === "Moderate") {
      backgroundColor = "#FFF5E0";
      color = "#E58026";
    } else if (riskprofile === "Conservative") {
      backgroundColor = "#DEFFEC";
      color = "#219653";
    }

    return {
      display: "flex",
      alignItems: "center",
      borderRadius: "152px",
      backgroundColor: backgroundColor,
      color: color,
      fontSize: "12px",
      padding: "8px",
      fontWeight: 500,
      "&::before": {
        position: "relative",
        left: 5,
        bottom: 6,
        content: '"\\2022"',
        width: "6px",
        height: "6px",
        color: color,
      },
    };
  });
  const modelTableColumns = [
    {
      field: "stockName",
      headerName: "Stock name",
      flex: 1,
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      flex: 1,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 1,
    },
    {
      field: "buyDate",
      headerName: "Buy Date",
      flex: 1,
    },
    {
      field: "sellDate",
      headerName: "Sell Date",
      flex: 1,
    },
    {
      field: "amountToInvest",
      headerName: "Amount Invested",
      flex: 1,
    },
    {
      field: "totalGains",
      headerName: "Total Gains",
      flex: 1,
    },
    {
      field: "amountAfterGains",
      headerName: "Amount After Gains",
      flex: 1,
    },
    {
      field: "todaysChange",
      headerName: "Todays Change",
      flex: 1,
    },
    // {
    //   field: "taxPl",
    //   headerName: "Tax Pl",
    //   flex: 1,
    // },
    {
      field: "riskProfile",
      headerName: "Selected Risk Profile",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" textAlign="center">
          {params?.row?.riskProfile ? (
            <ChipStyle
              style={{ cursor: "pointer" }}
              label={params?.row?.riskProfile}
              size="small"
              riskprofile={params?.row?.riskProfile}
            />
          ) : (
            ""
          )}
        </Stack>
      ),
    },
  ];

  const selectedTradeColumns = [
    {
      field: "customer_name",
      renderHeader: () => (
        <Box sx={{ textAlign: "center", lineHeight: 1.2 }}>
          Customer <br /> Name
        </Box>
      ),
      flex: 1,
      renderCell: (p) => {
        return (
          <Tooltip title={p.row.customer_name}>
            <Box sx={{ color: p.row.status == 3 ? "#a4a4a4" : "" }}>
              {p.row.customer_name}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "broker_name",
      renderHeader: () => (
        <Box sx={{ textAlign: "center", lineHeight: 1.2 }}>
          Broker <br /> Name
        </Box>
      ),
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.row.broker_name}>
          <Stack direction="column">
            <Text
              color="#6F6F6F"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.broker_name}
            </Text>
          </Stack>
        </Tooltip>
      ),
      editable: true,
    },
    {
      field: "login_id",
      headerName: "UCC",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.row.login_id}>
          <Stack direction="column">
            <Text
              color="#6F6F6F"
              fontWeight="500"
              fontSize="12px"
              alignItems="textCenter"
            >
              {params?.row?.login_id}
            </Text>
          </Stack>
        </Tooltip>
      ),
      editable: false,
    },
    // {
    //   field: "risk_name",
    //   headerName: "Risk Profile Name",
    //   flex: 1,
    //   renderCell: (params) => (
    //     <Tooltip title={params.row.risk_profile_name}>
    //       <Stack direction="column">
    //         <Text
    //           color="#6F6F6F"
    //           fontWeight="500"
    //           fontSize="12px"
    //           alignItems="textCenter"
    //         >
    //           {params?.row?.risk_profile_name}
    //         </Text>
    //       </Stack>
    //     </Tooltip>
    //   ),
    //   editable: false,
    // },
    {
      field: "symbol",
      renderHeader: () => (
        <Box sx={{ textAlign: "center", lineHeight: 1.2 }}>
          Stock <br /> Name
        </Box>
      ),
      flex: 1,
      renderCell: (p) => {
        return (
          <Tooltip title={p.row.symbol}>
            <Box sx={{ color: p.row.status == 3 ? "#a4a4a4" : "" }}>
              {p.row.symbol}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (p) => {
        return (
          <Tooltip title={p.row.action}>
            <Box sx={{ color: p.row.status == 3 ? "#a4a4a4" : "" }}>
              {p.row.action}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "exchange",
      headerName: "Segment",
      flex: 1,
      renderCell: (p) => {
        return (
          <Tooltip title={p.row.exchange}>
            <Box sx={{ color: p.row.status == 3 ? "#a4a4a4" : "" }}>
              {p.row.exchange}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "order_type",
      renderHeader: () => (
        <Box sx={{ textAlign: 'center', lineHeight: 1.2 }}>
          Order <br /> Type
        </Box>
      ),
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Tooltip title={row.order_type}>
            <Stack direction="row" alignItems="center">
              <Text
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  color: row.status == 3 ? "#a4a4a4" : "",
                }}
              >
                {row.order_type.length > 0
                  ? row.order_type.charAt(0).toUpperCase() +
                    row.order_type.slice(1).toLowerCase()
                  : ""}
              </Text>
            </Stack>
          </Tooltip>
        );
      },
    },
    {
      field: "quantity",
      headerName: PRODUCT_ID === 1 ? "Quantity" : "LOT",
      flex: 1,
      renderCell: (p) => {
        return (
          <Tooltip
            title={`${p.row.quantity} ${
              p.row.lot_size ? ` X ${p.row.lot_size}` : ""
            }`}
          >
            <Box sx={{ color: p.row.status == 3 ? "#a4a4a4" : "" }}>
              {p.row.quantity} {p.row.lot_size ? ` X ${p.row.lot_size}` : ""}
            </Box>
          </Tooltip>
        );
      },
    },
    ...(PRODUCT_ID === 3
      ? [
          {
            field: "total_quantity",
            renderHeader: () => (
              <Box sx={{ textAlign: 'center', lineHeight: 1.2 }}>
                Total <br /> Quantity
              </Box>
            ),
            flex: 1,
            renderCell: (p) => {
              return (
                <Tooltip title={p.row.total_quantity || 0}>
                  <Box sx={{ color: p.row.status == 3 ? "#a4a4a4" : "" }}>
                    {p.row.total_quantity || 0}
                  </Box>
                </Tooltip>
              );
            },
          },
        ]
      : []),
    {
      field: "price",
      headerName: "price",
      flex: 1,
      renderCell: (p) => {
        return (
          <Tooltip title={p.row.price}>
            <Box sx={{ color: p.row.status == 3 ? "#a4a4a4" : "" }}>
              {p.row.price}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "stop_loss",
      renderHeader: () => (
        <Box sx={{ textAlign: 'center', lineHeight: 1.2 }}>
          Stop <br /> Loss
        </Box>
      ),
      flex: 1,
      renderCell: (p) => {
        return (
          <Tooltip title={p.row.stop_loss}>
            <Box sx={{ color: p.row.status == 3 ? "#a4a4a4" : "" }}>
              {p.row.stop_loss}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "target",
      headerName: "Target",
      flex: 1,
      renderCell: (p) => {
        return (
          <Tooltip title={p.row.target}>
            <Box sx={{ color: p.row.status == 3 ? "#a4a4a4" : "" }}>
              {p.row.target}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "amount_invested",
      headerName: "Amount",
      flex: 1,
      renderCell: (p) => {
        return (
          <Tooltip title={p.row.amount_invested}>
            <Box sx={{ color: p.row.status == 3 ? "#a4a4a4" : "" }}>
              {p.row.amount_invested}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "tradeStatus",
      renderHeader: () => (
        <Box sx={{ textAlign: 'center', lineHeight: 1.2 }}>
          Trade <br /> Status
        </Box>
      ),
      flex: 1,
      renderCell: (p) => {
        return (
          <Tooltip title={p.row.tradeStatus}>
            <Box sx={{ color: p.row.status == 3 ? "#a4a4a4" : "" }}>
              {p.row.tradeStatus}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "message",
      headerName: "Message",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.row.message} arrow>
          <Box sx={{ color: params.row.status === 3 ? "#a4a4a4" : "inherit" }}>
            {params.row.message}
          </Box>
        </Tooltip>
      ),
    },
    {
      field: "risk_profile_name",
      renderHeader: () => (
        <Box sx={{ textAlign: 'center', lineHeight: 1.2 }}>
          Risk <br /> Profile
        </Box>
      ),
      flex: 1,
      renderCell: ({ row }) => {
        const { bgColor, textColor } = getRiskProfileChipColor(
          row.risk_profile_id
        );
        return (
          <Tooltip title={row.risk_profile_name}>
            <Stack direction="row" alignItems="center">
              <Chip
                label={row.risk_profile_name}
                sx={{
                  backgroundColor: bgColor,
                  color: textColor,
                  borderRadius: "152px",
                  height: "24px",
                  opacity: row.status == 3 ? "0.5" : "",
                }}
              />
            </Stack>
          </Tooltip>
        );
      },
    },
    {
      field: "remaining_quantity",
      renderHeader: () => (
        <Box sx={{ textAlign: 'center', lineHeight: 1.2 }}>
          Remaining <br /> Quantity
        </Box>
      ),
      flex: 1,
      renderCell: (p) => {
        return (
          <Tooltip title={p.row.remaining_quantity} placement="top">
            <Box sx={{ color: p.row.status == 3 ? "#a4a4a4" : "" }}>
              {p.row.remaining_quantity}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "sold_quantity",
      renderHeader: () => (
        <Box sx={{ textAlign: 'center', lineHeight: 1.2 }}>
          Traded <br /> Quantity
        </Box>
      ),
      flex: 1,
      renderCell: (p) => {
        return (
          <Tooltip title={p.row.sold_quantity} placement="top">
            <Box sx={{ color: p.row.status == 3 ? "#a4a4a4" : "" }}>
              {p.row.sold_quantity}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "cancel_or_cancel_order",
      renderHeader: () => (
        <Box sx={{ textAlign: 'center', lineHeight: 1.2 }}>
          Cancel <br /> Order
        </Box>
      ),
      flex: 1,
      renderCell: (params) => {
        const { order_type, tradeStatus } = params.row;

        // Conditional rendering based on tradeStatus
        if (tradeStatus === "INPROGRESS") {
          return (
            <IconButton>
              <CancelIcon
                variant="contained"
                onClick={() => handleCancelOrder(params.row)}
                disabled={
                  !(
                    (order_type === "LIMIT" && tradeStatus === "PENDING") ||
                    tradeStatus === "INPROGRESS"
                  )
                }
              >
                Cancel
              </CancelIcon>
            </IconButton>
          );
        } else if (tradeStatus === "PENDING") {
          return (
            <IconButton>
              <CancelIcon
                variant="contained"
                onClick={() => handleCancelOrderSingle(params.row)}
              >
                Cancel
              </CancelIcon>
            </IconButton>
          );
        }
      },
    },

    {
      field: "modify_order",
      renderHeader: () => (
        <Box sx={{ textAlign: 'center', lineHeight: 1.2 }}>
          Modify <br /> Order
        </Box>
      ),
      flex: 1,
      renderCell: (params) => {
        const { order_type, tradeStatus } = params.row;
        return (
          <IconButton>
            <EditIcon
              variant="contained"
              onClick={() => handleOpenModal(params.row)}
              disabled={
                !(
                  (order_type === "LIMIT" && tradeStatus === "PENDING") ||
                  tradeStatus === "INPROGRESS"
                )
              }
            >
              Modify
            </EditIcon>
          </IconButton>
        );
      },
    },

    // {
    //   // field: "Action",
    //   headerName: "Action",
    //   flex: 1,
    //   renderCell: (trade) => (
    //     <Stack direction="row" alignItems="center">
    //       <EditIcon
    //         onClick={() => onViewDetail(trade.row.trade_id)}
    //         style={{ cursor: "pointer" }}
    //       />
    //     </Stack>
    //   ),
    // },
  ];

  const selectedTradeColumnsUpdate = [
    {
      field: "customer_name",
      renderHeader: () => (
        <Box sx={{ textAlign: "center", lineHeight: 1.2 }}>
          Customer <br /> Name
        </Box>
      ),
      flex: 1,
      renderCell: (p) => {
        return (
          <Tooltip title={p.row.customer_name}>
            <Box sx={{ color: p.row.status == 3 ? "#a4a4a4" : "" }}>
              {p.row.customer_name}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "broker_name",
      renderHeader: () => (
        <Box sx={{ textAlign: "center", lineHeight: 1.2 }}>
          Broker <br /> Name
        </Box>
      ),
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.row.broker_name}>
          <Stack direction="column">
            <Text
              color="#6F6F6F"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.broker_name}
            </Text>
          </Stack>
        </Tooltip>
      ),
      editable: true,
    },
    {
      field: "login_id",
      headerName: "UCC",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.row.login_id}>
          <Stack direction="column">
            <Text
              color="#6F6F6F"
              fontWeight="500"
              fontSize="12px"
              alignItems="textCenter"
            >
              {params?.row?.login_id}
            </Text>
          </Stack>
        </Tooltip>
      ),
      editable: false,
    },
    // {
    //   field: "risk_name",
    //   headerName: "Risk Profile Name",
    //   flex: 1,
    //   renderCell: (params) => (
    //     <Tooltip title={params.row.risk_profile_name}>
    //       <Stack direction="column">
    //         <Text
    //           color="#6F6F6F"
    //           fontWeight="500"
    //           fontSize="12px"
    //           alignItems="textCenter"
    //         >
    //           {params?.row?.risk_profile_name}
    //         </Text>
    //       </Stack>
    //     </Tooltip>
    //   ),
    //   editable: false,
    // },
    {
      field: "symbol",
      renderHeader: () => (
        <Box sx={{ textAlign: "center", lineHeight: 1.2 }}>
          Stock <br /> Name
        </Box>
      ),
      flex: 1,
      renderCell: (p) => {
        return (
          <Tooltip title={p.row.symbol}>
            <Box sx={{ color: p.row.status == 3 ? "#a4a4a4" : "" }}>
              {p.row.symbol}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (p) => {
        return (
          <Tooltip title={p.row.action}>
            <Box sx={{ color: p.row.status == 3 ? "#a4a4a4" : "" }}>
              {p.row.action}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "exchange",
      headerName: "Segment",
      flex: 1,
      renderCell: (p) => {
        return (
          <Tooltip title={p.row.exchange}>
            <Box sx={{ color: p.row.status == 3 ? "#a4a4a4" : "" }}>
              {p.row.exchange}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "order_type",
      renderHeader: () => (
        <Box sx={{ textAlign: "center", lineHeight: 1.2 }}>
          Header <br /> Name
        </Box>
      ),
      renderCell: ({ row }) => {
        return (
          <Tooltip title={row.order_type}>
            <Stack direction="row" alignItems="center">
              <Text
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  color: row.status == 3 ? "#a4a4a4" : "",
                }}
              >
                {row.order_type.length > 0
                  ? row.order_type.charAt(0).toUpperCase() +
                    row.order_type.slice(1).toLowerCase()
                  : ""}
              </Text>
            </Stack>
          </Tooltip>
        );
      },
      flex: 1,
    },
    {
      field: "quantity",
      headerName: PRODUCT_ID === 1 ? "Quantity" : "LOT",
      flex: 1,
      renderCell: (p) => {
        return (
          <Tooltip
            title={`${p.row.quantity} ${
              p.row.lot_size ? ` X ${p.row.lot_size}` : ""
            }`}
          >
            <Box sx={{ color: p.row.status == 3 ? "#a4a4a4" : "" }}>
              {p.row.quantity} {p.row.lot_size ? ` X ${p.row.lot_size}` : ""}
            </Box>
          </Tooltip>
        );
      },
    },
    ...(PRODUCT_ID === 3
      ? [
          {
            field: "total_quantity",
            renderHeader: () => (
              <Box sx={{ textAlign: "center", lineHeight: 1.2 }}>
                Total <br /> Quantity
              </Box>
            ),
            flex: 1,
            renderCell: (p) => {
              return (
                <Tooltip title={p.row.total_quantity || 0}>
                  <Box sx={{ color: p.row.status == 3 ? "#a4a4a4" : "" }}>
                    {p.row.total_quantity || 0}
                  </Box>
                </Tooltip>
              );
            },
          },
        ]
      : []),
    {
      field: "price",
      headerName: "price",
      flex: 1,
      renderCell: (p) => {
        return (
          <Tooltip title={p.row.price}>
            <Box sx={{ color: p.row.status == 3 ? "#a4a4a4" : "" }}>
              {p.row.price}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "stop_loss",
      renderHeader: () => (
        <Box sx={{ textAlign: "center", lineHeight: 1.2 }}>
          Stop <br /> Loss
        </Box>
      ),
      flex: 1,
      renderCell: (p) => {
        return (
          <Tooltip title={p.row.stop_loss}>
            <Box sx={{ color: p.row.status == 3 ? "#a4a4a4" : "" }}>
              {p.row.stop_loss}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "target",
      headerName: "Target",
      flex: 1,
      renderCell: (p) => {
        return (
          <Tooltip title={p.row.target}>
            <Box sx={{ color: p.row.status == 3 ? "#a4a4a4" : "" }}>
              {p.row.target}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "amount_invested",
      headerName: "Amount",
      flex: 1,
      renderCell: (p) => {
        return (
          <Tooltip title={p.row.amount_invested}>
            <Box sx={{ color: p.row.status == 3 ? "#a4a4a4" : "" }}>
              {p.row.amount_invested}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "tradeStatus",
      renderHeader: () => (
        <Box sx={{ textAlign: "center", lineHeight: 1.2 }}>
          Trade <br /> Status
        </Box>
      ),
      flex: 1,
      renderCell: (p) => {
        return (
          <Tooltip title={p.row.tradeStatus}>
            <Box sx={{ color: p.row.status == 3 ? "#a4a4a4" : "" }}>
              {p.row.tradeStatus}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "message",
      headerName: "Message",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.row.message} arrow>
          <Box sx={{ color: params.row.status === 3 ? "#a4a4a4" : "inherit" }}>
            {params.row.message}
          </Box>
        </Tooltip>
      ),
    },
    {
      field: "risk_profile_name",
      renderHeader: () => (
        <Box sx={{ textAlign: "center", lineHeight: 1.2 }}>
          Risk <br /> Profile
        </Box>
      ),
      renderCell: ({ row }) => {
        const { bgColor, textColor } = getRiskProfileChipColor(
          row.risk_profile_id
        );
        return (
          <Tooltip title={row.risk_profile_name}>
            <Stack direction="row" alignItems="center">
              <Chip
                label={row.risk_profile_name}
                sx={{
                  backgroundColor: bgColor,
                  color: textColor,
                  borderRadius: "152px",
                  height: "24px",
                  opacity: row.status == 3 ? "0.5" : "",
                }}
              />
            </Stack>
          </Tooltip>
        );
      },
      flex: 1,
    },
    {
      field: "remaining_quantity",
      renderHeader: () => (
        <Box sx={{ textAlign: "center", lineHeight: 1.2 }}>
          Remaining <br /> Quantity
        </Box>
      ),
      flex: 1,
      renderCell: (p) => {
        return (
          <Tooltip title={p.row.remaining_quantity} placement="top">
            <Box sx={{ color: p.row.status == 3 ? "#a4a4a4" : "" }}>
              {p.row.remaining_quantity}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "sold_quantity",
      renderHeader: () => (
        <Box sx={{ textAlign: "center", lineHeight: 1.2 }}>
          Traded <br /> Quantity
        </Box>
      ),
      flex: 1,
      renderCell: (p) => {
        return (
          <Tooltip title={p.row.sold_quantity} placement="top">
            <Box sx={{ color: p.row.status == 3 ? "#a4a4a4" : "" }}>
              {p.row.sold_quantity}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "cancel_or_cancel_order",
      renderHeader: () => (
        <Box sx={{ textAlign: "center", lineHeight: 1.2 }}>
          Cancel <br /> Order
        </Box>
      ),
      flex: 1,
      renderCell: (params) => {
        const { order_type, tradeStatus } = params.row;

        // Conditional rendering based on tradeStatus
        if (tradeStatus === "INPROGRESS") {
          return (
            <IconButton>
              <CancelIcon
                variant="contained"
                onClick={() => handleCancelOrder(params.row)}
                disabled={
                  !(
                    (order_type === "LIMIT" && tradeStatus === "PENDING") ||
                    tradeStatus === "INPROGRESS"
                  )
                }
              >
                Cancel
              </CancelIcon>
            </IconButton>
          );
        } else if (tradeStatus === "PENDING") {
          return (
            <IconButton>
              <CancelIcon
                variant="contained"
                onClick={() => handleCancelOrderUpdate(params.row)}
              >
                Cancel
              </CancelIcon>
            </IconButton>
          );
        }
      },
    },

    {
      field: "modify_order",
      renderHeader: () => (
        <Box sx={{ textAlign: "center", lineHeight: 1.2 }}>
          Modify <br /> Order
        </Box>
      ),
      flex: 1,
      renderCell: (params) => {
        const { order_type, tradeStatus } = params.row;
        if (tradeStatus === "PENDING") {
          return (
            <IconButton>
              <EditIcon
                variant="contained"
                onClick={() => handleOpenModal(params.row)}
              >
                Modify
              </EditIcon>
            </IconButton>
          );
        } else if (tradeStatus === "INPROGRESS") {
          return (
            <IconButton>
              <EditIcon
                variant="contained"
                onClick={() => handleOpenModalUpdate(params.row)}
                disabled={
                  !(
                    (order_type === "LIMIT" && tradeStatus === "PENDING") ||
                    tradeStatus === "INPROGRESS"
                  )
                }
              >
                Modify
              </EditIcon>
            </IconButton>
          );
        }
      },
    },

    // {
    //   // field: "Action",
    //   headerName: "Action",
    //   flex: 1,
    //   renderCell: (trade) => (
    //     <Stack direction="row" alignItems="center">
    //       <EditIcon
    //         onClick={() => onViewDetail(trade.row.trade_id)}
    //         style={{ cursor: "pointer" }}
    //       />
    //     </Stack>
    //   ),
    // },
  ];
  // -------> Start

  const filteredTradeColumns = selectedTradeColumns.filter(
    (column) => column.field !== "modify_order"
  );

  const filteredModifyTradeColumns = selectedTradeColumnsUpdate.filter(
    (column) => column.field !== "modify_order"
  );

  return (
    <>
      <SideDrawer
        open={isEditTradeDrawerOpen}
        closeDrawer={() => {
          setEditIsTradeDrawerOpen(false);
        }}
        title={isImportTradeInitiated ? "Import Trades" : "Edit Trades"}
        subtitle={
          isImportTradeInitiated
            ? "Here you can import trade for the customers"
            : "Here you can edit trade for the customers"
        }
        drawerWidth={500}
        showActionContainer={false}
      >
        <AddTradeDrawer
          primaryAction={
            currentStep === 2
              ? () => {
                  setCurrentStep(1);
                }
              : () => {
                  setEditIsTradeDrawerOpen(false);
                }
          }
          handleSubmit={
            currentStep === 1
              ? () => {
                  setCurrentStep(2);
                }
              : handleSubmit
          }
          prefilledData={fetchedData}
          cancelButtonText={currentStep === 1 ? "Cancel" : "Back"}
          submitButtonText={currentStep === 2 ? "Done" : "Continue"}
          currentStep={currentStep}
          PRODUCT_ID={PRODUCT_ID}
          isImportTradeInitiated={isImportTradeInitiated}
          initateImportTrade={handleImportButtonClick}
        />
      </SideDrawer>
      <SideDrawer
        open={isEditTradeUpdateDrawerOpen}
        closeDrawer={() => {
          setEditIsTradeUpdateDrawerOpen(false);
        }}
        title="Edit Trades"
        subtitle="Here you can edit trade for the customers"
        drawerWidth={500}
        showActionContainer={false}
      >
        <AddTradeDrawer
          primaryAction={
            currentStep === 2
              ? () => {
                  setCurrentStep(1);
                }
              : () => {
                  setEditIsTradeUpdateDrawerOpen(false);
                }
          }
          handleSubmit={
            currentStep === 1
              ? () => {
                  setCurrentStep(2);
                }
              : handleEditSingleTradeUpdate
          }
          prefilledData={fetchedData}
          cancelButtonText={currentStep === 1 ? "Cancel" : "Back"}
          submitButtonText={currentStep === 2 ? "Done" : "Continue"}
          currentStep={currentStep}
          PRODUCT_ID={PRODUCT_ID}
        />
      </SideDrawer>
      <div>
        {/* Your other component code */}

        {warningMessage && (
          <WarningPopup
            message={warningMessage}
            apiResponse={apiResponse}
            onClose={() => setWarningMessage("")}
          />
        )}
      </div>
      {/* Trade Listing  */}
      {checkTradePending && (
        <TradeUpdateStackTable
          customerColumns={selectedTradeColumnsUpdate}
          onRowSelectionModelChange={handleCustomerChangeInAddSingleTrade}
          selectedRows={addSingleTradeSelectedDetails}
          StackList={StackList}
          backtoMainScreen={backtoMainScreen}
          productId={PRODUCT_ID}
          setFetchedData={setFetchedData}
          // onEditIsTradeDrawerOpen={onEditIsTradeDrawerOpen}
          onEditUpdateIsTradeDrawerOpen={onEditUpdateIsTradeDrawerOpen}
          key={reloadKey}
          open={open}
          handleCloseModal={handleCloseModal}
          openUpdate={openUpdate}
          handleCloseModalUpdate={handleCloseModalUpdate}
          selectedRow={selectedRow}
          setEditableQuantity={setEditableQuantity}
          editableQuantity={editableQuantity}
          editablePrice={editablePrice}
          editableStopLoss={editableStopLoss}
          setEditablePrice={setEditablePrice}
          setEditableStopLoss={setEditableStopLoss}
        />
      )}
      {showTradesDetailsTable && (
        <SelectCustomerSendCallsTable
          customerColumns={selectedTradeColumns}
          onRowSelectionModelChange={handleCustomerChangeInAddSingleTrade}
          selectedRows={addSingleTradeSelectedDetails}
          handleStackCall={sendSingleTradeCalls}
          StackList={StackList}
          backtoMainScreen={backtoMainScreen}
          productId={PRODUCT_ID}
          isEditTradeDrawerOpen={isEditTradeDrawerOpen}
          onEditIsTradeDrawerOpen={onEditIsTradeDrawerOpen}
          updateTableListData={updateTableListData}
          setFetchedData={setFetchedData}
          key={reloadKey}
          open={open}
          handleCloseModal={handleCloseModal}
          selectedRow={selectedRow}
          setEditableQuantity={setEditableQuantity}
          editableQuantity={editableQuantity}
        />
      )}
      {showTradesUpdateDetailsTable && (
        <TradeUpdateCallDetailTable
          // callPendingData={callPendingData}
          customerColumns={selectedTradeColumnsUpdate}
          trade_id={tradeUpdateId}
          onRowSelectionModelChange={handleCustomerChangeInAddSingleTrade}
          StackList={StackList}
          productId={PRODUCT_ID}
          backtoMainScreen={backtoMainScreen}
        />
      )}
      {!(
        addTradeCurrentStep === AddSingleTradeStep.CUSTOMER_DETAIL_TABLE ||
        addTradeCurrentStep === AddSingleTradeStep.ADD_TRADE_UPDATE_CALL ||
        importTradeCurrentStep
      ) && (
        <Box mt={2.5} mb={4}>
          {/* Trads Stacks */}
          {PRODUCT_ID != 2 ? (
            <TradesCountStacks
              pendingTradeCallStackList={pendingTradeCallStackList}
              pendingCallStackList={pendingCallStackList}
              stepToSendCall={stepToSendCall}
              pendingTradeCheck={pendingTradeCheck}
              stackListFetching={stackListFetching}
            />
          ) : null}

          {/* Data Listing */}
          <Card>
            <TradeHeader
              handleCustomFilterDrawer={handleCustomFilterDrawer}
              handleTradeDrawerOpen={handleTradeDrawerOpen}
              handleSearch={(e) =>
                handleFilterDataChange({
                  name: "search",
                  value: e?.target?.value,
                })
              }
              initateImportTrade={initateImportTrade}
              filterData={filterData}
              totalRecord={tradesTotalRecord}
              PRODUCT_ID={PRODUCT_ID}
              onDownloadExcel={() => onDownloadExcel(filterData)}
              handleSquareOff={handleSquareOff}
              tradesDetailsList={tradesDetails}
              selectedRows={selectedRows}
              sqareOffApiLoading={sqareOffApiLoading}
              handleImportButtonClick={handleImportButtonClick}
            />
            <CustomDataGrid
              list={tradesDetails}
              columnHeaderHeight={46}
              rowHeight={46}
              columns={modelTableColumns}
              count={tradesDetails.length}
              loading={tradeLoading}
              sx_wrapper={{
                borderRadius: "0px",
                "& .MuiDataGrid-columnHeaders": {
                  borderRadius: "0px",
                  backgroundColor: "red",
                },
              }}
              pagination
              rowCount={tradesTotalRecord}
              paginationMode="server"
              paginationModel={tradesPaginationModel}
              onPaginationModelChange={setTradesPaginationModel}
              selectedRows={selectedRows.map((row) => row.id)}
              onRowSelectionModelChange={handleRowSelection}
              checkboxSelection={showCheckboxes} // Ensure this is set to true when checkboxes are needed
              isRowSelectable={({ columns, id, row }) => {
                console.log("isRowSelectable -> ", id, row.sqaureOffStatus);
                if (
                  row &&
                  row.sqaureOffStatus &&
                  row.sqaureOffStatus === SQUARE_OFF_STATUS.PENDING
                ) {
                  return true;
                }
                return false;
              }}
            />
          </Card>
        </Box>
      )}
      {/* Drawer: Add Filter */}
      <SideDrawer
        open={isCustomFilterDrawerOpen}
        closeDrawer={handleCustomFilterDrawer}
        title="Add Filters"
        subtitle="See the data in an organized manner by applying filters"
        handleSubmit={() => getAndSetTradeListingData()}
        cancelButtonText="Clear all"
        submitButtonText="Apply"
        drawerWidth={500}
        primaryAction={() => handleFilterDataChange({ clearAll: 1 })}
      >
        <CustomAddFilter
          handleChange={handleFilterDataChange}
          filterData={filterData}
        />
      </SideDrawer>
      {/* Drawer: Update Trade */}
      <ActiveTradeDrawer isOpen={false} onClose={() => {}} />
      {isImportTradeInProcess ? (
        <>
          {/* Modal: Trades Import  */}
          {importTradeCurrentStep === ImportTradeSteps.IMPORT_FILE_MODAL && (
            <TradeImportModal
              isOpen={
                importTradeCurrentStep === ImportTradeSteps.IMPORT_FILE_MODAL
              }
              handleClose={resetCancelImport}
              handleSave={importTradeFileUpload}
              importTradeResponse={importTradeResponse}
            />
          )}

          {/* Modal: Import Trade Preview */}
          {importTradeCurrentStep ===
            ImportTradeSteps.IMPORT_TRADE_PREVIEW_TABLE && (
            <TradesPreviewModal
              isOpen={true}
              ProceedToSelectRiskProfile={ProceedToSelectRiskProfile}
              handleClose={() => backToImportModal()}
              handleCancel={() => backToImportModal()}
              importListData={importListData}
              importValidateData={importValidateData}
              validationMessage={validationMessage}
            />
          )}

          {/* Modal: Import Trade Risk Profile confirmation */}
          {importTradeCurrentStep ===
            ImportTradeSteps.IMPORT_RISK_PROFILE_MODAL && (
            <RiskProfileModal
              isOpen={
                importTradeCurrentStep ===
                ImportTradeSteps.IMPORT_RISK_PROFILE_MODAL
              }
              handleClose={() => {}}
              // handleRiskProfileClick={() => {}}
              backToPreviewTable={backToPreviewTable}
              proceedToImportTrade={proceedToImportTrade}
              prefilledData={fetchedData}
              PRODUCT_ID={PRODUCT_ID}
              ImportTradeSteps={ImportTradeSteps}
              setImportTradeCurrentSteps={setImportTradeCurrentSteps}
              setPreviewModalOpen={setPreviewModalOpen}
              setImportTradeResponse={handleProceedToImportTrade}
            />
          )}

          {importTradeCurrentStep ===
            ImportTradeSteps.CUSTOMER_DETAILS_TABLE && (
            <SelectCustomerSendCallsTable
              customerColumns={filteredTradeColumns}
              data={newAddedTradeDetails?.customers}
              onRowSelectionModelChange={handleCustomerChangeInAddSingleTrade}
              selectedRows={addSingleTradeSelectedDetails}
              handleSendCalls={sendMultipleTradeCalls}
              productId={PRODUCT_ID}
              mode="IMPORT"
            />
          )}
        </>
      ) : (
        <></>
      )}{" "}
      {/* Import Trades Components Ends Here */}
      {/*----- Add Single Trade Components Starts Here -----*/}
      {/* Side Drwer: Add Single Trade Form */}
      {isTradeDrawerOpen ? (
        <>
          {addTradeCurrentStep === AddSingleTradeStep.ADD_TRADE_FORM && (
            <SideDrawer
              open={isTradeDrawerOpen}
              closeDrawer={() => {
                setIsTradeDrawerOpen(false);
              }}
              title="Add Trades"
              subtitle="Here you can add trade for the customers"
              drawerWidth={500}
              showActionContainer={false}
            >
              <AddTradeDrawer
                primaryAction={
                  currentStep === 2
                    ? () => {
                        setCurrentStep(1);
                      }
                    : () => {
                        setIsTradeDrawerOpen(false);
                      }
                }
                handleSubmit={
                  currentStep === 1
                    ? () => {
                        setCurrentStep(2);
                      }
                    : handleAddSingleTrade
                }
                cancelButtonText={currentStep === 1 ? "Cancel" : "Back"}
                submitButtonText={currentStep === 2 ? "Done" : "Continue"}
                currentStep={currentStep}
                PRODUCT_ID={PRODUCT_ID}
              />
            </SideDrawer>
          )}

          {/*Table to show Select customer with stocks for trades */}
          {addTradeCurrentStep === AddSingleTradeStep.CUSTOMER_DETAIL_TABLE && (
            <SelectCustomerSendCallsTable
              customerColumns={filteredTradeColumns}
              data={newAddedTradeDetails?.customers}
              onRowSelectionModelChange={handleCustomerChangeInAddSingleTrade}
              selectedRows={addSingleTradeSelectedDetails}
              handleSendCalls={sendSingleTradeCalls}
              productId={PRODUCT_ID}
              mode="SINGLE"
              parseAddSingleTradeResponse={handleAddSingleTrade}
              result={result} 
              // StackList={StackList}
            />
          )}

          {addTradeCurrentStep === AddSingleTradeStep.ADD_TRADE_UPDATE_CALL && (
            <TradeUpdateCallDetailTable
              // callPendingData={callPendingData}
              customerColumns={filteredModifyTradeColumns}
              trade_id={newAddedTradeDetails.trade_id}
              data={newAddedTradeDetails.customers}
              onRowSelectionModelChange={handleCustomerChangeInAddSingleTrade}
              productId={PRODUCT_ID}
              backtoMainScreen={backtoMainScreen}
              // selectedRows={addSingleTradeSelectedDetails}
              StackList={StackList}
            />
          )}
        </>
      ) : (
        <></>
      )}{" "}
      {/* Add Single Trade Components ends Here */}
    </>
  );
};

export default TradeContainer;
