import React, { useState, useEffect } from "react";
import Stepper from "components/common/Stepper";
import Box from "components/common/Box";
import { styled } from "@mui/material";
import { onboardingSteps } from "helpers/constants";
import CustomerDetailsContainer from "./CustomerDetailsContainer";
import RiskAssesmentContainer from "./RiskAssesmentContainer";
import InvestmentDetailsContainer from "./InvestmentDetailsContainer";
import OtherDetailsContainer from "./OtherDetailsContainer";
import SummaryContainer from "./SummaryContainer";
import { useLocation } from "react-router-dom";

const StepperBackground = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  maxWidth: "calc(100% + 48px)",
  margin: "0 -24px",
  padding: "16px 94px",
}));

const AddCustomerContainer = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [currentUserId, setCurrentUserId] = useState(null);

  const handleStepChange = (nextStep, UserId) => {
    setCurrentStep(nextStep);
    if (UserId) {
      setCurrentUserId(UserId);
    }
  };

  const location = useLocation();

  const onBoardData = location.state && location.state.onBoardData;

  useEffect(() => {
    if (onBoardData && Object.keys(onBoardData).length > 0) {
      const currentStage = onBoardData.current_stage;
      if (onBoardData && onBoardData?.user_id) {
        setCurrentUserId(onBoardData?.user_id);
      }
      if (currentStage === 4) {
        setCurrentStep(4);
      } else if (currentStage === 3 || currentStage === 2) {
        setCurrentStep(3);
      } else if (
        currentStage === 1 &&
        onBoardData?.is_email_verified === "Y" &&
        onBoardData?.is_mobile_verified === "Y"
      ) {
        setCurrentStep(3);
      } else if (
        (currentStage === 1 && onBoardData?.is_email_verified === "N") ||
        onBoardData?.is_mobile_verified === "N"
      ) {
        setCurrentStep(1);
      } else {
        setCurrentStep(1);
      }
    }
  }, [onBoardData]);

  return (
    <>
      {currentStep <= 4 ? (
        <>
          <StepperBackground>
            <Stepper
              stepperData={onboardingSteps}
              currentStepsId={currentStep}
            />
          </StepperBackground>
          <Box mt={7}>
            {currentStep === 1 && (
              <CustomerDetailsContainer
                handleStepChange={handleStepChange}
                currentStep={currentStep}
                currentUserId={currentUserId}
              />
            )}
            {currentStep === 2 && (
              <RiskAssesmentContainer
                handleStepChange={handleStepChange}
                currentUserId={currentUserId}
              />
            )}
            {currentStep === 3 && (
              <InvestmentDetailsContainer
                handleStepChange={handleStepChange}
                currentUserId={currentUserId}
              />
            )}
            {currentStep === 4 && (
              <OtherDetailsContainer
                handleStepChange={handleStepChange}
                currentUserId={currentUserId}
              />
            )}
          </Box>
        </>
      ) : (
        <>
          <SummaryContainer handleStepChange={handleStepChange} />
        </>
      )}
    </>
  );
};

export default AddCustomerContainer;
