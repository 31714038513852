import { useEffect, useState } from "react";
import Box from "components/common/Box";
import Text from "components/common/Text";
import CustomDataGrid from "components/datagrid/CustomGrid";
import StockTradeDrawer from "components/Trade/customStockTradeDrawer";
import { tradesApiAction } from "stores/redux/apiSlices/trades/tradesApiSlice";

// icon
import KeyboardBackspaceSharpIcon from '@mui/icons-material/KeyboardBackspaceSharp';


// Header component for displaying trade details
const ModelImportHeader = (props) => {
  const { 
    trade_id, 
    tradeDataForTableWithId,
    recallDataOnUpdate
   } = props;

  const getStacksCount = () => {
    let pending = tradeDataForTableWithId.filter( d => d.status );
    return {
      customerPending: pending.length || 0,
      totalcustomers: tradeDataForTableWithId.length || 0
    };
  }

  const {
    customerPending,
    totalcustomers
  } = getStacksCount();

 

  const [sideDrawerOpen1, setSideDrawerOpen1] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);

  const [updatedTradeId, setupdatedTradeId] = useState(null); // ID of the expanded trade

  // api 
  const [handleDataforUpdateCall] = tradesApiAction.getTradeUpdateData();

  // Function to fetch trade details and open side drawer
  const ViewDetailsRadio = async (selectedId) => {
    const id = selectedId || trade_id;
    setupdatedTradeId(id);
    if (!id) {
      console.error("No trade ID provided.");
      return;
    }

    const payload = { trades: [id] };
    try {
      const response = await handleDataforUpdateCall(payload);
      setApiResponse(response);
      setSideDrawerOpen1(true);
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Function to close side drawer
  const closeSideDrawer1 = () => {
    setSideDrawerOpen1(false);
  };
  

  return (
    <>
      <Box mt={3}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "16px 20px",
            mb: 2,
            ml: 2,
            mr: 2,
            backgroundColor: "#FFF5E0",
            border: "1px solid #F2994A",
            borderRadius: "8px",
          }}
        >
          <Text fontSize="16px" fontWeight="400" color="#242424">
            {customerPending} out of {totalcustomers} customers have taken the
            call for the trade
          </Text>

          <a href="#" onClick={() => ViewDetailsRadio(trade_id)}>
            View Details
          </a>
        </Box>
        {/* Side drawer for displaying trade details */}
        <StockTradeDrawer
          apiResponse={apiResponse}
          sideDrawerOpen1={sideDrawerOpen1}
          closeSideDrawer1={closeSideDrawer1}
          recallDataOnUpdate={() => (recallDataOnUpdate(), ViewDetailsRadio(updatedTradeId))}
        />
      </Box>
    </>
  );
};

// Table component for displaying trade update call details
const TradeUpdateCallDetailTable = (props) => {
  const {
    customerColumns,
    onRowSelectionModelChange,
    // callPendingData,
    trade_id,
    // StackList,
    productId,
    backtoMainScreen
  } = props;

  const [tradesPaginationModel, setTradesPaginationModel] = useState({
    page: 0,
    pageSize: 5
  });

  // Filter trades with flag 1
  // const tradeData = StackList.result.filter((trade) => trade.flag === 1);
  const StackTradeId = trade_id;
  const [getTradeDetailsUsingId, { data: tradeTableData }] = tradesApiAction.getTradeDetailsUsingId();

  useEffect(() => {
    // Fetch trade table using ID
    getTradeDetailsUsingId({StackTradeId, productId});
  }, [StackTradeId]);

  // Transform data for table display
  const tradeDataForTable = tradeTableData?.result || [];
  const tradeDataForTableWithId = tradeDataForTable.map((item, index) => ({
    ...item,
    id: index + 1,
  }));

  const recallDataOnUpdate = () =>{
    getTradeDetailsUsingId({productId, StackTradeId});
  }

  return (

    <Box>
      {/* back to main page. */}
      <Box sx={{
        marginTop: "10px", 
        cursor: "pointer",
        display: "inline-block"
        }} 
        onClick={backtoMainScreen}>
        <KeyboardBackspaceSharpIcon />
      </Box>

      <CustomDataGrid
        autoHeight
        list={tradeDataForTableWithId}
        columnHeaderHeight={46}
        rowHeight={60}
        columns={customerColumns}
        header={() => ModelImportHeader({ trade_id, tradeDataForTableWithId, recallDataOnUpdate })}
        onRowSelectionModelChange={onRowSelectionModelChange}

        // pagination
        pagination
        rowCount={tradeDataForTableWithId.length || 0}
        paginationMode= "client" // client, server
        paginationModel={tradesPaginationModel}
        onPaginationModelChange={setTradesPaginationModel}
      />
    </Box>
  );
};

export default TradeUpdateCallDetailTable;
