import { useState, useEffect } from "react";
import { styled } from "@mui/material";
import Card from "components/common/Card";
import Text from "components/common/Text";
import Box from "components/common/Box";
import Stack from "components/common/Stack";
import Button from "components/common/Button";
import Chip from "@mui/material/Chip";
import List from "@mui/material/List";
import ListItem from "components/common/ListItem";
import ListItemButton from "components/common/ListItemButton";
import ListItemIcon from "components/common/ListItemIcon";
import ListItemText from "components/common/ListItemText";
import IconButton from "components/common/IconButton";
import Radio from "components/common/Radio";
import TextField from "components/common/TextField";
import Checkbox from "./common/Checkbox";

const StyledListItem = styled(ListItem)(({ theme, isSelected }) => ({
  border: "2px solid ",
  borderColor: isSelected ? "#1A54B9" : "#CBCBCB",
  margin: "8px 0",
  borderRadius: "8px",
  marginBottom: "20px",
  // padding:"16px",
  backgroundColor: isSelected ? "#EAF2FF" : "inherit",
}));
const PreviousButton = styled(Button)(({}) => ({
  width: "100%",
  borderRadius: "8px",
  textTransform: "capitalize",
  padding: "14px 28px",
  marginTop: "50px",
}));
const ContinueButton = styled(Button)(({}) => ({
  width: "100%",
  borderRadius: "8px",
  textTransform: "capitalize",
  padding: "14px 28px",
  marginTop: "50px",
}));

const Answers = (props) => {
  const [selectedValue, setSelectedValue] = useState([]);

  const [isError, setError] = useState(false);

  const [textBoxErrors, setTextBoxErrors] = useState({});

  const {
    // action
    onChange,
    handleAnswers,
    handlePreviousStep,

    //
    currentQtsId,
    qtsOptions = {},

    totalQts,
    riskPoints,
    riskDetailsAnswer = [],
  } = props;

  if (!qtsOptions) {
    return null; // or some fallback UI
  }

  const { currentQtsId: CURRENT_Q_ID, isMultiselect: IS_MULTI_SELECT } =
    qtsOptions;

  const ANSWER_EXIST = riskDetailsAnswer.find(
    (ans) => ans.question_id === currentQtsId
  );

  const IS_ONLY_SELECT = qtsOptions?.options?.find(
    (ans) => ans.isOnlySelect === "Y"
  );

  useEffect(() => {
    // Preset values:
    if (ANSWER_EXIST && ANSWER_EXIST.answersIds.length) {
      setSelectedValue(ANSWER_EXIST.answersIds);
    } else {
      setSelectedValue([]);
    }
  }, [currentQtsId, riskDetailsAnswer]);

  // Internal: Radio button change
  const handleRadioChange = (value) => {
    const { optionId, weightage, text_values, isOnlySelect } = value;

    let selectedAns = {
      answer_id: optionId,
      text_values: [],
      weightage: weightage,
    };

    // case: selected has inputs
    const hasTextValue = text_values && text_values.length;

    // Handle to auto submit:
    if (IS_MULTI_SELECT === "N") {
      setSelectedValue([selectedAns]);

      if (!hasTextValue) {
        setTimeout(() => {
          handleAnswerSubmit([selectedAns]);
        }, 400);
      }
      return;
    }

    let selected = [...selectedValue];

    if (!selected || !selected.length || isOnlySelect === "Y") {
      setSelectedValue([selectedAns]);
      return;
    }

    if (IS_ONLY_SELECT) {
      selected = selected.filter(
        (d) => d.answer_id && d?.answer_id !== IS_ONLY_SELECT?.optionId
      );
    }

    let existIndex = selected.findIndex((d) => d.answer_id === optionId);
    if (existIndex === -1) {
      selected.push(selectedAns);
    } else {
      selected.splice(existIndex, 1);
    }
    setSelectedValue(selected);
  };

  const handleTextChange = ({ value, optionId, question_textbox_info_id }) => {
    let selected = [...selectedValue];
    let optionIndex = selected.findIndex((d) => d.answer_id === optionId);

    let optionFields = selected[optionIndex];
    let text_values = [...optionFields.text_values] || [];

    let exist = text_values.findIndex(
      (d) => d.question_textbox_info_id === question_textbox_info_id
    );
    const texVal = {
      option_text_value: value,
      question_textbox_info_id: question_textbox_info_id,
    };

    if (text_values && exist !== -1) {
      text_values[exist] = texVal;
    } else {
      text_values.push(texVal);
    }

    selected[optionIndex] = { ...optionFields, text_values: text_values };

    setSelectedValue([...selected]);
  };

  // Internal: previouse button
  const handlePreviousStepChange = () => {
    setError(false);
    handlePreviousStep();
  };

  // Internal: submit button
  const handleAnswerSubmit = (val = null) => {
    let answersIds = val ? val : selectedValue;

    handleAnswers({
      isComplete: currentQtsId === totalQts,
      answers: {
        question_id: qtsOptions.questionId,
        answersIds: answersIds,
      },
    });

    setSelectedValue([]);
  };

  return (
    <>
      <Box sx={{ p: 8 }}>
        <Card sx={{ p: 4, borderRadius: "16px", width: "100%" }}>
          {/* Heading & Points */}
          <Stack direction="row" justifyContent="space-between">
            <Text
              sx={{
                color: "#242424",
                fontSize: "18px",
                fontWeight: 500,
              }}
            >
              Select one answer for the question.
            </Text>

            <Box>
              <Chip
                label={`${riskPoints} Points`}
                sx={{
                  color: "#1A54B9",
                  borderRadius: "112px",
                  backgroundColor: "#F7F8FF",
                  fontSize: "14px",
                }}
              />
            </Box>
          </Stack>

          {/* // List Item check. */}
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {qtsOptions?.options?.map((option, index) => {
              const labelId = `radio-list-label-${index}`;
              let optionSelected;
              if (selectedValue && selectedValue.length) {
                optionSelected = selectedValue.find(
                  (d) => d?.answer_id && d?.answer_id === option?.optionId
                );
              }

              return (
                <>
                  {/* List Item check. - All */}
                  <StyledListItem
                    isSelected={!!optionSelected}
                    key={index}
                    secondaryAction={
                      <IconButton edge="end" aria-label="comments"></IconButton>
                    }
                    disablePadding
                  >
                    <ListItemButton
                      role={undefined}
                      onClick={() => handleRadioChange(option)}
                      dense
                    >
                      <ListItemIcon>
                        {IS_MULTI_SELECT === "Y" &&
                        option.isOnlySelect === "N" ? (
                          <Checkbox
                            checked={!!optionSelected}
                            tabIndex={-1}
                            disableRipple
                            sx={{
                              "&.Mui-checked": {
                                color: "black",
                              },
                            }}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        ) : (
                          <Radio
                            checked={!!optionSelected}
                            tabIndex={-1}
                            disableRipple
                            sx={{
                              "&.Mui-checked": {
                                color: "black",
                              },
                            }}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        onChange={onChange}
                        id={labelId}
                        primary={option.optionDescription}
                      />
                    </ListItemButton>
                  </StyledListItem>

                  {/* Text Fields */}
                  {option?.text_values && option?.text_values.length
                    ? option?.text_values.map((input, index) => {
                        let val = {};
                        if (optionSelected && optionSelected.text_values) {
                          val = optionSelected.text_values.find(
                            (v) =>
                              v.question_textbox_info_id ===
                              input?.question_textbox_info_id
                          );
                        }

                        if (optionSelected) {
                          return (
                            <Stack key={input?.question_textbox_info_id}>
                              <Text
                                sx={{
                                  fontSize: 14,
                                  color: "#344054",
                                  fontWeight: 500,
                                  marginBottom: "6px",
                                }}
                              >
                                {input.text_heading}
                              </Text>
                              <TextField
                                onChange={(e) => {
                                  let value = e.target.value;

                                  if (index === 0) {
                                    value = value.replace(/[^a-zA-Z0-9\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g, ""); 
                                  } else {
                                    value = value.replace(/[^0-9]/g, "");
                                  }

                                  handleTextChange({
                                    value: value,
                                    optionId: option.optionId,
                                    question_textbox_info_id:
                                      input?.question_textbox_info_id,
                                  });
                                }}
                                value={val?.option_text_value || ""}
                                sx={{
                                  width: "100%",
                                  marginBottom: "24px",
                                }}
                                placeholder={input.text_placeholder}
                                error={
                                  !!textBoxErrors[
                                    input.question_textbox_info_id
                                  ]
                                }
                                helperText={
                                  textBoxErrors[input.question_textbox_info_id]
                                }
                              />
                            </Stack>
                          );
                        }
                      })
                    : null}
                </>
              );
            })}

            {isError ? (
              <Text variant="small" sx={{ color: "#FF3333", mb: "30px" }}>
                Please Select one option
              </Text>
            ) : null}
          </List>

          {/* Action button */}
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={2}
            mt={2}
          >
            {currentQtsId > 1 && (
              <PreviousButton
                variant="outlined"
                onClick={() => handlePreviousStepChange()}
              >
                Previous
              </PreviousButton>
            )}

            {(selectedValue && selectedValue.length) || ANSWER_EXIST ? (
              <ContinueButton
                // onClick={currentQtsId === totalQts ? handleSubmit : handleAnswerSubmit}
                onClick={() => handleAnswerSubmit()}
              >
                Continue
              </ContinueButton>
            ) : null}
          </Stack>
        </Card>
      </Box>
    </>
  );
};

export default Answers;
