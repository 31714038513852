import React from "react";
import Grid from "components/common/Grid";
import Box from "components/common/Box";
import Text from "components/common/Text";
import TextField from "components/common/TextField";
import Button from "components/common/Button";
import Stack from "components/common/Stack";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material";
import OTPInput from "react-otp-input";
import Verified from "asset/icons/Verified";

const OtpButton = styled(Button)(() => ({
  borderRadius: "8px",
  fontSize: "16px",
  fontWeight: 600,
  width: "146px",
  height: "53px",
}));

const OtpVerificationBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginTop: "20px",
  marginBottom: "10px",
}));

const OtpVerificationText = styled(Text)(() => ({
  fontSize: "14px",
  color: "#242424",
  fontWeight: "500",
}));

const OtpBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  marginBottom: "24px",
  flexWrap: "nowrap",
}));

const otpFieldStyle = {
  width: 58,
  height: 58,
  borderRadius: "10px",
  border: "1px solid #CBCBCB",
  color: "black",
  margin: "0 5px",
  fontSize: "15px",
};

function SignUp(props) {
  const {
    handleBasicFormChange,
    formData,
    handleEmailOtpSubmit,
    handlePhoneOtpSubmit,
    handleEmailOtpSend,
    handlePhoneOtpSend,
    isEmailOtpSend,
    isPhoneOtpSend,
    isEmailVerified,
    basicDetailsError,
    emailSec,
    emailOtpActive,
    phoneOtpActive,
    phoneSec,
  } = props;
  const navigate = useNavigate();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={2} p={0}></Grid>
        <Grid item xs={8} p={0}>
          <Stack
            justifyContent="center"
            alignItems="start"
            sx={{
              height: "100vh",
              width: "40vw",
            }}
          >
            <Text
              sx={{
                color: "#242424",
                fontSize: 30,
                fontWeight: 600,
                mb: "8px",
                zoom:"0.7"
              }}
            >
              Sign up
            </Text>

            <Text
              sx={{
                fontSize: 14,
                fontWeight: 400,
                color: "#475467",
                marginBottom: "32px",
                zoom:"0.7"
              }}
            >
              It's great to see you here, sign-up to begin your journey.
            </Text>

            <Text
              sx={{
                fontSize: 14,
                color: "#344054",
                fontWeight: 500,
                marginBottom: "6px",
                zoom:"0.7"
              }}
            >
              Name
            </Text>

            <TextField
              onChange={({ target }) => {
                handleBasicFormChange(target.name, target.value);
              }}
              value={formData.name}
              name="name"
              sx={{
                width: "70%",
                marginBottom: "24px",
                zoom:"0.7"
              }}
              placeholder="Enter Your Name"
            />

            <Box sx={{ display: "flex" }}>
              <Text
                sx={{
                  fontSize: 14,
                  color: "#344054",
                  fontWeight: 500,
                  marginBottom: "6px",
                  mr: "5px",
                  zoom:"0.7"
                }}
              >
                Email ID
              </Text>
              {isEmailVerified && <Verified />}
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                zoom:"0.7"
              }}
            >
              <TextField
                sx={{ mr: "20px", width: "70%", marginBottom: "20px" }}
                name="email"
                placeholder="Enter email ID"
                onChange={({ target }) => {
                  handleBasicFormChange(target.name, target.value);
                }}
                value={formData.email}
              />

              <OtpButton
                onClick={handleEmailOtpSend}
                disabled={!formData.name || !formData.email || isEmailOtpSend}
              >
                Send OTP
              </OtpButton>

              {basicDetailsError.email && (
                <Text sx={{ py: 1 }} variant="small" color="red">
                  {basicDetailsError.email}
                </Text>
              )}
            </Box>

            {isEmailOtpSend && !isEmailVerified ? (
              <Box>
                <OtpVerificationBox>
                  <OtpVerificationText>OTP Verification</OtpVerificationText>
                  {emailOtpActive ? (
                    <Text
                      variant="small"
                      sx={{
                        fontWeight: 500,
                        color: "#1A54B9",
                        ml: 1,
                        lineHeight: 0,
                        zoom:"0.7"
                      }}
                    >
                      Resend OTP in 00: {emailSec}
                    </Text>
                  ) : (
                    <Text
                      onClick={handleEmailOtpSend}
                      variant="small"
                      sx={{
                        fontWeight: 500,
                        color: "#1A54B9",
                        ml: 1,
                        cursor: "pointer",
                        zoom:"0.7"
                      }}
                    >
                      Resend OTP
                    </Text>
                  )}
                </OtpVerificationBox>

                <OtpBox>
                  <Box sx={{ display: "flex", alignItems: "center", zoom:"0.7" }}>
                    <OTPInput
                      value={formData.emailOtp}
                      onChange={(value) => {
                        handleBasicFormChange("emailOtp", value);
                      }}
                      isInputNum={true}
                      numInputs={6}
                      placeholder="0"
                      inputStyle={otpFieldStyle}
                      renderSeparator={<span></span>}
                      renderInput={(props) => <input {...props} />}
                    />
                    {basicDetailsError.emailOtp && (
                      <Text sx={{ py: 1 }} variant="small" color="red">
                        {basicDetailsError.emailOtp}
                      </Text>
                    )}
                  </Box>
                  <OtpButton sx={{ ml: 2.5, zoom:"0.7" }} onClick={handleEmailOtpSubmit}>
                    Confirm OTP
                  </OtpButton>
                </OtpBox>
              </Box>
            ) : null}

            <Text
              sx={{
                fontSize: 14,
                color: "#344054",
                fontWeight: 500,
                marginBottom: "6px",
                zoom:"0.7"
              }}
            >
              Phone number
            </Text>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                zoom:"0.7"
              }}
            >
              <TextField
                onChange={({ target }) => {
                  handleBasicFormChange(target.name, target.value);
                }}
                value={formData.phoneNo}
                name="phoneNo"
                sx={{
                  width: "70%",
                  marginBottom: "24px",
                }}
                disabled={!isEmailVerified}
                placeholder="Enter your phone number"
              />

              <OtpButton
                onClick={handlePhoneOtpSend}
                disabled={
                  !formData.name ||
                  !isEmailVerified ||
                  !formData.phoneNo ||
                  isPhoneOtpSend
                }
              >
                Send OTP
              </OtpButton>

              {basicDetailsError.phoneNo && (
                <Text sx={{ py: 1 }} variant="small" color="red">
                  {basicDetailsError.phoneNo}
                </Text>
              )}
            </Box>

            {isPhoneOtpSend ? (
              <Box>
                <OtpVerificationBox>
                  <OtpVerificationText>OTP Verification</OtpVerificationText>
                  {phoneOtpActive ? (
                    <Text
                      variant="small"
                      sx={{
                        fontWeight: 500,
                        color: "#1A54B9",
                        ml: 1,
                        lineHeight: 0,
                        zoom:"0.7"
                      }}
                    >
                      Resend OTP in 00: {phoneSec}
                    </Text>
                  ) : (
                    <Text
                      onClick={handlePhoneOtpSend}
                      variant="small"
                      sx={{
                        fontWeight: 500,
                        color: "#1A54B9",
                        ml: 1,
                        cursor: "pointer",
                         zoom:"0.7"
                      }}
                    >
                      Resend OTP
                    </Text>
                  )}
                </OtpVerificationBox>

                <OtpBox>
                  <Box sx={{ display: "flex", alignItems: "center", zoom:"0.7" }}>
                    <OTPInput
                      value={formData.phoneOtp}
                      onChange={(value) => {
                        handleBasicFormChange("phoneOtp", value);
                      }}
                      isInputNum={true}
                      numInputs={6}
                      placeholder="0"
                      inputStyle={otpFieldStyle}
                      renderSeparator={<span></span>}
                      renderInput={(props) => <input {...props} />}
                    />
                    {basicDetailsError.phoneOtp && (
                      <Text sx={{ py: 1 }} variant="small" color="red">
                        {basicDetailsError.phoneOtp}
                      </Text>
                    )}
                  </Box>
                  <OtpButton sx={{ ml: 2.5, zoom:"0.7" }} onClick={handlePhoneOtpSubmit}>
                    Confirm OTP
                  </OtpButton>
                </OtpBox>
              </Box>
            ) : null}

            <Box sx={{ width: "100%", zoom:"0.7" }}>
              <Box>
                <Typography
                  sx={{
                    color: "#676C76",
                    fontSize: "16px",
                    fontWeight: 500,
                    display: "flex",
                    ml: "110px",
                  }}
                >
                  Don't have an account?
                  <Typography
                    sx={{
                      color: "#1A54B9",
                      fontSize: "16px",
                      fontWeight: 600,
                      ml: "4px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    Log in
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </Stack>
        </Grid>

        <Grid item xs={2} p={0}></Grid>
      </Grid>
    </>
  );
}

export default SignUp;
