import React, { useState, useEffect } from "react";
import Grid from "components/common/Grid";
import Box from "components/common/Box";
import Text from "components/common/Text";
import TextField from "components/common/TextField";
import Button from "components/common/Button";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import OTPInput from "react-otp-input";

const OtpLogin = (props) => {
  const {
    onReset,
    handleLoginSubmit,
    otp,
    onChange,
    mobileNumberEmail,
    resendOtp,
    OtpError,
  } = props;
  const [resendTimer, setResendTimer] = useState(30); 

  useEffect(() => {
    let interval;

    if (resendTimer > 0) {
      interval = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [resendTimer]);

  const handleResendOtp = async () => {
    try {
      await resendOtp();
      setResendTimer(30); 
    } catch (error) {
      console.error("Error resending OTP:", error);
    }
    
  };

  return (
    <Grid>
      <Grid
        container
        spacing={0}
        sx={{
          height: "97vh",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              zoom:"0.7"
            }}
          >
            <Text
              sx={{
                fontSize: "30px",
                fontWeight: 600,
                color: "#1D2939",
                lineHeight: "38px",
              }}
            >
              OTP Verification
            </Text>

            <Text
              variant="body2"
              sx={{
                fontWeight: 400,
                lineHeight: "24px",
                marginBottom: "20px",
              }}
            >
              We have sent an OTP to
              <span
                style={{
                  color: "#1A54B9",
                  fontWeight: 500,
                  marginLeft: "5px",
                  marginTop: "20px",
                }}
              >
                {mobileNumberEmail}
              </span>
              <IconButton onClick={onReset} aria-label="edit">
                <EditIcon
                  sx={{
                    fontSize: "18px",
                    color: "#1A54B9",
                    marginBottom: "3px",
                  }}
                />
              </IconButton>
            </Text>
            <OTPInput
              value={otp}
              onChange={onChange}
              numInputs={6}
              placeholder="0"
              inputStyle={{
                width: 62,
                height: 60,
                borderRadius: "10px",
                border: "1px solid #CBCBCB",
                color: "black",
                fontSize: "17px",
                margin: "0 5px",
              }}
              renderSeparator={<span></span>}
              renderInput={(props) => <input {...props} />}
            />

            <Button
              onClick={handleLoginSubmit}
              variant="contained"
              sx={{
                width: "452px",
                height: "56px",
                borderRadius: "8px",
                marginTop: "30px",
                background: "#142E56",
                "&:hover": {
                  background: "#142E56",
                },
              }}
            >
              Confirm OTP
            </Button>
            <Box>
              <Text
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  color: "#1A54B9",
                  lineHeight: "20px",
                  textAlign: "center",
                  marginTop: "30px",
                  pointerEvents: resendTimer > 0 ? "none" : "auto",
                }}
              >
                {resendTimer > 0 ? (
                  `Resend OTP in 00:${String(resendTimer).padStart(2, "0")}`
                ) : (
                  <Box onClick={handleResendOtp}>
                    <Text
                      sx={{
                        cursor: "pointer",
                        fontSize: "14px",
                        fontWeight: 500,
                        color: "#1A54B9",
                        lineHeight: "20px",
                        textAlign: "center",
                        marginTop: "30px",
                      }}
                    >
                      You can resend OTP now
                    </Text>
                  </Box>
                )}
              </Text>
              {OtpError && (
                <Text variant="body2" color="error">
                  {OtpError}
                </Text>
              )}
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default OtpLogin;
