import Box from "components/common/Box";
import React, { useEffect, useState } from "react";
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import CustomGrid from "components/datagrid/CustomGrid";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import { uuid } from "helpers/utility";
import { useLocation } from "react-router-dom";
import { CustomerPortfolioApiAction } from "stores/redux/apiSlices/CustomerPortfolio/portfolioApiSlice";
import { ChipStyle } from "components/dashboard/DahboradConstant";
import Button from "components/common/Button";
import { tradesApiAction } from "stores/redux/apiSlices/trades/tradesApiSlice";
import CustomDataGrid from "components/datagrid/CustomGrid";

const CustomerHoldingTable = ({ productId, customerId, memberID }) => {

  const [selectedRows, setSelectedRows] = useState([]);
  const [tradesDetails, setTradesDetails] = useState([]);


  const [getPerformance, getDetailTableDetail = []] =
    CustomerPortfolioApiAction.getHoldingAPI();

  const [squareOff] = tradesApiAction.squareOffCustomerApi();

  const detailTable = getDetailTableDetail?.data?.data ?? [];
  console.log("detailTable++", detailTable);

  const detailTotalCount = getDetailTableDetail?.data?.totalRecords;
  console.log("detailTotalCount++", detailTotalCount);


  const handleRowSelection = (selectedIds) => {
    const newSelectedRows = detailTable

    console.log("newSelectedRows+++",newSelectedRows);
    
  
    setSelectedRows(newSelectedRows);
  };
  

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });

  const DetailTableHeader = [
    {
      field: "stock_name",
      headerName: "Stock Name",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#242424"
              fontWeight="500"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.stock_name}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "customer_name",
      headerName: "Customer Name",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#242424"
              fontWeight="500"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.customer_name}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#242424"
              fontWeight="500"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.quantity}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "buy_date",
      headerName: "Buy Date",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#242424"
              fontWeight="500"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.buy_date}
            </Text>
          </Stack>
        </>
      ),
    },

    {
      field: "sell_date",
      headerName: "Buy/Sell",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#242424"
              fontWeight="500"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.sell_date}
            </Text>
          </Stack>
        </>
      ),
    },

    {
      field: "amount_invested",
      renderHeader: () => (
        <Box sx={{ textAlign: "center", lineHeight: 1.2 }}>
          Amount <br /> Invested
        </Box>
      ),
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#242424"
              fontWeight="500"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.amount_invested}
            </Text>
          </Stack>
        </>
      ),
    },

    {
      field: "total_gains",
      headerName: "Total Gains",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#242424"
              fontWeight="500"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.total_gains}
            </Text>
          </Stack>
        </>
      ),
    },

    {
      field: "amount_after_gains",
      headerName: "Amount After Gains",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#242424"
              fontWeight="500"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.amount_after_gains}
            </Text>
          </Stack>
        </>
      ),
    },

    {
      field: "todays_change",
      headerName: "Todays Change",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#242424"
              fontWeight="500"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.todays_change}
            </Text>
          </Stack>
        </>
      ),
    },

    {
      field: "risk_profile",
      headerName: "Selected Risk Profile",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" textAlign="center">
          {params?.row?.risk_profile ? (
            <ChipStyle
              style={{ cursor: "pointer" }}
              label={params?.row?.risk_profile}
              size="small"
              riskprofile={params?.row?.risk_profile}
            />
          ) : (
            ""
          )}
        </Stack>
      ),
    },
  ];

  const AlgoDetailTableHeader = [
    {
      field: "stock_name",
      headerName: "Stock Name",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#242424"
              fontWeight="500"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.stock_name}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "last_price",
      headerName: "Current Price",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.last_price
                ? `₹ ${Math.abs(params?.row?.last_price)}`
                : ""}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "52WeeksHighestHighPrice",
      headerName: "52 week high",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.["52WeeksHighestHighPrice"]
                ? `₹ ${Math.abs(params?.row?.["52WeeksHighestHighPrice"])}`
                : ""}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "52WeeksLowestLowPrice",
      headerName: "52 week loss",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.["52WeeksHighestHighPrice"]
                ? `₹ ${Math.abs(params?.row?.["52WeeksHighestHighPrice"])}`
                : ""}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "1YearReturns",
      headerName: "1 Y return",
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.["1YearReturns"] !== undefined &&
            params?.row?.["1YearReturns"] !== 0 &&
            (params?.row?.["1YearReturns"] > 0 ? (
              <Stack direction="row">
                <NorthIcon
                  style={{ height: "15px", color: "#219653", marginTop: "3px" }}
                />
                <Text
                  color="#219653"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.["1YearReturns"]
                    ? `${Math.abs(params?.row?.["1YearReturns"])} %`
                    : ""}
                </Text>
              </Stack>
            ) : (
              <Stack direction="row">
                <SouthIcon
                  style={{ height: "15px", color: "#EB5757", marginTop: "3px" }}
                />
                <Text
                  color="#EB5757"
                  fontWeight="400"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.["1YearReturns"]
                    ? `${Math.abs(params?.row?.["1YearReturns"])} %`
                    : ""}
                </Text>
              </Stack>
            ))}
        </>
      ),
    },
    {
      field: "3YearsReturns",
      headerName: "3 Y return",
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.["3YearsReturns"] !== undefined &&
            params?.row?.["3YearsReturns"] !== 0 &&
            (params?.row?.["3YearsReturns"] > 0 ? (
              <Stack direction="row">
                <NorthIcon
                  style={{ height: "15px", color: "#219653", marginTop: "3px" }}
                />
                <Text
                  color="#219653"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.["3YearsReturns"]
                    ? `${Math.abs(params?.row?.["3YearsReturns"])} %`
                    : ""}
                </Text>
              </Stack>
            ) : (
              <Stack direction="row">
                <SouthIcon
                  style={{ height: "15px", color: "#EB5757", marginTop: "3px" }}
                />
                <Text
                  color="#EB5757"
                  fontWeight="400"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {" "}
                  {params?.row?.["3YearsReturns"]
                    ? `${Math.abs(params?.row?.["3YearsReturns"])} %`
                    : ""}
                </Text>
              </Stack>
            ))}
        </>
      ),
    },
    {
      field: "5YearsReturns",
      headerName: "5 Y return",
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.["5YearsReturns"] &&
          params?.row?.["5YearsReturns"] !== 0 ? (
            params?.row?.["5YearsReturns"] > 0 ? (
              <Stack direction="row">
                <NorthIcon
                  style={{ height: "15px", color: "#219653", marginTop: "3px" }}
                />
                <Text
                  color="#219653"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.["5YearsReturns"]
                    ? ` ${Math.abs(params?.row?.["5YearsReturns"])} %`
                    : ""}
                </Text>
              </Stack>
            ) : (
              <Stack direction="row">
                <SouthIcon
                  style={{ height: "15px", color: "#EB5757", marginTop: "3px" }}
                />
                <Text
                  color="#EB5757"
                  fontWeight="400"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.["5YearsReturns"]
                    ? ` ${Math.abs(params?.row?.["5YearsReturns"])} %`
                    : ""}
                </Text>
              </Stack>
            )
          ) : null}
        </>
      ),
    },
    {
      field: "return_since_inception",
      headerName: "Returns since inception",
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.return_since_inception &&
          params?.row?.return_since_inception !== 0 ? (
            params?.row?.return_since_inception > 0 ? (
              <Stack direction="row">
                <NorthIcon
                  style={{ height: "15px", color: "#219653", marginTop: "3px" }}
                />
                <Text
                  color="#219653"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.return_since_inception
                    ? ` ${Math.abs(params?.row?.return_since_inception)} %`
                    : ""}
                </Text>
              </Stack>
            ) : (
              <Stack direction="row">
                <SouthIcon
                  style={{ height: "15px", color: "#EB5757", marginTop: "3px" }}
                />
                <Text
                  color="#EB5757"
                  fontWeight="400"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.return_since_inception
                    ? ` ${Math.abs(params?.row?.return_since_inception)} %`
                    : ""}
                </Text>
              </Stack>
            )
          ) : null}
        </>
      ),
    },
  ];

  const modifiedDetailTableData = detailTable.map((row) => ({
    ...row,
    id: uuid(),
  }));

  const handleSquareOff = async () => {
    
    const payload = {
      squareOfTradeIds: detailTable.map((row) => ({
        trade_id: row.trade_id,
        customer_id: row.customer_id,
        transaction_id: row.transaction_id,
      })),
    };
    
    try {
      let res = await squareOff(payload);
      setSelectedRows([]);
      // set selected data
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const CustomerHeader = () => {
    return (
      <>
        <Box sx={{ p: 2 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text
              sx={{ color: "primary", mr: 1, fontWeight: 500 }}
              color="primary"
              variant="button"
            >
              Holdings{" "}
            </Text>

            {/* <Button
              sx={{
                fontWeight: 500,
                borderRadius: "8px",
                borderColor: "#D0D5DD",
                p: 1,
              }}
              variant="outlined"
              onClick={handleSquareOff}
            >
              Square Off Trades
            </Button> */}
          </Stack>
        </Box>
      </>
    );
  };

  const getColumns = (productId) => {
    if (productId === 2) {
      return DetailTableHeader;
    } else if (productId === 3) {
      return DetailTableHeader;
    } else if (productId === 1) {
      return DetailTableHeader;
    } else {
      return;
    }
  };

  React.useEffect(() => {
    getPerformance({
      user_id: memberID,
      page: paginationModel?.page + 1,
      pageSize: paginationModel?.pageSize,
      product_id: productId,
    });
  }, [paginationModel.page, paginationModel.pageSize]);

  const handlePaginationChange = (newPaginationModel) => {
    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      page: newPaginationModel?.page,
      pageSize: newPaginationModel.pageSize,
    }));
  };

  return (
    <div>
      <Stack>
        <CustomDataGrid
          list={modifiedDetailTableData}
          columnHeaderHeight={46}
          rowHeight={72}
          columns={getColumns(productId)}
          count={modifiedDetailTableData.length}
          pagination
          rowCount={detailTotalCount}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationChange}
          // selectedRows={modifiedDetailTableData.map((row) => row.id)}
          // onRowSelectionModelChange={handleRowSelection}
    
          // getRowId={(row) => row.id}
          isLoading={false}
          header={CustomerHeader}
        />
      </Stack>
    </div>
  );
};

export default CustomerHoldingTable;
