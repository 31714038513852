import React, { useState, useEffect } from "react";
import DashboardIcon from "@mui/icons-material/DashboardOutlined";
import AddCustomerIcon from "asset/icons/AddCustomerIcon";
import CustomerIcon from "asset/icons/CustomerIcon";
import DividendIcon from "asset/icons/DividendIcon";
import LogOutIcon from "asset/icons/LogOutIcon"
import FamilyIcon from "asset/icons/FamilyIcon";
import ReportIcon from "asset/icons/ReportIcon";
import RiskProfile from "asset/icons/RiskProfile";
import TradeIcon from "asset/icons/TradeIcons";
import PlanIcon from "asset/icons/PlanIcon";
import Stack from "components/common/Stack";
import NewSideBar from "components/Layout/NewSideBar";
import { Outlet, useNavigate } from "react-router-dom";
import { routes } from "helpers/constants";
import { useLocation } from "react-router-dom";
import Header from "components/Layout/Header";
import CustomerDashboard from "pages/Customer/CustomerDashboard";
import { useSelector } from "react-redux";

// import {
//   Outlet, useMatch,
//   useResolvedPath
// } from "react-router-dom";

// Constant
import { appConstants } from "helpers/constants/appConstants";
import BlureBackground from "hooks/BlureBackground";
const USE_ROLES = appConstants.userRoles;

const LayoutContainer = (props) => {
	const [isDrawerExpanded, setIsDrawerExpanded] = useState(true);

	const location = useLocation();
	let navigate = useNavigate();
	const userRoleCode = useSelector((state) => state.commonReducer.userRoleCode);
	
	const [selectedRoute, setSelectedRoute] = useState(location.pathname);

	useEffect(() => {
		setSelectedRoute(location.pathname);
	}, [location.pathname]);

	const getSidebarMenuConfig = () => {
		const role_admin = USE_ROLES.ADMIN.code
		const role_ria = USE_ROLES.RIA.code
		const role_subbroker = USE_ROLES.SUBBROKER.code
		const role_customer = USE_ROLES.CUSTOMER.code
		return [
			{
				type: "item",
				primaryText: "Dashboard",
				icon: <DashboardIcon />,
				onClick: () => {
					handleNavigation(routes.dashboard[0]);
				},
				isActive: isActiveRoute(routes.dashboard),
				aclVisible: ([role_subbroker,role_ria].includes(userRoleCode)),
			},
			{
				type: "item",
				primaryText: "Dashboard",
				icon: <DashboardIcon />,
				onClick: () => {
				  handleNavigation(routes.adminDashBoard[0]);
				},
				isActive: isActiveRoute(routes.adminDashBoard),
				aclVisible: (userRoleCode === role_admin),
			  },



// 
			  {
				type: "item",
				primaryText: "Dashboard",
				icon: <DashboardIcon />,
				onClick: () => {
				  handleNavigation(routes.customerDashboard[0]);
				},
				isActive: isActiveRoute(routes.customerDashboard),
				aclVisible: (userRoleCode === role_customer),
			  },

			  {
				type: "item",
				primaryText: "Portfolio",
				icon: <RiskProfile />,
				onClick: () => {
				  handleNavigation(routes.customerPortfolio[0]);
				},
				isActive: isActiveRoute(routes.customerPortfolio),
				aclVisible: (userRoleCode === role_customer),
			  },

			  {
				type: "item",
				primaryText: "Reports",
				icon: <ReportIcon />,
				onClick: () => {
				  handleNavigation(routes.customerReports[0]);
				},
				isActive: isActiveRoute(routes.customerReports),
				aclVisible: (userRoleCode === role_customer),
			  },

			{
				type: "item",
				primaryText: "Add Customer",
				icon: <AddCustomerIcon />,
				onClick: () => {
					handleNavigation(routes.addCustomer[0]);
				},
				isActive: isActiveRoute(routes.addCustomer),
				aclVisible: ([role_subbroker,role_ria].includes(userRoleCode)),
			},
			{
				type: "item",
				primaryText: "Customers",
				icon: <CustomerIcon />,
				onClick: () => {
					handleNavigation(routes.customers[0]);
				},
				isActive: isActiveRoute(routes.customers),
				aclVisible: ([role_subbroker,role_ria].includes(userRoleCode)),
			},
			{
				type: "item",
				primaryText: "Trades",
				icon: <TradeIcon />,
				onClick: () => {
					handleNavigation(routes.trades[0]);
				},
				isActive: isActiveRoute(routes.trades),
				aclVisible: ([role_subbroker,role_ria].includes(userRoleCode)),
			},
			{
				type: "item",
				primaryText: "Reports",
				icon: <ReportIcon />,
				onClick: () => {
					handleNavigation(routes.reports[0]);
				},
				isActive: isActiveRoute(routes.reports),
				aclVisible: ([role_subbroker,role_ria].includes(userRoleCode)),
			},
			{
				type: "item",
				primaryText: "Model Portfolio",
				icon: <RiskProfile />,
				onClick: () => {
					handleNavigation(routes.riskProfile[0]);
				},
				isActive: isActiveRoute(routes.riskProfile),
				aclVisible: ([role_subbroker,role_ria].includes(userRoleCode)),
			},
			{
				type: "item",
				primaryText: "Plans",
				icon: <PlanIcon />,
				onClick: () => {
					handleNavigation(routes.plans[0]);
				},
				isActive: isActiveRoute(routes.plans),
				aclVisible: ([role_subbroker,role_ria].includes(userRoleCode)),
			},
			{
				type: "item",
				primaryText: "Family Members",
				icon: <FamilyIcon />,
				onClick: () => {
					handleNavigation(routes.familyMembers[0]);
				},
				isActive: isActiveRoute(routes.familyMembers),
				aclVisible: ([role_subbroker,role_ria].includes(userRoleCode)),
			},
			{
				type: "item",
				primaryText: "Dividend",
				icon: <DividendIcon />,
				onClick: () => {
					handleNavigation(routes.dividend[0]);
				},
				isActive: isActiveRoute(routes.dividend),
				aclVisible: ([role_subbroker,role_ria].includes(userRoleCode)),
			},
			// {
			// 	type: "item",
			// 	primaryText: "Log Out",
			// 	icon: <LogOutIcon />,
			// 	onClick: () => {
			// 		localStorage.removeItem('token')
			// 		navigate('/login')
			// 	},
			// 	isActive: null,
				
			// },
			
		];
	};

	const isActiveRoute = (route) => {
		return route.includes(selectedRoute);
	};
	// const handleDrawerToggle = (isExpanded) => {
	// 	setIsDrawerExpanded(isExpanded);
	// };

	//   const getSidebarMenuConfig = () => {
	// const {
	// isOnboardingCompleted,
	// isRMSOnboardingCompleted,
	// isOnestockOnboardingCompleted,
	// loginProfile,
	// profilePic,
	// activeProductList,
	// } = this.props;

	// const sidebarMenu = [
	//       {
	//         type: "item",
	//         primaryText: "DashBoard",
	//         icon: <DashboardIcon/>,
	//         onClick: () => {
	//           handleNavigation(routes.customerDashboard);
	//         },
	//         isActive: isActiveRoute(routes.customerDashboard),
	//       },

	//       {
	//         type: "item",
	//         primaryText: "Portfolio",
	//         icon: <RiskProfile/>,
	//         onClick: () => {
	//           handleNavigation(routes.customerPortfolio);
	//         },
	//         isActive: isActiveRoute(routes.customerPortfolio),
	//       },

	//       {
	//         type: "item",
	//         primaryText: "Report",
	//         icon: <ReportIcon/>,
	//         onClick: () => {
	//           handleNavigation(routes.customerReports);
	//         },
	//         isActive: isActiveRoute(routes.customerReports),
	//       },
	//     ];

	//     return sidebarMenu;
	//   };

	const handleDrawerToggle = (isExpanded) => {
		setIsDrawerExpanded(isExpanded);
	};


  const handleNavigation = (redirectUrl) => {
    navigate(redirectUrl);
    // setSelectedRoute(redirectUrl);
  };


  // Now you can call the function
  const sidebarMenu = getSidebarMenuConfig();

  // Find the menu item with matching route and get its primaryText
  const selectedPrimaryText = sidebarMenu.find(
    (item) => item.type === "item" && item.isActive
  )?.primaryText;
  return (
	<BlureBackground>
		<Stack direction="row" sx={{ maxHeight: "100vh", overflow: "hidden" }}>
		<NewSideBar
			menuConfig={sidebarMenu}
			isDrawerExpanded={isDrawerExpanded}
			onDrawerToggle={handleDrawerToggle}
		/>
		<Stack
			sx={{
			flex: 1,
			backgroundColor: {
				xs: "background.paper",
				md: "grey.100",
			},
			maxWidth: "calc(100% - 240px)",
			zoom: "0.7"
			}}
		>
			<Header selectedRoute={selectedPrimaryText} userRoleCode={userRoleCode}/>

			<Stack
			sx={{
				position: "relative",
				flex: 1,
				maxWidth: "100%",
				px: 3,
				py: 0,
				pb: 2,
				overflowX: "hidden",
				backgroundColor: "#F7F8FF",
			}}
			id="mainAppWrapper"
			>
			<Outlet />
			</Stack>
		</Stack>
		</Stack>
	</BlureBackground>
  );
};

export default LayoutContainer;
